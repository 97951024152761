import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfileIcon from './UserProfileIcon';
interface HeaderProps {
  gray?: boolean
}


const Header: React.FC<HeaderProps> = ({ gray = false }) => {
  {/*const iconImages = [
    { icon: "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png", url: 'https://twitter.com/Mr_BunnySex' },
    { icon: "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png", url: 'https://t.me/mr_bunny_vip' },
    { icon: gray ? 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/GrayPornHubPrLogo.png' : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png", url: 'https://cn.pornhub.com/channels/mr-bunny' },
  ];*/}

  const iconImages = [
    { icon: gray ? 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/GrayPornHubPrLogo.png' : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png", url: 'https://cn.pornhub.com/channels/mr-bunny' },
    { icon: "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png", url: 'https://t.me/mr_bunny_vip' },
    { icon: "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png", url: 'https://twitter.com/Mr_BunnySex' },
  ];

  const [isLogoLoaded, setLogoLoaded] = useState(false);
  const [areIconsLoaded, setIconsLoaded] = useState([false, false, false]); // 三个媒体图标的加载状态
  const navigate = useNavigate()
  const handleIconClick = (url: string) => {
    window.open(url, '_blank'); // 在新窗口中打开链接
  };

  const handleLogoLoaded = () => {
    setLogoLoaded(true);
  };

  const handleIconLoaded = (index: number) => {
    setIconsLoaded((prevState) => {
      const newIconsLoaded = [...prevState];
      newIconsLoaded[index] = true;
      return newIconsLoaded;
    });
  };

  return (
    <>
      <header style={headerStyle} className='px-8 md:px-[120px] py-8 md:py-[30px] backdrop-blur-[10px] bg-black bg-opacity-10 top-0 w-full'>
    
        <div style={leftPartStyle}>
          {/* Logo 图片 */}
          <img
            src='https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/search-header-logo.svg'
            alt="Logo"
            style={logoStyle}
            className='cursor-pointer h-[30px] md:h-[40px] mr-8'
            onClick={(e) => {
              e.stopPropagation()
              navigate('/')
            }}
          />
          <img
            src={gray ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/gray-logo.png" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"}
            alt="Logo"
            style={logoStyle}
            className='cursor-pointer h-[30px] md:h-[40px]'
            onLoad={handleLogoLoaded}
            onClick={(e) => {
              e.stopPropagation()
              window.open('https://moso.monster/')
            }}
          />

          
          <div className='hidden sm:hidden md:flex'>
          {iconImages.map(({ icon, url }, index) => (
            <img
              key={index}
              src={icon}
              alt={`Icon ${index + 1}`}
              className='cursor-pointer h-8 ml-4'
              onLoad={() => handleIconLoaded(index)}
              onClick={(e) => {
                e.stopPropagation()
                handleIconClick(url)
              }}
            />
          ))}
          <div className='text-2xl text-[#cfcfcf] ml-4 flex items-center'><UserProfileIcon /></div>
        </div>
        </div>
        
        {/*
        <div className='hidden sm:hidden md:flex'>
          {iconImages.map(({ icon, url }, index) => (
            <img
              key={index}
              src={icon}
              alt={`Icon ${index + 1}`}
              className='cursor-pointer h-8 ml-4'
              onLoad={() => handleIconLoaded(index)}
              onClick={(e) => {
                e.stopPropagation()
                handleIconClick(url)
              }}
            />
          ))}
          <div className='text-2xl text-[#cfcfcf] ml-4 flex items-center'><UserProfileIcon /></div>
        </div>
        */}
        <div className='fixed z-[-1] flex left-0 bottom-8 justify-center w-full md:hidden'>
          {/* 媒体图标 */}
          {/* {iconImages.map(({ icon, url }, index) => (
            <img
              key={index}
              src={icon}
              alt={`Icon ${index + 1}`}
              className='cursor-pointer h-8 ml-4'
              onLoad={() => handleIconLoaded(index)}
              onClick={() => handleIconClick(url)}
            />
          ))} */}
          <div className='text-2xl text-[#cfcfcf] ml-4 flex items-center'><UserProfileIcon /></div>
        </div>
      </header>
    </>
  );
};

// 省略样式定义，与之前示例相同

export default Header;

// 样式定义
const headerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const leftPartStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const logoStyle: React.CSSProperties = {
  width: 'auto',
};


