export const terms1 = `
本網站全面會員制度 
會員規則適用於所有加入本平台的用户,加入會員意味着您同意並遵守以下規定.

年齡限制 
加入會員的用户必須年滿18週歲,如發現未滿18週歲用户冒用他人身份加入會員,平台將立即終止其會員資格.

1.訂閲制度 
會員訂閲是通過支付一定費用獲得的特權,平台將提供月制度會員訂閲類型,具體內容和費用將在平台上明確標示. 
1.2 訂閲費用由平台決定,可能會根據市場需求進行調整.任何費用調整將提前通知會員,會員可自行決定是否繼續訂閲.

2. 內部信息可見性 
成為完全支付會員後,您將獲得訪問平台內部所有人信息的權限,包括聯繫我們等 
內部信息包括但不限於其他會員的個人資料、聯繫方式、互動記錄等.
會員在使用這些信息時應遵守適用法律法規,不得將信息用於非法,侵權或濫用等行為.
平台保留根據法律法規和經營需要隨時調整內部信息可見性的權利,所有調整將提前通知會員.

4. 會員責任與義務 
會員應妥善保管個人賬户信息,不得與他人分享賬户信息.因賬户信息泄露造成的一切後果由會員承擔.
會員在平台上的行為應符合法律法規,不得從事違法、虛假宣傳、侵權等活動.
會員不得以任何方式干擾平台正常運營,包括但不限於惡意軟件、網絡攻擊等行為. 
會員應尊重其他會員的權益,不得通過獲取內部信息進行騷擾,侵犯隱私等行為.

5. 終止會員資格 
平台有權基於實際情況隨時終止會員資格,包括但不限於違反會員規則、濫用平台特權、違法行為等.
若會員在終止資格後仍有未使用完的訂閲,平台將不與退還相應訂閲費用.

6. 免責條款 
會員在使用平台內部信息時應自行判斷信息真實性和合法性,平台不對會員因使用這些信息而產生的任何損失或糾紛負責.
平台不對由於不可抗力,技術故障等原因導致的服務中斷或數據丟失承擔責任.
請您在加入會員之前仔細閲讀並理解以上會員規則.
如果您同意並願意遵守這些規定,請繼續完成會員註冊和支付流程.
如有疑問或意見,請隨時與我們聯繫.
平台保留對會員規則進行調整的權利,調整後的規則將在公示並及時通知會員.

`

export const terms2 = `

本網站免責條款（以下簡稱“條款”）旨在明確用户（以下簡稱“您”或“用户”）在使用本網站（以下簡稱“網站”）時的權利、義務和責任。
請在使用本網站之前仔細閲讀並理解這些條款。
如果您不同意這些條款，請停止使用本網站。

1. 圖片和視頻禁止傳播 
本網站上的圖片和視頻素材（以下統稱“內容”）僅用於個人瀏覽和娛樂之目的,
任何以傳播、複製、公開展示等方式使用這些內容都是被禁止的,除非取得版權所有者的明確授權.
用户不得在未經授權的情況下將本網站上的任何內容傳播到其他網站、社交媒體平台或任何其他媒體渠道.
如發生此類違規行為，用户將承擔相應法律責任.如有發現本網站有權利將其會員權益停止.

2. 侵權處理 
如果您認為本網站上的內容侵犯了您的版權、隱私權或其他權利,請您立即通過郵件與我們聯繫,提供詳細的侵權信息.
我們將認真核實您的侵權投訴，並在確認侵權事實後，將依法採取措施刪除相關侵權內容.
您提交的侵權投訴應當真實、準確，我們不對惡意投訴行為承擔任何法律責任.

3. 免責聲明 
本網站上的內容僅供參考和娛樂,不構成任何法律、金融、醫療或其他專業領域的建議.用户應在需要專業意見時諮詢相關專業人士.
我們不對任何由於使用本網站而導致的任何直接或間接損失承擔責任,包括但不限於利潤損失、數據丟失等.
我們不保證本網站的內容的準確性、完整性和可靠性，用户應自行承擔使用本網站內容的風險.

4. 條款變更 
我們保留隨時修改這些條款的權利,修改後的條款將在本網站上公佈並自動生效.
您繼續使用本網站即表示您同意遵守修改後的條款.

請您在使用本網站之前仔細閲讀並理解以上免責條款.
如有任何疑問或意見，請隨時與我們聯繫。
我們保留對免責條款進行調整的權利，調整後的條款將在本網站上公示並及時生效.
`