import { useFormikContext, Field, FieldProps } from 'formik';

interface CustomCheckboxFieldProps {
  name: string;
  className?: string;
}

const CustomCheckboxField: React.FC<CustomCheckboxFieldProps> = ({ name, className }) => {
  const { values, handleChange } = useFormikContext<any>();

  const isChecked = values[name];

  const checkboxClasses = `border cursor-pointer w-5 h-5 rounded-full ${isChecked ? 'bg-[#ff548b]' : 'bg-[#494a4a]'}`;

  return (
    <div className={className}>
      <label htmlFor={name} className="inline-flex items-center">
        <div className={checkboxClasses}>
          <input
            type="checkbox"
            id={name}
            name={name}
            className="hidden"
            onChange={handleChange}
            checked={isChecked}
          />
          {/* <span className="h-4 w-4 border border-white bg-white rounded-sm"></span> */}
        </div>
      </label>
    </div>
  );
};

export default CustomCheckboxField;
