import React, { useEffect } from 'react';
import { Field, ErrorMessage, Formik, Form } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useNavigate, Navigate } from "react-router-dom";
import { getCookie, setLocalStorage } from '../../grocery/utils';
import Header from '../../component/Header';
import BackGround from '../../component/BackGround';
import CustomInputField from '../../component/CustomInputField';
import CustomCheckboxField from '../../component/CustomCheckboxField';
import banner from '../../asset/images/gray-banner.png';
import useAuthStore from '../../authStore';

// Define a type for the form values
interface FormValues {
  // username: string;
  password: string;
  repeatPassword: string;
  email: string;
  agreeToTerms: boolean;
}

// Validation schema with Yup
const validationSchema = Yup.object().shape({
  // username: Yup.string().required('Required'),
  password: Yup.string()
    .required('Required')
    .matches(/^(?=.*[a-zA-Z])(?=.*\d)/, 'Password must contain at least one letter and one number')
    .min(8, 'Password must be at least 8 characters long'),
  repeatPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  agreeToTerms: Yup.bool().oneOf([true], 'Please agree to the terms.')
});

async function signup(values: FormValues, navigate: any) {
  try {
    const response = await fetch(`${process.env.REACT_APP_PUBLIC_HOST}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });
    const data = await response.json();
    if(data?.rtoken){
      setLocalStorage('rToken', data?.rtoken)
    }
    
    if (data?.ok) {
      // 注册成功
      toast.success(data?.msg)
      console.log('response', response);
      // console.log('response.headers', response.headers);
      // const rToken = getCookie('rToken');
      console.log('rToken', data.rtoken);
      setLocalStorage('rToken', data?.rtoken)
      setTimeout(() => {
        navigate(`/verify?email=${values?.email}`)
      }, 300)
      return
    }


    if (data?.msg.includes('verify your email')) {
      navigate(`/verify?email=${values?.email}`)
      toast.error(data?.msg)
      return
    }
    toast.error(data?.msg)
  } catch (error: any) {
    toast.error(error?.response?.data || 'Somthing wrong!')
    console.error('Error:', error);
  }
}

const MyForm: React.FC = () => {
  const navigate = useNavigate()
  const { isLoggedIn } = useAuthStore();

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate('/explore')
  //   }
  // }, [])

  if (isLoggedIn) {
    return <div className='height-fill-available md:min-h-screen'>
      <Header />
      <BackGround />
      <div className='flex flex-col justify-center items-center md:mt-8'>
        <h2 className='font-montserrat text-3xl font-bold mb-4 md:mb-8' >You had join us</h2>
        <h2 className='font-montserrat text-3xl font-bold mb-4 md:mb-8 cursor-pointer' onClick={() => {
          navigate('/')
        }}>Go to Home</h2>
      </div>
    {/*   <div>
        <p className='font-montserrat text-p text-[#aaaaaa]'>Bunny.vip has a full <span onClick={() => {
          navigate('/membership')
        }} className="text-pink-500 hover:underline cursor-pointer">membership</span> system</p>
        <p className='font-montserrat text-p text-[#aaaaaa]'>Want to know about members?<span onClick={() => {
          navigate('/help')
        }} className="text-pink-500 hover:underline cursor-pointer"> Need Help?</span></p>
      </div> */}
    </div>
  }

  return (
    <div className='height-fill-available md:min-h-screen'>
      <Header />
      <BackGround />
      <div className='flex flex-col justify-center items-center md:mt-8'>
        <h2 className='font-montserrat text-3xl font-bold mb-4 md:mb-8' >加入我們！</h2>
        <Formik
          initialValues={{
            password: '',
            repeatPassword: '',
            email: '',
            agreeToTerms: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values: FormValues, { setSubmitting }) => {
            signup(values, navigate);
            setSubmitting(false);
          }}
        >
          {() => (
            <Form className='w-[80%] md:w-[400px] '>
              {/* <CustomInputField name="username" type="text" placeholder="Username" /> */}
              <CustomInputField name="email" type="email" placeholder="郵箱" />
              <CustomInputField name="password" type="password" placeholder="密碼" />
              <CustomInputField name="repeatPassword" type="password" placeholder="請再次輸入密碼" />

              <div>
                <label className='font-montserrat text-p text-[#aaaaaa] flex items-center'>
                  <CustomCheckboxField name="agreeToTerms" className="flex items-center mr-2" />
                  {/* <Field className='m-4 ml-0' type="checkbox" name="agreeToTerms" /> */}
                  <span className="ml-2">
                    同意我們的 <a href='/terms' className="text-pink-500 hover:underline">會員條款</a>
                  </span>
                  <div>
                    <ErrorMessage style={{ color: 'red', textAlign: 'left', marginLeft: '10px' }} name='agreeToTerms' component="div" />
                  </div>
                </label>
              </div>

              <button
                type="submit"
                className="my-4 bg-gradient-to-r from-[#F093FB] to-[#F5576C] text-white font-montserrat flex items-center justify-center py-2 px-4 rounded-full w-full"
              >
                註冊
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div>
        {/*
        <p className='font-montserrat text-p text-[#aaaaaa]'>Bunny.vip has a full <span onClick={() => {
          navigate('/membership')
        }} className="text-pink-500 hover:underline cursor-pointer">membership</span> system</p>
      */}
        <p className='font-montserrat text-p text-[#aaaaaa]'>想知道更多關於會員信息?<span onClick={() => {
          navigate('/help')
        }} className="text-pink-500 hover:underline cursor-pointer"> 需要幫助?</span></p>
        {/*
        <p className='font-montserrat text-p text-[#aaaaaa]'>Already have an account？
          <span onClick={() => {
            navigate('/login')
          }} className="text-pink-500 hover:underline cursor-pointer"> Login</span>
        </p>
        */}
      </div>
    </div>
  )

};

export default MyForm;
