import { useNavigate } from "react-router-dom";
import Header from "../../component/Header";
import EmailTextBox from "../../component/EmailTextBox";
import { contact } from "../../grocery/utils";
const iconImages = [
  { icon: "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png", url: 'https://twitter.com/Mr_BunnySex' },
  { icon: "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png", url: 'https://t.me/mr_bunny_vip' },
  { icon: "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png", url: 'https://cn.pornhub.com/channels/mr-bunny' },
];

const handleIconClick = (url: string) => {
  window.open(url, '_blank');
};
function About() {

  const navigate = useNavigate()
  return (
    <div className="bg-black">
      <Header />
      <div className="flex  flex-col justify-center items-center">
        <p className="text-2xl font-montserrat-extra-bold md:text-5xl text-[#FF548B] w-[80%] md:w-[400px]">NEED HELP?</p>
        <div className="flex flex-col md:flex-row justify-between w-[60%] md:my-8">
          <div onClick={() => {
            window.open('https://t.me/mr_bunny_vip')
          }} className="text-left my-4 md:my-0 cursor-pointer">
            <img alt="" src='https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/help-tg.svg' />
            <p>● Telegram:点击跳转,联系我们</p>
          </div>
          <div onClick={() => {
            window.open('https://twitter.com/Mr_BunnySex')
          }} className="text-left my-4 md:my-0 cursor-pointer">
            <img alt="" src='https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/help-tw.svg' />
            <p>● Twitter: 我们在Twitter上有官方账号,私信我们的官方账号</p>
          </div>
        </div>
        <div className="w-[60%] my-8">
          <div className="text-left">
            <img alt="" src='https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/help-email.svg' />
          </div>
          <EmailTextBox />
        </div>
        <div className='flex justify-center mb-4'>
          {/* 媒体图标 */}
          {iconImages.map(({ icon, url }, index) => (
            <img
              key={index}
              src={icon}
              alt={`Icon ${index + 1}`}
              className='cursor-pointer h-8 mr-8'
              onClick={() => handleIconClick(url)}
            />
          ))}
        </div>
        {/* <div className="flex mb-4">
          <p className="mr-8" onClick={() => { contact() }}>Contact </p>
          <p className="mr-8" onClick={() => { navigate('/membership') }}> Membership </p>
          <p className="mr-8" onClick={() => { navigate('/terms') }}> Terms </p>
        </div>
        <p className="mb-8">
          For business, please contact: <span className="text-[#FF548B] w-[60%] md:w-[400px] text-left">support@mrbunny.vip</span>
        </p> */}
      </div>
    </div>
  );
}

export default About;
