import React, { useState, useEffect } from 'react';
import { Skeleton, Space } from 'antd';
import MediaList from '../../component/MediaList';
import { getArtistsArtworkAndLike } from '../../api/like';
import { useParams, useNavigate } from 'react-router-dom';
import { getArtistss } from '../../api';
import useAuthStore from '../../authStore';
import { findAllByTestId } from '@testing-library/react';
import { contact } from '../../grocery/utils';

const Info: React.FC = () => {
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [imageList, setImageList] = useState<any[]>([]);
  const [videoList, setVideoList] = useState<any[]>([]);
  const [sumLikes, setSumLikes] = useState<number>(0);
  const [bannerLoad, setBannerLoad] = useState<boolean>(false);
  const [mBbannerLoad, setMBBannerLoad] = useState<boolean>(false);
  const [infoLoad, setInfoLoad] = useState<boolean>(false);
  const [artists, setArtists] = useState<any>({});
  const navigate = useNavigate()
  const { user } = useAuthStore()
  const params = useParams()
  const fetchMoreData = () => {
    // setTimeout(async () => {
    //   const res = await getArtistsArtwork()
    //   setMediaList(prev => [...prev, ...res.data]);
    // }, 1500);
  };

  useEffect(() => {
    if (params?.animalId) {
      const aid = params?.animalId
      if (!user?.uid) {
        return
      }
      getArtistsArtworkAndLike({ uid: user?.uid, aid: Number(aid) }).then((res) => {
        setMediaList(
          (res.filter((i: any) => (!!i.videoUrl)) || []).concat(
            res.filter((i: any) => (!i.videoUrl)) || []
          )
        );
      }).catch().finally()
      getArtistss({
        id: params.animalId
      }).then((res: any) => {
        console.log('res', res);
        setArtists(res?.data?.[0])
      }).catch().finally()
    }
  }, [])
  useEffect(() => {
    const iList = mediaList.filter(i => (!i.videoUrl))
    const vList = mediaList.filter(i => (!!i.videoUrl))
    let sumLike = 0;
    mediaList.forEach(i => {
      sumLike = sumLike + Number(i.totalLikes || 0)
    })
    setSumLikes(sumLike)
    setImageList(iList)
    setVideoList(vList)
  }, [mediaList])

  return (
    <div className='expolre'>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className='bg-[rgba(0,0,0,0.7)] relative  z-[1]'>
        <img
          className={`${bannerLoad?'hidden md:block w-full':'hidden'} `}
          src={artists?.banner}
          alt=''
          onLoad={() => {
            setBannerLoad(true)
          }}
          onError={() => {
            setBannerLoad(false)
          }}
        />
        <img
          src={artists?.mb_banner || artists?.banner}
          alt=''
          className={`${mBbannerLoad?'block md:hidden w-full':'hidden'} `}
          onLoad={() => {
            setMBBannerLoad(true)
          }}
          onError={() => {
            setMBBannerLoad(false)
          }}
        />
        {bannerLoad 
        ? <div style={{ background: 'linear-gradient(to bottom, transparent, rgba(0,0,0,0.8))' }} className='absolute left-0 top-0 z-9 w-full h-full  hidden md:block'  ></div> 
        : <div>
          <div className='bg-[#d0c9c9] pb-[17.7%] skeleton hidden md:block'  ></div>
        </div>}
        {mBbannerLoad ? <div style={{ background: 'linear-gradient(to bottom, transparent, rgba(0,0,0,0.8))' }} className='absolute left-0 top-0 z-9 w-full h-full  block md:hidden'  ></div> : <div>
          <div className='bg-[#d0c9c9] pb-[50.6%] skeleton block md:hidden'  ></div>
        </div>}
        <img
          src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/infoLogo.svg" alt=''
          className='w-32 left-4 top-4 md:w-64 cursor-pointer absolute md:left-16 md:top-8 '
          onClick={() => {
            navigate('/explore')
          }}
        />
        <div className='absolute right-0 bottom-0 flex md:m-4 items-center '>
          <div className='flex flex-col  p-2 md:py-4  md:px-16'>
            <p className='text-2xl md:text-5xl font-montserrat'>{sumLikes}</p>
            <p className='text-sm text-gray-300 mt-2 font-bold font-montserrat'>LIKE</p>
          </div>
          <span className='line mx-4 border-l-2 inline-block h-8 border-[rgba(242,242,242,0.25)]'></span>
          <div className='flex flex-col  p-2 md:py-4  md:px-16'>
            <p className='text-2xl md:text-5xl font-montserrat'>{imageList?.length || 0}</p>
            <p className='text-sm text-gray-300 mt-2 font-bold font-montserrat'>PHOTOS</p>
          </div>
          <span className='line  mx-4 border-l-2 inline-block h-8 border-[rgba(242,242,242,0.25)]'></span>
          <div className='flex flex-col  p-2 md:py-4  md:px-16'>
            <p className='text-2xl md:text-5xl font-montserrat'>{videoList?.length || 0}</p>
            <p className='text-sm text-gray-300 mt-2 font-bold font-montserrat'>VIDEO</p>
          </div>
        </div>
      </div>

      <div className='relative p-4  z-10 flex flex-col md:flex-row justify-between md:py-16  bg-black w-full md:max-w-[1280px] lg:max-w-[1440px] mx-auto'>
        <div>
          <div className='mt-[-2rem] md:mt-[-130px] rounded-full'>
            <img src={artists?.avatar} alt='' className='p-1 w-24 h-24 md:w-32 md:h-32 rounded-full border-4 border-[rgba(255,255,255,0.3)] bg-[rgba(255,255,255,0.3)]  ' />
          </div>
          <p className='ml-[6.5rem] md:ml-[140px] text-left mt-[-4.5rem] md:mt-2'>
            <h1 className='flex flex-col md:block text-md md:text-5xl font-montserrat-bold font-bold md:mt-[-2rem]'>
              <span> {artists?.username || ''}</span>
              <span className='text-[0.7rem] md:text-xl font-montserrat md:pl-2'>{artists?.englishName || ''}</span>
            </h1>
            <p onClick={() => {
              if (artists?.movieUrl) {
                window.open(artists?.movieUrl)
              }
            }} className={`flex my-2 items-center ${artists?.movieUrl ? 'cursor-pointer' : 'cursor-not-allowed'}  ${artists?.movieUrl ? 'text-[#FE5D75]' : 'text-[#414141]'}`}>
              <img src={artists?.movieUrl ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/video-white-2.svg" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/video-gray.svg"} alt='banner' className='w-4 md:w-8' />
              <span className='text-[0.6rem] md:text-xl pl-2 font-bold'>Click to view her movie</span>
            </p>
          </p>
          <p className='text-sm md:text-xl text-left pt-2 text-[rgba(255,255,255,0.7)]' >{artists?.description || ''}</p>
          <p className='text-left pt-2 text-[#8E8F93]'>
            <span className='text-white font-montserrat-bold text-2xl pr-2 font-bold'> Tags:</span>
            <span className='text-sm md:text-xl'>{artists?.tags?.split(',')?.map((tag: string) => (
              <span className='pr-2 text-gray-300 cursor-pointer'
                onClick={() => {
                  navigate(`/explore?tag=${tag}`)
                }}>#{tag}</span>
            ))}
            </span>
          </p>
        </div>
        <div className='absolute top-4 right-4 md:right-0 md:top-8'>
          <div onClick={() => { contact() }} className='text-[#FE5D75] bg-white rounded-full p-2 md:px-8 md:py-6 text-sm md:text-5xl font-montserrat-extra-bold font-extrabold cursor-pointer'>CONTACT US</div>
        </div>
      </div>
      <div className='md:pt-8 '>
        <MediaList
          mediaList={mediaList}
          setMediaList={setMediaList}
          fetchMoreData={fetchMoreData}
          showName={false}
          info={artists}
        />
      </div>
    </div >
  );
};

export default Info;
