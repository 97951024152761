import { S3 } from "@aws-sdk/client-s3";
// https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/pexels-sora-shimazaki-5668475.jpg
// https://fra1.digitaloceanspaces.com/mrrabbit/coproduce.svg
// https://mrrabbit.fra1.digitaloceanspaces.com/images/pexels-sora-shimazaki-5668475.jpg
export const endpoint = 'https://fra1.digitaloceanspaces.com';
export const ImageBaseUrl = 'https://mrrabbit.fra1.digitaloceanspaces.com'
const s3Client = new S3({
  // forcePathStyle: true, // Configures to use subdomain/virtual calling format.
  endpoint,
  // https://mr.rabbit.ams3.digitaloceanspaces.com
  region: "fra1",
  credentials: {
    // accessKeyId: 'DO00VM9Y3T4HRV6ZFNPH',
    accessKeyId: process.env.REACT_APP_SPACES_KEY,
    // secretAccessKey: 'GVnqIVbQlZpeYVhvb66UG6IjrCjEfn8txcfav7//eAc'
    secretAccessKey: process.env.REACT_APP_SPACES_SECRET
  }
});

export { s3Client };


