import React, {useEffect, useState} from 'react';
import Trip from "./trip"
import {tripData3} from "./data";
import PlanHeader from '../../component/PlanHeader';
import Footer from '../../component/Footer';
import Button from '../../component/Button';
import { useNavigate } from "react-router-dom";

function PlanMeihuo() {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [maxWidth, setMaxWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setIsMobile(document.documentElement.clientWidth <= 768);
    setMaxWidth(window.innerWidth);
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (

    <div className={`flex font-oldchinese flex-col bg-zinc-900 max-w-[1440px] mx-auto`}>
      <PlanHeader/>
      <div
        /* className="flex items-center justify-center  overflow-hidden relative flex-col items-end px-20 pt-11 pb-20 w-full min-h-[763px] max-md:px-5 max-md:max-w-full" */
        className={`flex items-center justify-center overflow-hidden relative flex-col items-end w-full max-md:max-w-full ${isMobile ? 'w-full object-cover' : 'px-20 min-h-[763px] '}`}
        style={{
          backgroundImage: /* 'url(https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/mfgsg.png)' */
          isMobile ? "url(https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/%E4%B8%9C%E4%BA%AC%E8%87%B3%E5%B0%8A%E4%B9%8B%E8%A1%8C%204%E5%A4%A93%E6%99%9A.jpg)" : "url(https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/mfgsg.png)",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div /* className="relative self-center mt-48 text-8xl text-center text-white leading-[90px] tracking-[9.65px] max-md:mt-10 max-md:max-w-full max-md:text-4xl max-md:leading-10" */
        className={`relative self-center font-oldchinese text-center text-white leading-[90px] tracking-[9.65px] max-md:mt-10 max-md:max-w-full max-md:leading-10 ${isMobile ? 'text-[35px]' : 'mt-48 text-8xl'}`}>
          東京至尊之行
          <br />
          4天3晚
        </div>
        <div /* className="relative self-center mt-12 mb-28 text-4xl text-white leading-[52.5px] max-md:my-10 max-md:max-w-full" */
        className={`relative self-center font-oldchinese text-white leading-[52.5px] max-md:max-w-full ${isMobile ? 'mt-6 mb-2 text-[20px]' : 'mt-12 mb-28 text-4xl'}`}>
        「上海出发」兔子超級至尊行程計畫
        </div>
      </div>

      <div /* className="flex flex-col items-start px-20 py-20 mt-1 w-full bg-zinc-900 max-md:px-5 max-md:max-w-full" */
      className={`flex font-oldchinese flex-col items-start mt-1 w-full bg-zinc-900 max-md:max-w-full ${isMobile ? 'px-2 py-4 ' : 'px-20 py-20 '}`}>
        <div className="flex flex-col self-stretch ml-2.5 max-md:max-w-full">
          <div /* className="text-4xl text-left text-white leading-[61.5px] max-md:max-w-full" */
          className={`text-left text-white leading-[61.5px] max-md:max-w-full ${isMobile ? 'text-[16px]' : 'text-4xl '}`}>「上海出发」東京魅惑之行 4天3晚
          </div>
          <div className="flex px-4 flex-col mt-8 max-md:max-w-full">
            <div /* className="text-xl text-left leading-5 text-rose-300 tracking-[2.2px] max-md:max-w-full" */
            className={`text-left leading-5 text-rose-300 tracking-[2.2px] max-md:max-w-full ${isMobile ? 'text-[20px]' : 'text-xl'}`}>
              特别活动体验:
            </div>
            <div /* className="flex flex-col mt-3 gap-6 text-2xl text-left leading-6 tracking-[2.75px] max-md:max-w-full" */
            className={`flex flex-col mt-3 gap-6 text-left leading-6 tracking-[2.75px] max-md:max-w-full ${isMobile ? 'text-[15px]' : 'text-2xl'}`}>

              <div className='flex flex-col gap-3'> 
                <div className='text-yellow-400'>*美女24小时全天过夜陪玩 </div>
                <div className='text-white'>晚餐:日式和牛料理</div>
              </div>

              <div className='flex flex-col gap-3'> 
                <div className='text-yellow-400'>*美女陪玩富士山*俯瞰東京直升機體驗(东京湾)*日本老牌吉原泡泡浴體驗 </div>
                <div className='text-white'>早餐:酒店自助早餐,午餐:传统日式手打乌冬面套餐,晚餐:日本河豚刺生</div>
              </div>

              <div className='flex flex-col gap-3'> 
                <div className='text-yellow-400'>*成人AV场参观*日本美女上門空降體驗 </div>
                <div className='text-white'>早餐:酒店自助早餐,午餐:日式人体盛宴,晚餐:日本蟹道乐</div>
              </div>
            </div>
            <div /* className="mt-10 text-left text-4xl text-white leading-[60px] max-md:max-w-full" */
            className={`mt-10 text-left text-white leading-[60px] max-md:max-w-full ${isMobile ? 'text-[25px]' : 'text-4xl'}`}>
              每日行程
            </div>

            <Trip data={tripData3}></Trip>
          {/*   <div className="flex flex-col mt-28 ml-10 max-w-full text-2xl text-left leading-9 text-white w-[576px] max-md:mt-10">
              <div className="max-md:max-w-full">预订须知</div>
              <div className="mt-5 text-base leading-6 text-zinc-500 max-md:max-w-full">
                本产品由兔子先生-兔子旅行委提供
                <br />
                确定预定内容后,需支付百分之30%的费用作为定金,如果客人取消预定,定金将不予退款
                <br />
                取消预定,定金将不予返还
              </div>
              <div className="mt-11 max-md:mt-10 max-md:max-w-full">
                日本色情行业不接受以下人群
              </div>
              <div className="mt-5 text-base leading-6 text-zinc-500 max-md:max-w-full">
                未满 18 岁,酗酒,有吸⾷药物,黑帮相关者. <br />
                携带性病等传染性疾病者
                <br />
                言行暴力者
                <br />
                在未交涉情况下对日本女性强行发生性⾏为者
              </div>
            </div> */}
          </div>
        </div>
      </div>



      <div className={`flex font-oldchinese flex-col max-w-full text-left leading-9 text-white ${isMobile ? 'mt-12 ml-10 mb-12' : 'text-2xl mt-28 ml-36 mb-36'}`} style={{ maxWidth: `${maxWidth}px`, overflowWrap: 'break-word'}}>
        <div className="max-md:max-w-full">预订须知</div>
        <div className={`text-zinc-500 max-md:max-w-full ${isMobile ? 'text-[10px] mt-2 mr-2 leading-0' : 'text-base mt-5 leading-6'}`} style={{ maxWidth: `${maxWidth}px`, overflowWrap: 'break-word'}}>
          <div> 本产品由兔子先生-兔子旅行委提供</div>
          <div>确定预定内容后,需支付百分之30%的费用作为定金,如果客人取消预定,定金将不予退款</div>
          <div> 取消预定,定金将不予返还</div>
        </div>
        <div className="mt-11 max-md:mt-6 max-md:max-w-full">
          日本色情行业不接受以下人群
        </div>
        <div className={`text-zinc-500 max-md:max-w-full ${isMobile ? 'text-[10px] mt-0 leading-0' : 'text-base mt-5 leading-6'}`}>
          <div>未满 18 岁,酗酒,有吸⾷药物,黑帮相关者.</div>
          <div>携带性病等传染性疾病者</div>
          <div>言行暴力者</div>
          <div>在未交涉情况下对日本女性强行发生性⾏为者</div>
        </div>
      </div>

      <div className={`relative flex flex-col ${isMobile ? 'mt-12' : 'py-20 px-16'} max-md:px-5`}>
        <img
          loading="lazy"
          src={isMobile ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/Mask%20group%20(1).png" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/dreamplan.png"}
          alt="Banner background"
          className={`absolute inset-0 object-cover w-full h-full max-md:max-w-full`}
        />
        <div className="flex relative gap-2.5 self-start whitespace-nowrap leading-[150%] max-md:flex-wrap">
          <div className={`font-semibold flex-auto text-white ${isMobile ? 'text-2xl mt-6' : 'text-8xl mt-6'}`}>
            Dream
          </div>
          <div className={`font-oldchinese flex-auto my-auto text-pink-400 max-md:max-w-full ${isMobile ? 'text-xl mt-8' : 'text-6xl mt-12'}`}>
            兔子先生男優圓夢計畫
          </div>
        </div>
        <div className={`flex relative gap-3.5 items-start self-start font-semibold max-md:flex-wrap ${isMobile ? 'ml-2 leading-[100%] mt-2' : 'ml-28 leading-[150%] mt-6'}`} >
          <div className={`self-start text-white ${isMobile ? 'text-2xl' : 'text-7xl'}`}>
            Plan
          </div>
          <div className={`flex-auto self-end text-pink-400 ${isMobile ? 'text-2xl' : 'text-4xl'}`}>
            Bunny Journey
          </div>
        </div>
        <div className={`font-oldchinese relative self-center text-center text-white max-md:max-w-full ${isMobile ? 'text-[10px] mt-6' : 'text-2xl mt-11'}`}>
          兔子先生男優圓夢計畫,實現你做男優的夢想,不管是刺激的劇情或者日式溫柔,泡泡浴,我們都會滿足你
        </div>

        <div className={`relative gap-3.5 self-center rounded-none ${isMobile ? 'mt-6 mb-2 py-2 pl-0' : 'mt-20 pr-1.5 pl-7'}`}>
          <Button onClick={() => navigate('/dreamplan')} className="font-oldchinese flex-auto my-auto">
            聯繫我們
          </Button>
        </div>
      </div>


      <div className="flex max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow items-start px-20 py-10 w-full bg-zinc-800 max-md:px-5 max-md:max-w-full">
            <div /* className="font-oldchinese text-5xl text-white leading-[79.5px] max-md:max-w-full max-md:text-4xl" */
              className={`font-oldchinese text-white max-md:max-w-full ${isMobile ? 'text-3xl leading-[40px]' : 'text-5xl leading-[79.5px]'}`}>
              兔子先生
            </div>
            <div /* className="mt-3.5 text-5xl text-pink-400 font-[275] leading-[79.5px] max-md:max-w-full max-md:text-4xl" */
              className={`mt-3.5 text-pink-400 font-[275] max-md:max-w-full ${isMobile ? 'text-2xl leading-[40px]' : 'text-5xl leading-[79.5px]'}`}>
              Mr.BunnySEX
            </div>
            <div /* className="font-oldchinese mt-4 text-2xl leading-8 text-white max-w-[506px] max-md:max-w-full text-left mx-auto px-1" */
              className={`font-oldchinese mt-4 leading-8 text-white max-w-[506px] max-md:max-w-full text-left mx-auto px-1${isMobile ? 'text-xs' : 'text-2xl'}`}>
              兔子先生,我們專注於拍攝日本女生同時我們面向華人,讓華人深切的感受日本女生的溫柔,刺激的劇情,強烈的視覺影視,我們擁有上百部成人作品,各種類型的女生,帶給你全新的體驗.
            </div>
            <div className="flex gap-5 justify-between mt-10 max-md:mt-10">
              <img
                loading="lazy"
                srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/rabbit.svg"
                //className="shrink-0 max-w-full ml-12 object-contain w-[170px]"
                className={`shrink-0 max-w-full ml-12 object-contain ${isMobile ? 'w-[100px]' : 'w-[170px]'}`}
              />
              <img
                loading="lazy"
                srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
                //className="shrink-0 max-w-full ml-6 object-contain w-[170px]"
                className={`shrink-0 max-w-full ml-6 object-contain ${isMobile ? 'w-[100px]' : 'w-[170px]'}`}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[50%] max-md:w-full justify-center items-center">
          <img
            loading="lazy"
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/homeFrame.jpg"
            className="w-full object-cover"
          />
        </div>
      </div>
      <Footer></Footer>

    </div>
  );
}

export default PlanMeihuo;