import React, { useState, useEffect, useCallback } from 'react';
import { getArtistsArtworkByRandom } from '../../api';
import SearchHeader from '../../component/SearchHeader';
import { useSearchParams } from 'react-router-dom';
import MediaList from '../../component/FilterMediaList';
import useAuthStore from '../../authStore';

//export const typeMap: { [key: string]: string } = { 'actress': '女優', 'amateur': '素人' };

const MediaWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tagFilter, setTagFilter] = useState<string | undefined>(undefined);
  //const [typeFilter, setTypeFilter] = useState<string | undefined>(undefined);
  const { user } = useAuthStore();

  const fetchMoreData = async () => {
    if (!user?.uid) {
      return;
    }
    console.log('run filter fetch');
    setLoading(true);
    const excludedIds = mediaList?.length ? mediaList.filter(i => (i?.id)).map(i => (i.id)) : [0];
    const sort = searchParams.get('sort') || 'rand';
    const tag = searchParams.get('tag') || undefined;
    //const type = searchParams.get('type') || undefined;
    const name = searchParams.get('name') || undefined;

    console.log('Fetch data tages:', tag); 
    try {
      const res = await getArtistsArtworkByRandom({ orderBy: sort, limit: 20, excludedIds, uid: user?.uid, tag, name });
      const newList = res?.data || [];
      const mlist = mediaList.concat(newList);
      setMediaList(mlist);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    if (!user?.uid) {
      return;
    }
    console.log('run filter load');
    setLoading(true);
    const excludedIds = [0];
    const sort = searchParams.get('sort') || 'rand';
    const tag = searchParams.get('tag') || undefined;
    const type = searchParams.get('type') || undefined;
    const name = searchParams.get('name') || undefined;
    setTagFilter(tag);
    //setTypeFilter(type);
    console.log('Load data tag:', tag);
    console.log('Load data tag:', tag);
    try {
      const res = await getArtistsArtworkByRandom({ orderBy: sort, limit: 20, excludedIds, uid: user?.uid, tag, name });
      console.log('meidawall getArtistsArtworkByRandom', res); 
      const newList = res?.data || [];
      console.log('new list in old function:',newList);
      setMediaList(newList);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, user?.uid]);

  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
  }, [searchParams, loadData]);

  return (
    <div className='explore'>
      <div className='fixed left-0 top-0 w-full z-10 bg-[rgba(0,0,0,0.7)]'>
        <SearchHeader />
      </div>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className='h-28 lg:h-32'></div>
      {tagFilter && (
            <p className='mx-auto text-left text-xl md:text-4xl text-white text-bold font-montserrat pb-4 max-w-[90rem] px-4'>
              tagged
              <span>「
                <span className='text-[#00F0FF]'>#{tagFilter}</span>
                」
              </span>
              {/* ({mediaList?.length || 0} results) */}
              <span className='text-[#c4eb25] cursor-pointer ml-4' onClick={() => {
                setTagFilter(undefined);
                setSearchParams(prevParams => {
                  const params = new URLSearchParams(prevParams);
                  params.delete('tag');
                  return params;
                });
              }}>X</span>
            </p>
          )}
      {/*tagFilter || typeFilter ? (
        <div>
          {tagFilter && (
            <p className='mx-auto text-left text-xl md:text-4xl text-white text-bold font-montserrat pb-4 max-w-[90rem] px-4'>
              tagged
              <span>「
                <span className='text-[#00F0FF]'>#{tagFilter}</span>
                」
              </span>
              ({mediaList?.length || 0} results)
              <span className='text-[#c4eb25] cursor-pointer ml-4' onClick={() => {
                setTagFilter(undefined);
                setSearchParams(prevParams => {
                  const params = new URLSearchParams(prevParams);
                  params.delete('tag');
                  return params;
                });
              }}>X</span>
            </p>
          )}
          {/*{typeFilter && (
            <p className='mx-auto text-left text-xl md:text-4xl text-white text-bold font-montserrat pb-4 max-w-[90rem] px-4'>
              type
              <span>「
                <span className='text-[#00F0FF]'>#{typeFilter}</span>
                」
              </span>
              ({mediaList?.length || 0} results)
              <span className='text-[#c4eb25] cursor-pointer ml-4' onClick={() => {
                setTypeFilter(undefined);
                setSearchParams(prevParams => {
                  const params = new URLSearchParams(prevParams);
                  params.delete('type');
                  return params;
                });
              }}>X</span>
            </p>
          )}
        </div>
      ) : null}*/}
      
      <MediaList mediaList={mediaList} setMediaList={setMediaList} fetchMoreData={fetchMoreData} loading={loading} />
    </div>
  );
};

export default MediaWall;
