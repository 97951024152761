import React, { useEffect, useState } from 'react';
import { message, Upload, Button, Image } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { s3Client, ImageBaseUrl } from '../grocery/s3';

interface ImageUploadListProps {
  onUploadSuccess?: (urls: string[]) => void;
}

const ImageUploadList: React.FC<ImageUploadListProps> = ({ onUploadSuccess }) => {

  const [imageUrls, setImageUrls] = useState<string[]>([]);

  // useEffect(() => {
  //   setImageUrls(initialImageUrls);
  // }, [initialImageUrls]);

  const isImage = (file: RcFile) => {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
    if (!acceptedImageTypes.includes(file.type)) {
      message.error('You can only upload image files!');
      return false;
    }
    return true;
  };

  const beforeUpload = async (file: RcFile) => {
    if (!isImage(file)) {
      return false;
    }
    console.log('beforeUpload', file);
    try {
      const folder = 'images';
      const bucketName = 'mrrabbit';
      const fileName = Date.now() + file.name;
      const remoteFilePath = `${folder}/` + fileName;
      const params = {
        Bucket: bucketName,
        Key: remoteFilePath,
        Body: file,
        ACL: 'public-read',
      };
      const res = await s3Client.send(new PutObjectCommand(params));
      if (res?.ETag) {
        message.success(`${file.name} file uploaded successfully`);
        const uploadUrl = `${ImageBaseUrl}/${folder}/${fileName}`;
        setImageUrls(prevUrls => {
          const newUrls = [...prevUrls, uploadUrl];
          onUploadSuccess?.(newUrls);
          return newUrls;
        });
        // setImageUrls(prevUrls => [...prevUrls, uploadUrl]);
        // onUploadSuccess?.([...imageUrls, uploadUrl]);
      }
      return false;
    } catch (error) {
      message.error(`${file.name} file upload failed.`);
      return false;
    }
  };
  console.log('imageUrls', imageUrls);

  return (
    <div className='flex flex-col'>
      <Upload
        showUploadList={false}
        beforeUpload={beforeUpload}
        multiple
      >
        <Button>Upload Images</Button>
      </Upload>
      {imageUrls.map((url, index) => (
        <Image key={index} src={url} width={100} alt={`Uploaded ${index + 1}`} className='m-2' />
      ))}
    </div>
  );
};

export default ImageUploadList;
