import React, { useState, useEffect, useCallback } from 'react';
import { getArtistsArtworkAndLike, getListByTag, getArtistsArtworkByRandom } from '../../api';
import SearchHeader from '../../component/SearchHeader';
import { useSearchParams } from 'react-router-dom';
import MediaList from '../../component/MediaList';
import useAuthStore from '../../authStore';
import { throttle } from '../../grocery/utils';

export const typeMap = { 'actress': '女優', 'amateur': '素人' } as any;

const MediaWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tagFilter, setTagFilter] = useState<string | undefined>(undefined);
  const { user } = useAuthStore();

  function handleSearch(value: string) {
    // 在这里可以根据用户输入的值进行筛选操作
    // setSearchValue(value);
  }

  const fetchMoreData = async () => {    
    if (!user?.uid) {
      return;
    }
    setLoading(true);
    const excludedIds = mediaList?.length ? mediaList?.map(i => i.id) : [0];
    const sort = searchParams.get('sort') || 'rand';
    const tag = searchParams.get('tag') || undefined;
    const name = searchParams.get('name') || undefined;
    getArtistsArtworkByRandom({ orderBy: sort, limit: 20, excludedIds, uid: user?.uid, tag, name }).then((res: any) => {
      let newList = res?.data || [];
      const mlist = mediaList?.concat(newList);
      setMediaList(mlist);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }

  const loadData = useCallback(async () => {
    if (!user?.uid) {
      return;
    }
    setLoading(true);
    const excludedIds = [0];
    const sort = searchParams.get('sort') || 'rand';
    const tag = searchParams.get('tag') || undefined;
    const name = searchParams.get('name') || undefined;
    setTagFilter(tag);
    getArtistsArtworkByRandom({ orderBy: sort, limit: 20, excludedIds, uid: user?.uid, tag, name }).then((res: any) => {
      let newList = res?.data || [];
      setMediaList(newList);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }, [searchParams, user?.uid]);

  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
  }, [searchParams, loadData]);

  return (
    <div className='expolre'>
      <div className='fixed left-0 top-0 w-full z-10 bg-[rgba(0,0,0,0.7)]'>
        <SearchHeader />
      </div>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className='h-28 lg:h-32'></div>
      {tagFilter ? (
        <div>
          <p className='mx-auto text-left text-xl md:text-4xl text-white text-bold font-montserrat pb-4 max-w-[90rem] px-4'>
            tagged
            <span>「
              <span className='text-[#00F0FF]'>#{tagFilter}</span>
              」
            </span>
            ({mediaList?.length || 0} results)
            <span className='text-[#c4eb25] cursor-pointer ml-4' onClick={() => {
              setTagFilter('');
              setSearchParams();
            }}>X</span>
          </p>
        </div>
      ) : null}
      <MediaList mediaList={mediaList} setMediaList={setMediaList} fetchMoreData={fetchMoreData} loading={loading} />
    </div>
  );
};

export default MediaWall;

/* import React, { useState, useEffect, useCallback } from 'react';
import { getArtistsArtworkAndLike, getListByTag, getArtistsArtworkByRandom, getAD } from '../../api';
import SearchHeader from '../../component/SearchHeader';
import { useSearchParams } from 'react-router-dom';
import MediaList from '../../component/MediaList';
import useAuthStore from '../../authStore';
import { throttle } from '../../grocery/utils'
import { log } from 'console';
export const typeMap = { 'actress': '女優', 'amateur': '素人', } as any

const adListIndex = [5, 8, 12, 20, 25]

const getMixinList = ({ mixinList, adList, mediaList }: any) => {
  try {
    const tempList = [].concat(mediaList);
    adList.forEach((ad: any, index: number) => {
      const randomIndex = adListIndex?.[index]
      // const randomIndex = parseInt((Math.random() * mediaList?.length).toString(), 10);
      ad.showType = 'ad'
      tempList.splice(randomIndex, 0, ad as never);
    });
    // return mixinList.concat(tempList);
    return tempList;
  } catch (error) {
    return []
  }
}


const MediaWall: React.FC = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [adList, setAdlist] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tagFilter, setTagFilter] = useState<string | undefined>(undefined);
  const { user } = useAuthStore()
  function handleSearch(value: string) {
    // 在这里可以根据用户输入的值进行筛选操作
    // setSearchValue(value);
  }

  const insert3RandomADs = (newList=[],tempList=[]) => {
    const ads =adList?.length ? adList:tempList
    for (let i = 0; i < 3; i++) {
      const adRandomIndex = parseInt((Math.random() * ads?.length).toString(), 10);
      const newAd = ads?.[adRandomIndex]  as never
      const insertRandomIndex = parseInt((Math.random() * newList?.length).toString(), 10);
      newList?.splice(insertRandomIndex, 0, newAd)
    }
    return newList
  }

  const fetchMoreData = async () => {    
    if (!user?.uid) {
      return
    }
    setLoading(true)
    const excludedIds = mediaList?.length ? mediaList?.filter(i => (i?.id && i.showType!=='ad'))?.map(i=>(i.id)):[0]
    const sort = searchParams.get('sort') || 'rand'
    const tag = searchParams.get('tag') || undefined
    const name = searchParams.get('name') || undefined
    getArtistsArtworkByRandom({ orderBy: sort, limit: 20, excludedIds, uid: user?.uid, tag, name }).then((res: any) => {
      let newList = res?.data || []
      newList = insert3RandomADs(newList)
      if(newList?.length === 3){
        return
      }
      const mlist = mediaList?.concat(newList)
      setMediaList(mlist)
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false)
    })
  }

  const loadData = useCallback(async() => {
    if (!user?.uid) {
      return
    }
    const res = await getAD()
    const tempList = res?.data.map((ad: any, index: number) => ({
      showType: 'ad',
      ...ad,
      id:'ad-'+ad.id
    }));
    console.log('tempList',tempList);
    console.log('adList',adList);
    
    await setAdlist(tempList)
    
    setLoading(true)
    const excludedIds = [0];
    const sort = searchParams.get('sort') || 'rand'
    const tag = searchParams.get('tag') || undefined
    const name = searchParams.get('name') || undefined
    setTagFilter(tag)
    getArtistsArtworkByRandom({ orderBy: sort, limit: 20, excludedIds, uid: user?.uid, tag, name }).then((res: any) => {
      let newList = res?.data || []
      newList = insert3RandomADs(newList,tempList)
      setMediaList(newList)
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false)
    })
  },[searchParams])

  useEffect(() => {
    loadData()
    window.scrollTo(0, 0);
  }, [searchParams])

  return (
    <div className='expolre'>
      <div className='fixed left-0 top-0 w-full z-10 bg-[rgba(0,0,0,0.7)]'>
        <SearchHeader />
      </div>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className='h-28 lg:h-32'></div>
      {tagFilter ? <div className=''>
        <p className='mx-auto text-left text-xl md:text-4xl text-white text-bold font-montserrat pb-4 max-w-[90rem] px-4'>
          tagged
          <span>「
            <span className='text-[#00F0FF]'>#{tagFilter}</span>
            」
          </span>
          ({mediaList?.length || 0} results)
          <span className='text-[#c4eb25] cursor-pointer ml-4' onClick={() => {
            setTagFilter?.('')
            setSearchParams()
          }}>X</span>
        </p>
      </div> : null}
      <MediaList mediaList={mediaList} setMediaList={setMediaList} fetchMoreData={fetchMoreData} loading={loading} adList={adList} />
    </div>
  );
};

export default MediaWall;
 */