import React, { useState, useEffect, useRef } from 'react';

interface BackGroundProps { }

const videoList = [
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/videos/home-bg4.mp4',
  // 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/videos/home-bg2.mp4',
  // 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/videos/home-bg3.mp4',
];

const gifList = [
  { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/videos/bg1.gif', duration: 10120 },
  { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/videos/bg2.gif', duration: 11210 },
  { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/videos/bg3.gif', duration: 8000 },
  { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/videos/bg4.gif', duration: 8180 },
];

const BackGround: React.FC<BackGroundProps> = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentGifIndex, setCurrentGifIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    const isMobile = window?.innerWidth <= 768; // 适应不同移动设备的宽度阈值
    if (isMobile) {
      return
    }
    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, []);

  useEffect(() => {
    // 预加载所有 GIF
    gifList.forEach((gif) => {
      const img = new Image();
      img.src = gif.url;
    });

    const currentDuration = gifList[currentGifIndex].duration;

    const timer = setTimeout(() => {
      setCurrentGifIndex((prevIndex) => (prevIndex + 1) % gifList.length);
    }, currentDuration);

    return () => {
      clearTimeout(timer);
    };
  }, [currentGifIndex]);

  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoList.length);
  };

  return (
    <div className={`fixed left-0 top-0 height-fill-available md:min-h-screen max-h-screen md:h-screen w-screen overflow-hidden z-[-1]`}>
      {window?.innerWidth > 768 ? <video
        ref={videoRef}
        className="object-cover w-full h-full hidden md:block lg:block xl:block"
        autoPlay
        loop={false}
        muted
        preload='auto'
        src={videoList[currentVideoIndex]}
      >
        <source src={videoList[currentVideoIndex]} type="video/mp4" />
      </video> : null}
      {/* 只在移动端显示 */}
      <img style={{ display: 'none' }} src={gifList[currentGifIndex].url} alt="Background GIF" className="md:hidden lg:hidden xl:hidden object-cover w-full h-full" />
      {/* 隐藏的预加载视频 */}
      {window?.innerWidth > 768 ? <div style={{ display: 'none' }}>
        {videoList.map((src, index) => {
          if (index !== currentVideoIndex) {
            return (
              <video key={src} preload="auto">
                <source src={src} type="video/mp4" />
              </video>
            );
          }
          return null;
        })}
      </div> : null}
    </div>
  );
};

export default BackGround;
