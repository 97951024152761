import Header from "../../component/Header";
import { terms1, terms2 } from "./text";
function About() {
  return (
    <div className="bg-[#181818]">
      <Header />
      <div className="flex  flex-col justify-center items-center mb-4">
        <p className="text-2xl md:text-5xl font-montserrat-extra-bold text-[#FF548B] w-[80%] md:w-[800px]"> 會員條款</p>
        <pre className="text-sm md:text-xl text-left w-[80%] md:w-[800px] my-8">{terms1}</pre>
        <p className="text-2xl md:text-5xl font-montserrat-extra-bold text-[#FF548B] w-[80%] md:w-[800px]">免責條款</p>
        <pre className="text-sm md:text-xl text-left w-[80%] md:w-[800px] my-8">{terms2}</pre>                  
      </div>
             </div>
            );
}

export default About;
