import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const NavigationButtons = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Button className='m-2' onClick={() => navigate('/')}>Home</Button>
      <Button className='m-2' onClick={() => navigate('/admin')}>Admin</Button>
      <Button className='m-2' onClick={() => navigate('/tags')}>Tags</Button>
      <Button className='m-2' onClick={() => { navigate('/ad');  }}>Advertising</Button>
      <Button className='m-2' onClick={() => { navigate('/order');  }}>Orders</Button>
      <Button className='m-2' onClick={() => { navigate('/user');  }}>Users</Button>
    </div>
  );
};

export default NavigationButtons;
