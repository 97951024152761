import { useNavigate } from 'react-router-dom';
import useAuthStore from '../authStore';
import React, { useState, useEffect, useRef } from 'react';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthStore();
  const [isMobile, setIsMobile] = useState(false);

  const handleTypeClick = (type: string) => {
    navigate(`/girlType?type=${type}`);
  };

  const handleAnchorClick = (id: string, type: string) => {
    if (isLoggedIn) {
      handleTypeClick(type);
    } else {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // 延时以确保页面已加载
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 设定一个阈值来检测是否为手机端
    };

    handleResize(); // 初始化检测
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col items-center max-w-[1440px] mx-auto pt-20 pb-5 bg-neutral-900">



      <div className={`flex flex-row gap-5 w-full max-w-screen-xl mx-auto max-md:flex-wrap max-md:max-w-full ${isMobile ? 'text-center' : ''}`}>

        <div className={`flex flex-col w-1/3 px-5 text-white leading-[150%] ${isMobile ? 'mx-auto text-sm items-center' : 'text-left'}`}>
          <div className={`flex gap-1.5 ${isMobile ? 'text-4xl' : 'text-6xl'} whitespace-nowrap`}>
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/gray-banner.png"
              className="shrink-0 border-solid object-contain stroke-[2.931px] stroke-white max-w-xs max-h-xs mx-auto"
            />
          </div>
          <div className={`mt-6 leading-10 ${isMobile ? 'text-xs' : 'text-xl'}`} style={{ whiteSpace: 'nowrap' }}>
            Bespoke Travels Exclusively Yours<br />
            高端私人訂製旅行,享受至尊服務
          </div>
        </div>

        <div className={`flex flex-col w-1/3 max-md:ml-0 max-md:w-full ${isMobile ? 'mx-auto text-sm items-center' : 'ml-36 text-left'}`}>
          <div className={`flex flex-col grow px-5 ${isMobile ? 'mt-10 items-center' : 'mt-0'}`}>
            <div className={`leading-8 text-white ${isMobile ? 'text-lg' : 'text-2xl'}`}>
              快速連結
            </div>
            <div className={`mt-7 leading-7 text-white ${isMobile ? 'text-xs' : 'text-sm'}`}>
              <span onClick={() => navigate('/')} className="cursor-pointer">主頁</span>
              <br />
              <span onClick={() => handleAnchorClick('bunny-girl', '兔子先生專屬女優')} className="text-pink-400 cursor-pointer">
                Bunny Girl 兔女郎
              </span>
              <br />
              <span onClick={() => handleAnchorClick('famous-girl', '日本知名女優')} className="text-pink-400 cursor-pointer">
                Famous Girl 日本知名女優
              </span>
              <br />
              <span onClick={() => handleAnchorClick('female-amateur', '日本素人')} className="text-pink-400 cursor-pointer">
                Female Amateur 日本素人
              </span>
              <br />
              <span onClick={() => navigate('/about')} className="cursor-pointer">About 關於我們</span>
              <br />
             {/*  <span onClick={(e) => {
                e.stopPropagation();
                window.open('https://t.me/mr_bunny_vip');
              }} className="cursor-pointer">Contact 聯繫我們</span> */}
            </div>
          </div>
        </div>

        <div className={`flex flex-col w-1/3 max-md:ml-0 max-md:w-full ${isMobile ? 'mx-auto text-sm items-center' : 'text-left'}`}>
          <div className={`flex flex-col grow px-5 ${isMobile ? 'mt-10 items-center' : 'mt-0'}`}>
            <div className={`leading-8 text-white ${isMobile ? 'text-lg' : 'text-2xl'}`}>聯繫我們</div>
            <div className={`mt-4 ${isMobile ? 'text-xs' : 'text-sm'} text-gray-400`}>
              For business, please contact(商務合作):{" "}
              <span className="text-pink-400">support@mrbunny.vip</span>
            </div>
            {/* <div className={`mt-3.5 ${isMobile ? 'text-xs' : 'text-sm'} text-gray-400`}>
              Emergency Contact(緊急聯繫Telegram):
            </div>
            <div className={`mt-1.5 ${isMobile ? 'text-xs' : 'text-sm'} text-pink-400`}>
              https://t.me/mr_bunny_vip
            </div> */}
            <img
              loading="lazy"
              srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
              className={`mt-6 aspect-[2.13] ${isMobile ? 'w-[45px]' : 'w-[57px]'}`}
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://moso.monster/');
              }}
            />
            <div className={`flex gap-4 mt-5 text-center whitespace-nowrap font-[685] ${isMobile ? 'pr-2.5' : 'pr-5'}`}>
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png"
                className={`shrink-0 self-stretch my-auto aspect-square ${isMobile ? 'w-[20px]' : 'w-[27px]'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open('https://t.me/mrbunny_sex');
                }}
              />
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png"
                className={`shrink-0 self-stretch my-auto aspect-square ${isMobile ? 'w-[20px]' : 'w-[27px]'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open('https://twitter.com/Mr_BunnySex');}}
              />
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png"
                className={`shrink-0 self-stretch my-auto ${isMobile ? 'w-[50px] h-auto' : 'w-24 h-auto'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open('https://pornhub.com/channels/mr-bunny');
                }}
              />
            </div>
          </div>
        </div>

      </div>


      {/* <div className="flex flex-row gap-5 w-full max-w-screen-xl mx-auto max-md:flex-wrap max-md:max-w-full">

        <div className="flex flex-col w-1/3 self-start px-5 text-white leading-[150%]">
          <div className="flex gap-1.5 text-6xl whitespace-nowrap max-md:text-4xl">
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/gray-banner.png"
              className="shrink-0 border-solid object-contain stroke-[2.931px] stroke-white max-w-xs max-h-xs mx-auto"
            />
          </div>
          <div className="mt-6 text-xl leading-10 text-left" style={{ marginLeft: '6rem', whiteSpace: 'nowrap' }}>
            Bespoke Travels Exclusively Yours<br />
            高端私人訂製旅行,享受至尊服務
          </div>
        </div>


        <div className="flex flex-col w-1/3 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow px-5 max-md:mt-10 text-left mx-auto" style={{ marginLeft: '5rem' }}>
            <div className="text-2xl font-medium leading-8 text-white">
              快速連結
            </div>
            <div className="mt-7 text-sm leading-7 text-white">
              <span onClick={() => navigate('/')} className="cursor-pointer">主頁</span>
              <br />
              <span onClick={() => handleAnchorClick('bunny-girl', '兔子先生專屬女優')} className="text-pink-400 cursor-pointer">
                Bunny Girl 兔女郎
              </span>
              <br />
              <span onClick={() => handleAnchorClick('famous-girl', '日本知名女優')} className="text-pink-400 cursor-pointer">
                Famous Girl 日本知名女優
              </span>
              <br />
              <span onClick={() => handleAnchorClick('female-amateur', '日本素人')} className="text-pink-400 cursor-pointer">
                Female Amateur 日本素人
              </span>
              <br />
              <span onClick={() => navigate('/about')} className="cursor-pointer">About 關於我們</span>
              <br />
              <span onClick={(e) => {
                e.stopPropagation();
                window.open('https://t.me/mr_bunny_vip');
              }} className="cursor-pointer">Contact 聯繫我們</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col ml-5 w-1/3 max-md:ml-0 max-md:w-full text-left mx-auto">
          <div className="flex flex-col grow px-5 font-medium max-md:mt-10">
            <div className="text-2xl leading-8 text-white">聯繫我們</div>
            <div className="mt-4 text-sm text-gray-400">
              For business, please contact(商務合作):{" "}
              <span className="text-pink-400">support@mrbunny.vip</span>
            </div>
            <div className="mt-3.5 text-sm text-gray-400">
              Emergency Contact(緊急聯繫Telegram):
            </div>
            <div className="mt-1.5 text-sm text-pink-400">
              https://t.me/mr_bunny_vip
            </div>
            <img
              loading="lazy"
              srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
              className="mt-6 aspect-[2.13] w-[57px]"
            />
            <div className="flex gap-4 mt-5 text-center whitespace-nowrap font-[685] max-md:pr-5">
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png"
                className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
              />
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png"
                className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
              />
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png"
                className="shrink-0 self-stretch my-auto w-24 h-auto"
              />
            </div>
          </div>
        </div>
        
      </div> */}

<div className={`flex flex-row mt-7 w-full text-xs text-white max-w-screen-[1440px] mx-auto ${isMobile ? 'flex-wrap max-w-full justify-center gap-12 ' : 'gap-72 px-60'}`}>
      <div className={`flex w-1/2 justify-center my-auto leading-[195%] max-md:text-xs whitespace-nowrap ${isMobile ? 'text-center' : ''}`}>
        Copyright © 2023 Mr.BunnySEX Project
      </div>
      <div className={`flex justify-center w-1/2 flex-row leading-6 max-md:text-xs whitespace-nowrap ${isMobile ? 'text-center gap-0 ' : 'gap-2.5 '}`}>
        <div>Membership</div>
        <div>Terms</div>
        <div onClick={() => { window.location.replace('/privacy'); }}>Privacy Policy</div>
        <div>Terms & Services</div>
      </div>
    </div>



      {/* <div className="flex flex-row gap-72 px-60 mt-7 w-full text-xs text-white max-w-screen-[1440px] mx-auto max-md:flex-wrap max-md:max-w-full">
        <div className="flex w-1/2 justify-center my-auto leading-[195%]">
          Copyright © 2023 Mr.BunnySEX Project
        </div>
        <div className="flex justify-center w-1/2 flex-row gap-2.5 leading-6">
          <div>Membership</div>
          <div>Terms</div>
          <div onClick={() => { window.location.replace('/privacy'); }}>Privacy Policy</div>
          <div>Terms & Services</div>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
/* import { useNavigate } from 'react-router-dom';
import useAuthStore from '../authStore';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthStore();

  const handleTypeClick = (type: string) => {
    navigate(`/girlType?type=${type}`);
  };

  const handleAnchorClick = (id: string, type: string) => {
    if (isLoggedIn) {
      handleTypeClick(type);
    } else {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // 延时以确保页面已加载
    }
  };

  return (
    <div className="flex flex-col items-center max-w-[1440px] mx-auto pt-20 pb-5 bg-neutral-900">
      <div className="flex gap-5 w-full max-w-screen-xl mx-auto max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col self-start px-5 text-white leading-[150%]">
          <div className="flex gap-1.5 text-6xl whitespace-nowrap max-md:text-4xl">
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/gray-banner.png"
              className="shrink-0 border-solid object-contain stroke-[2.931px] stroke-white max-w-xs max-h-xs mx-auto"
            />
          </div>
          <div className="mt-6 text-xl leading-10 text-left" style={{ marginLeft: '6rem', whiteSpace: 'nowrap' }}>
            Bespoke Travels Exclusively Yours<br />
            高端私人訂製旅行,享受至尊服務
          </div>

        </div>
        <div className="flex-auto max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow px-5 max-md:mt-10 text-left" style={{ marginLeft: '5rem' }}>
                <div className="text-2xl font-medium leading-8 text-white">
                  快速連結
                </div>
                <div className="mt-7 text-sm leading-7 text-white">
                  <span onClick={() => navigate('/')} className="cursor-pointer">主頁</span>
                  <br />
                  <span onClick={() => handleAnchorClick('bunny-girl', '兔子先生專屬女優')} className="text-pink-400 cursor-pointer">
                    Bunny Girl 兔女郎
                  </span>
                  <br />
                  <span onClick={() => handleAnchorClick('famous-girl', '日本知名女優')} className="text-pink-400 cursor-pointer">
                    Famous Girl 日本知名女優
                  </span>
                  <br />
                  <span onClick={() => handleAnchorClick('female-amateur', '日本素人')} className="text-pink-400 cursor-pointer">
                    Female Amateur 日本素人
                  </span>
                  <br />
                  <span onClick={() => navigate('/about')} className="cursor-pointer">About 關於我們</span>
                  <br />
                  <span onClick={(e) => {
                    e.stopPropagation();
                    window.open('https://t.me/mr_bunny_vip');
                  }} className="cursor-pointer">Contact 聯繫我們</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full text-left">
              <div className="flex flex-col grow px-5 font-medium max-md:mt-10">
                <div className="text-2xl leading-8 text-white">聯繫我們</div>
                <div className="mt-4 text-sm text-gray-400">
                  For business, please contact(商務合作):{" "}
                  <span className="text-pink-400">support@mrbunny.vip</span>
                </div>
                <div className="mt-3.5 text-sm text-gray-400">
                  Emergency Contact(緊急聯繫Telegram):
                </div>
                <div className="mt-1.5 text-sm text-pink-400">
                  https://t.me/mr_bunny_vip
                </div>
                <img
                  loading="lazy"
                  srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
                  className="mt-6 aspect-[2.13] w-[57px]"
                />
                <div className="flex gap-4 mt-5 text-center whitespace-nowrap font-[685] max-md:pr-5">
                  <img
                    loading="lazy"
                    src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png"
                    className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
                  />
                  <img
                    loading="lazy"
                    src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png"
                    className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
                  />
                  <img
                    loading="lazy"
                    src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png"
                    className="shrink-0 self-stretch my-auto w-24 h-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-72 px-60 mt-7 w-full text-xs text-white max-w-screen-[1440px] mx-auto max-md:flex-wrap max-md:max-w-full">
        <div className="flex my-auto leading-[195%]">
          Copyright © 2023 Mr.BunnySEX Project
        </div>
        <div className="flex flex-row gap-2.5 leading-6">
          <div>Membership</div>
          <div>Terms</div>
          <div onClick={() => { window.location.replace('/privacy'); }}>Privacy Policy</div>
          <div>Terms & Services</div>
        </div>
      </div>
    </div>
  );
};

export default Footer; */




/* import { useNavigate } from 'react-router-dom';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleAnchorClick = (id: string) => {
    navigate('/');
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // 延时以确保页面已加载
  };

  return (
    <div className="flex flex-col items-center max-w-[1440px] mx-auto pt-20 pb-5 bg-neutral-900">
      <div className="flex gap-5 w-full max-w-screen-xl mx-auto max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col self-start px-5 text-white leading-[150%]">
          <div className="flex gap-1.5 text-6xl whitespace-nowrap max-md:text-4xl">
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/gray-banner.png"
              className="shrink-0 border-solid object-contain stroke-[2.931px] stroke-white max-w-xs max-h-xs mx-auto"
            />
          </div>
          <div className="mt-6 text-xl leading-10 text-left" style={{ marginLeft: '6rem' }}>
            Bespoke Travels Exclusively Yours<br />
            高端私人訂製旅行,享受至尊服務
          </div>
        </div>
        <div className="flex-auto max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow px-5 max-md:mt-10 text-left" style={{ marginLeft: '5rem' }}>
                <div className="text-2xl font-medium leading-8 text-white">
                  快速連結
                </div>
                <div className="mt-7 text-sm leading-7 text-white">
                  <span onClick={() => navigate('/')} className="cursor-pointer">主頁</span>
                  <br />
                  <span onClick={() => handleAnchorClick('bunny-girl')} className="text-pink-400 cursor-pointer">
                    Bunny Girl 兔女郎
                  </span>
                  <br />
                  <span onClick={() => handleAnchorClick('famous-girl')} className="text-pink-400 cursor-pointer">
                    Famous Girl 日本知名女優
                  </span>
                  <br />
                  <span onClick={() => handleAnchorClick('female-amateur')} className="text-pink-400 cursor-pointer">
                    Female Amateur 日本素人
                  </span>
                  <br />
                  <span onClick={() => navigate('/about')} className="cursor-pointer">About 關於我們</span>
                  <br />
                  <span onClick={(e) => {
                    e.stopPropagation()
                    window.open('https://t.me/mr_bunny_vip')
                  }} className="cursor-pointer">Contact 聯繫我們</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full text-left">
              <div className="flex flex-col grow px-5 font-medium max-md:mt-10">
                <div className="text-2xl leading-8 text-white">聯繫我們</div>
                <div className="mt-4 text-sm text-gray-400">
                  For business, please contact(商務合作):{" "}
                  <span className="text-pink-400">support@mrbunny.vip</span>
                </div>
                <div className="mt-3.5 text-sm text-gray-400">
                  Emergency Contact(緊急聯繫Telegram):
                </div>
                <div className="mt-1.5 text-sm text-pink-400">
                  https://t.me/mr_bunny_vip
                </div>
                <img
                  loading="lazy"
                  srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
                  className="mt-6 aspect-[2.13] w-[57px]"
                />
                <div className="flex gap-4 mt-5 text-center whitespace-nowrap font-[685] max-md:pr-5">
                  <img
                    loading="lazy"
                    src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png"
                    className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
                  />
                  <img
                    loading="lazy"
                    src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png"
                    className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
                  />

                  <img
                    loading="lazy"
                    src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png"
                    className="shrink-0 self-stretch my-auto w-24 h-auto"
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-72 px-60 mt-7 w-full text-xs text-white max-w-screen-[1440px] mx-auto max-md:flex-wrap max-md:max-w-full">
        <div className="flex my-auto leading-[195%]">
          Copyright © 2023 Mr.BunnySEX Project
        </div>
        <div className="flex flex-row gap-2.5 leading-6">
          <div>Membership</div>
          <div>Terms</div>
          <div onClick={() => { window.location.replace('/privacy') }}>Privacy Policy</div>
          <div>Terms & Services</div>
        </div>
      </div>
    </div>
  );
};

export default Footer; */

/* import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="flex flex-col items-center max-w-[1440px] mx-auto pt-20 pb-5 bg-neutral-900">
      <div className="flex gap-5 w-full max-w-screen-xl mx-auto max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col self-start px-5 text-white leading-[150%]">
          <div className="flex gap-1.5 text-6xl whitespace-nowrap max-md:text-4xl">
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/gray-banner.png"
              className="shrink-0 border-solid object-contain stroke-[2.931px] stroke-white max-w-xs max-h-xs mx-auto"
            />
          </div>
          <div className="mt-6 text-xl leading-10 text-left" style={{ marginLeft: '6rem' }}>
            Bespoke Travels Exclusively Yours<br />
            高端私人訂製旅行,享受至尊服務
          </div>
        </div>
        <div className="flex-auto max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow px-5 max-md:mt-10 text-left" style={{ marginLeft: '5rem' }}>
                <div className="text-2xl font-medium leading-8 text-white ">
                  快速連結
                </div>
                <div className="mt-7 text-sm leading-7 text-white">
                  主頁
                  <br />
                  <span className="text-pink-400">
                    Bunny Girl 兔女郎<br />
                    Famous Girl 日本知名女優
                  </span>
                  <br />
                  <span className="text-pink-400">Female Amateur 日本素人</span>
                  <br />
                  About 關於我們
                  <br />
                  Contact 聯繫我們
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full text-left">
              <div className="flex flex-col grow px-5 font-medium max-md:mt-10">
                <div className="text-2xl leading-8 text-white">聯繫我們</div>
                <div className="mt-4 text-sm text-gray-400">
                  For business, please contact(商務合作):{" "}
                  <span className="text-pink-400">support@mrbunny.vip</span>
                </div>
                <div className="mt-3.5 text-sm text-gray-400">
                  Emergency Contact(緊急聯繫Telegram):
                </div>
                <div className="mt-1.5 text-sm text-pink-400">
                  https://t.me/mr_bunny_vip
                </div>
                <img
                  loading="lazy"
                  srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
                  className="mt-6 aspect-[2.13] w-[57px]"
                />
                <div className="flex gap-4 mt-5 text-center whitespace-nowrap font-[685] max-md:pr-5">
                   <img
                   loading="lazy"
                   src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png"
                   className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
                   />
                  <img
                    loading="lazy"
                    src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png"
                    className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
                  />

                   <img
                    loading="lazy"
                    src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png"
                    className="shrink-0 self-stretch my-auto w-24 h-auto"
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-5 px-5 mt-7 w-full text-xs text-white max-w-screen-xl mx-auto max-md:flex-wrap max-md:max-w-full">
        <div className="flex-auto my-auto leading-[195%]">
          Copyright © 2023 Mr.BunnySEX Project
        </div>
        <div className="flex gap-2.5 leading-6">
          <div>Membership</div>
          <div>Terms</div>
          <div  onClick={() => { window.location.replace('/privacy') }}>Privacy Policy</div>
          <div>Terms & Services</div>
        </div>
      </div>
    </div>
  );
};

export default Footer; */