import React, { useState } from 'react';
import { Input } from 'antd';

const EmailTextBox: React.FC = () => {
  const [text, setText] = useState<string>('');

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleSend = () => {
    if (text.length <= 1000) {
      try {
        // 转义邮件内容，以免出现特殊字符导致的问题
        const encodedText = encodeURIComponent(text);
        // 使用mailto打开用户默认的邮件客户端
        window.location.href = `mailto:support@mrbunny.vip?subject=Subject&body=${encodedText}`;
      } catch (error) {
        console.log('error', error);
      }
    } else {
      console.log('Text exceeds 1000 words.');
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="relative">
        <Input.TextArea
          className="border-white bg-black text-white"
          value={text}
          onChange={handleTextChange}
          rows={4}
        />
        <div className="absolute bottom-2 right-4 text-xs text-gray-400">
          {text.length}/1000
        </div>
        <p className='absolute bottom-[-20px] right-4 text-xs text-gray-400'>（less than 1000 words）</p>
      </div>
      <div className="flex flex-col items-center">
        <p className='text-gray-600 mt-6 text-sm md:text-xl'>Click send, we will contact you in the shortest possible time, </p>
        <p className='text-gray-600 mb-6 text-sm md:text-xl'>Please check your email information in time</p>
        <a
          className="text-3xl my-4 bg-gradient-to-r from-[#F093FB] to-[#F5576C] text-white font-montserrat flex items-center justify-center py-2 px-4 rounded-full w-[300px]"
          onClick={handleSend}
          href={`mailto:support@mrbunny.vip?subject=Subject&body=${encodeURIComponent(text)}`}
        >
          Send
        </a>
        <p className='text-gray-600 mb-6'>Thanks for contacting us!</p>
      </div>
    </div >
  );
};

export default EmailTextBox;
