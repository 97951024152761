// src/components/DataTable.tsx
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Table, Space, Button, Image, Popover, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
interface User {
  id: string;
  username: string;
  description: string;
  avatar: string;
  banner: string;
}

interface DataTableProps {
  data: User[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

const DataTable: React.FC<DataTableProps> = ({ data, onDelete, onEdit }) => {
  const navigate = useNavigate()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'username',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search name"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <button onClick={() => confirm()}>Search</button>
          <button onClick={() => clearFilters()}>Reset</button>
        </div>
      ),
      onFilter: (value:any, record:any) => record?.username.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        {
          text: '日本知名女優',
          value: '日本知名女優',
        },
        {
          text: '兔子先生專屬女優',
          value: '兔子先生專屬女優',
        },
        {
          text: '日本美女素人',
          value: '日本美女素人',
        },
      ],
      onFilter: (value:any, record:any) => record.type === value,
    },
    {
      title: 'English Name',
      dataIndex: 'englishName',
      key: 'englishName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: string) => (
        <p className='w-[200px]'>{text}</p>
      ),
    },
    {
      title: 'Movie Url',
      dataIndex: 'movieUrl',
      key: 'movieUrl',
      render: (text: string) => (
        <p className='w-[100px]'>{text}</p>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (text: string) => (
        <p className='w-[100px]'>{text}</p>
      ),
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },
    {
      title: 'Cover',
      dataIndex: 'cover',
      key: 'cover',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },
    {
      title: 'Banner',
      dataIndex: 'banner',
      key: 'banner',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },
    {
      title: 'MB Banner',
      dataIndex: 'mb_banner',
      key: 'mb_banner',
      render: (text: string) => (
        <Popover content={<Image width={200} src={text} />}>
          <Image width={50} src={text} />
        </Popover>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: User) => (
        <Space size="middle">
          <Button onClick={() => onEdit(record as any)}>Edit</Button>
          <Button onClick={() => onDelete(record.id)} danger>Delete</Button>
          <Button onClick={() => {
            navigate(`/artwork/${record.id}`)
          }}>View Artwork</Button>
          {/* <CopyToClipboard text={record.avatar}>
            <Button>Copy Avatar URL</Button>
          </CopyToClipboard>
          <CopyToClipboard text={record.banner}>
            <Button>Copy Banner URL</Button>
          </CopyToClipboard> */}
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      scroll={{ x: 'max-content' }}
      pagination={{ 
        pageSizeOptions: ['10', '25', '50'],
        showSizeChanger: true,
      }} 
    />
  );
};

export default DataTable;
