import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Select } from 'antd';
import { HeartOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import UserProfileIcon from './UserProfileIcon';
import Menu from './Menu';
import SortByPopover from './SortByPopover';
import TypeFilter from './TypeFilter';
import { getAllNames } from '../api';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { contact } from '../grocery/utils';

const { Option } = Select;

const Header: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
  const [searchText, setSearchText] = useState<string>('');
  const [showClear, setShowClear] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const headerRef = useRef<HTMLDivElement | null>(null); // 引用头部元素的ref
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const navigate = useNavigate()
  useEffect(() => {
    getAllNames().then((res) => {
      setOptions(res?.data || [])
    }).catch((error) => {
      console.log(error);
    })
  }, [])
  useEffect(() => {
    const str = searchParams.get('name')
    if (str) {
      setShowClear(true)
    } else {
      setShowClear(false)
    }
  }, [searchParams])
  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    const str = searchParams.toString()
    const allParams = str && queryString.parse(str)
    setSearchParams({
      ...allParams,
      name: value
    })
  };

  const handleSearch = (value: string) => {
    setSearchText(value);

  };

  const filterOption = (input: string, option: React.ReactElement) => {
    if (option && option.props.children) {
      return option.props.children.toString().toLowerCase().includes(input.toLowerCase());
    }
    return false;
  };
  const handleClear = async (e: any) => {
    setSearchParams({})
    setSelectedOption(undefined);
  }

  const isMobile = window.innerWidth <= 1024;
  useEffect(() => {
    if (!isMobile) {
      return;
    }
    // 添加滚动事件监听器
    const handleScroll = () => {
      if (headerRef.current) {
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        // 根据滚动位置来判断是否隐藏头部
        setIsHeaderVisible(scrollY < 100);
      }
    };

    // 初始化时执行一次
    handleScroll();

    // 添加滚动事件监听器
    window.addEventListener('scroll', handleScroll);

    // 清除滚动事件监听器
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <header className={`px-2 py-4 md:py-8 md:px-16 ${isHeaderVisible ? '' : 'pt-0'}`} ref={headerRef}>
      <div className={`max-w-[90rem] mx-auto justify-between flex items-center pl-2 md:px-4 ${isHeaderVisible ? '' : 'hidden'}`}>
        <div className="flex items-center">
          <div className="mr-4 w-8">
            <img src='https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/search-header-logo.svg' className='cursor-pointer' alt="logo" onClick={() => {
              window.location.replace('/')
            }} />
          </div>
          <Menu />
          <div className='mr-2 hidden lg:block'>
            <SortByPopover />
          </div>
          {/* <div className='mx-6 hidden lg:block'>
            <TypeFilter />
          </div> */}
        {/*   <Select
            showSearch
            placeholder="Search by name"
            popupClassName="my-custom-dropdown-class" 
            className="my-selelct mr-4 w-48 md:w-[240px]"
            optionFilterProp="children"
            value={selectedOption}
            onChange={handleOptionChange}
            onSearch={handleSearch}
            filterOption={filterOption as any}
            suffixIcon={<SearchOutlined />}
          >
            {options?.map((item, index) => (
              <Option key={index} className="my-option" value={item}>{item}</Option>
            ))}
          </Select> */}
          {showClear ? <CloseOutlined className='text-sm text-white mr-2' onClick={handleClear} /> : null}
        </div>
        <div className="flex items-center ">
          <HeartOutlined
            onClick={() => {
              navigate('/mylikes')
            }}
            className="text-white-500 mr-2 cursor-pointer hover:cursor-pointer hover:text-red-700 md:mr-4" />
          <Link to="/about" className="hidden md:block mr-2 md:tracking-[2px] font-montserrat-bold text-md  text-white md:mr-4">關於我們</Link>
          <span onClick={() => { contact() }} className="cursor-pointer hidden md:block mr-2 md:tracking-[2px] font-montserrat-bold text-md  text-white md:mr-4">聯繫我們</span>
          <UserProfileIcon />
        </div>
      </div>
      <div className={`flex lg:hidden justify-between m-2`}>
        {/* <div>
          <TypeFilter />
        </div> */}
        <div>
          <SortByPopover />
        </div>
      </div>
    </header>
  );
};

export default Header;
