import React, { useState, useEffect } from 'react';

interface Image {
  url: string;
  width?: string;
}

interface Item {
  left?: string;
  title?: string;
  titleColor?: string;
  desc?: string;
  images: Image[];
}

interface Day {
  dayNum: string;
  number: string;
  title: string | string[];
  titleColor?: string;
  items: Item[];
}

interface TripProps {
  data: Day[];
}

function Trip(props: TripProps) {
  const { data } = props;
  const [isMobile, setIsMobile] = useState(false);
  const [maxWidth, setMaxWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    setMaxWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

/*   useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); */

  return (
    <React.Fragment>
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <div className={`trip-day-${index} mt-14 ml-6 max-w-full max-md:mt-10`}>
            <div className="flex gap-5 max-md:gap-0">
              <div className="flex flex-col"> 
                <div className={`flex flex-col text-black`}>
                  <div className={`text-center ${isMobile ? 'w-[60px] text-[10px]' : 'w-[80px] text-2xl'} bg-[#FF90B8] px-1`}>{item.dayNum}</div>
                  <div className={`px-1 ${isMobile ? 'w-[60px] text-[10px]' : 'w-[80px] text-2xl'} justify-center items-start text-center bg-white border-rose-300 border-solid border-[5px] max-md:px-5`}>
                    {item.number}
                  </div>
                </div>
              </div>
              <div className={`flex flex-col ${isMobile ? 'ml-5' : 'ml-10'} w-[100%] max-md:w-full`}>
                <div
                  style={{ maxWidth: `${maxWidth}px`, overflowWrap: 'break-word', color: item.titleColor || ''}}
                  className={`${Array.isArray(item.title) ? 'h-30' : 'h-20'} max-h-30 break-words items-center text-left flex-wrap flex justify-between grow text-white ${isMobile ? 'text-[15px] leading-2' : 'text-2xl leading-9'}`}
                >
                  {Array.isArray(item.title) ? item.title.map((item2, idx) => (
                    <React.Fragment key={idx}>
                      <div style={{ width: '100%' }}>{item2}</div>
                    </React.Fragment>
                  )) : item.title}
                </div>
              </div>
            </div>
          </div>
          {item.items.map((item2, index2) => (
            <React.Fragment key={index2}>
              {(item2.left || item2.title) && (
                <div className={`trip-item-top-${index}-${index2} flex gap-5 ml-5 max-w-full leading-[150%] w-[600px] max-md:flex-wrap ${isMobile ? 'mt-4' : 'mt-16'}`}>
                  <div className={`text-rose-300 items-center ${isMobile ? 'text-[10px]' : 'text-2xl'} ${isMobile ? 'w-[10%]' : 'w-[17%]'} text-[#FF90B8] text-center`}>{item2.left}</div>
                  <div className={`flex-auto  text-left text-white items-center max-md:max-w-full ${isMobile ? 'text-[15px]' : 'text-2xl'}`} style={{ maxWidth: `${maxWidth}px`, overflowWrap: 'break-word', color: item2.titleColor || ''}}>
                    {item2.title}
                  </div>
                </div>
              )}
              {item2.desc && (
                <div className={`trip-item-desc-${index}-${index2} flex ml-5 max-w-full leading-[150%] w-[1000px] max-md:flex-wrap ${isMobile ? 'gap-0 mt-6' : 'gap-5 mt-10'}`}>
                  <div className="text-2xl text-rose-300 w-[11%] text-center"></div>
                  <div /* className="text-left text-[#FF90B8] mt-5 text-base leading-6 w-[969px] max-md:text-sm max-md:leading-5" */
                  className={`text-left text-[#FF90B8] mt-5 leading-6 max-md:leading-5 ${isMobile ? 'text-[12px] px-4' : 'text-base  w-[969px]'}`} 
                  style={{ maxWidth: `${maxWidth}px`, overflowWrap: 'break-word' }}>
                    * {item2.desc}
                  </div>
                </div>
              )}
              <div className={`self-center ml-10 w-full max-w-[1050px] max-md:max-w-full ${isMobile ? 'mt-4' : 'mt-9'}`}>
                <div className="flex flex-wrap gap-5 max-md:gap-0">
                  {item2.images.map((item3, imgIndex) => (
                    <React.Fragment key={imgIndex}>
                      <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full" style={{ width: item3.width ? item3.width : '30%' }}>
                        <img
                          loading="lazy"
                          src={item3.url}
                          className="grow w-full object-cover aspect-[1.61] max-md:mt-8 max-md:max-w-full"
                          alt={`Image ${imgIndex + 1}`}
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export default Trip;

