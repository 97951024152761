import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
// import Header from '../component/Header'
// import TypingEffect from '../component/TypingEffect'
// import ImageFadeIn from '../component/ImageFadeIn';
// import BackGround from '../component/BackGround';
import useAuthStore from '../authStore';

import Banner from '../component/Banner';
import Button from '../component/Button';
import NewHeader from '../component/NewHeader';
import SideHeader from '../component/SideHeader';
import Footer from "../component/Footer";
// import sample from "../Figure/sample.png"


const images = [
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner1.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner2.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner3.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner4.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner5.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner6.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner7.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner8.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner9.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner10.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner11.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner12.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner13.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner14.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/banner15.jpg',
];

const phoneimages = [
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/phonebanner1.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/phonebanner2.jpg',
  'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/phonebanner3.jpg',
];

/* type InfoCardProps = {
  id?: string; // 添加 id 属性
  imgSrc: string;
  title: string;
  subTitle: string;
  description: string;
  alt: string;
  className?: string;
  onClick?: () => void;
};

const InfoCard: React.FC<InfoCardProps> = ({ id, imgSrc, title, subTitle, description, alt, className, onClick }) => (
  <article
    id={id} // 将 id 应用到 <article> 元素上
    className={`flex overflow-hidden relative flex-col grow px-20 pt-60 pb-6 text-3xl text-white leading-[60px] min-h-[736px] ${className}`}
    onClick={onClick} // 使用 onClick 属性
    style={{ cursor: 'pointer', height: '736px' }} // 添加鼠标指针样式并设置固定高度
  >
    <img loading="lazy" src={imgSrc} className="object-cover absolute inset-0 size-full" alt={alt} />
    <h2 className="relative self-center pt-10 font-bold ">{title}</h2>
    <p className="relative mt-4 text-center font-oldchinese">{subTitle}</p>
    <p className="relative mt-36 text-sm md:text-xs lg:text-sm xl:text-base max-md:mt-10 whitespace-nowrap">{description}</p>
  </article>
); */

const InfoCard: React.FC<{
  id: string;
  imgSrc: string;
  title: string;
  subTitle: string;
  description: string;
  alt: string;
  className?: string;
  onClick?: () => void;
}> = ({ id, imgSrc, title, subTitle, description, alt, className, onClick }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <article
      id={id}
      className={`lex overflow-hidden relative flex-col grow px-20 pb-6 text-white leading-[60px] ${isMobile ? 'pt-2' : 'pt-60 min-h-[736px]'} ${className}`}
      /* className={`flex overflow-hidden relative flex-col grow px-20 pt-60 pb-6 text-3xl text-white leading-[60px] min-h-[736px] ${className}`} */
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <img
        loading="lazy"
        src={imgSrc}
        className={`object-cover absolute inset-0 ${isMobile ? 'w-full h-full' : 'size-full'}`}
        alt={alt}
      />
      <h2 className={`relative self-center font-bold ${isMobile ? 'mt-4 text-2xl' : 'mt-10 text-3xl'}`}>{title}</h2>
      <p /* className="relative mt-4 text-center font-oldchinese" */
        className={`relative text-center font-oldchinese ${isMobile ? 'mt-4 text-2xl' : ' mt-4 text-3xl'}`}>{subTitle}</p>
      <p /* className="relative mt-36 text-sm md:text-xs lg:text-sm xl:text-base max-md:mt-10 whitespace-nowrap" */
        className={`relative text-sm md:text-xs lg:text-sm xl:text-base whitespace-nowrap" ${isMobile ? 'mt-6 mb-6' : 'text-3xl mt-72'}`}
      >{description}</p>
    </article>
  );
};


const ContactUsButton: React.FC = () => (
  <div className="flex gap-4 py-3 pr-1.5 pl-7 mt-10 bg-white rounded-none max-md:pl-5 max-md:mr-2.5">
    <span className="flex-auto my-auto text-xl font-bold leading-8 text-zinc-900">Contact us</span>
    <div className="flex justify-center items-center rounded-none">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a2f6a07-399e-4ded-9f57-7f3857db7266?apiKey=4001fa8768534eaebe631c6feeea2451&" className="bg-pink-400 rounded-full aspect-[1.2] w-[41px]" alt="Contact us" />
    </div>
  </div>
);


function Home() {
  const navigate = useNavigate()
  const { isLoggedIn } = useAuthStore();
  const [step, setStep] = useState<number>(0);
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const handleTagClick = (tag: string) => {
    navigate(`/filterExplore?tag=${tag}`);
  };

  const handleTypeClick = (type: string) => {
    navigate(`/girlType?type=${type}`);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setStep((prevStep) => (prevStep + 1) % 6);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      setStep((prevStep) => (prevStep + 1) % 6);
    }, 3000);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div className="font-oldchinese mx-auto max-w-[1440px]">
      <div
        className="Home"
        onClick={() => {
          if (isLoggedIn) {
            navigate('/explore')
            return
          }
          navigate('/login')
        }}
      ></div>

      <div className="relative">
        <div className="flex justify-center mx-auto max-md:max-w-full">
          {/* <SideHeader /> */}
          <NewHeader />
          <Banner
            className="object-cover absolute inset-0 size-full bg-cover bg-center z-0"
            images={isMobile ? phoneimages : images}
            addtionalImgSrc="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/blackshaow.png"
          />
        </div>
      </div>

      <main className="px-0 mt-0 w-full h-full max-md:max-w-full">
        <section className="flex max-md:flex-col max-md:gap-0">
          <InfoCard
            id="bunny-girl"
            imgSrc={isMobile ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/Group%2038463.jpg" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/%E5%85%94%E5%AD%90.png"}
            title="Bunny Girl"
            subTitle="兔子先生專屬女優"
            description="Mr. Bunny Exclusive Models"
            alt="Bunny Girl"
            className="w-[33%] max-md:ml-0 max-md:w-full"
            onClick={() => handleTypeClick('兔子先生專屬女優')}
          />
          <InfoCard
            id="famous-girl"
            imgSrc={isMobile ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/Mask%20group.jpg" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/%E7%9F%A5%E5%90%8D.png"}
            title="Famous Girl"
            subTitle="日本知名女优"
            description="Famous Adult actors from Japan"
            alt="Famous Girl"
            className="w-[33%] max-md:ml-0 max-md:w-full"
            onClick={() => handleTypeClick('日本知名女優')}
          />
          <InfoCard
            id="female-amateur"
            imgSrc={isMobile ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/Group%2038464.jpg" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/%E7%B4%A0%E4%BA%BA.png"}
            title="Female Amateur"
            subTitle="日本素人"
            description="Ordinary girls"
            alt="Female Amateur"
            className="w-[33%] max-md:ml-0 max-md:w-full"
            onClick={() => handleTypeClick('日本素人')}
          />
        </section>
      </main>

      {!isLoggedIn && (
        <div className="relative mx-auto max-w-[1440px] px-12">
          <img
            loading="lazy"
            src={isMobile ? "" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/bigbackgound.png"}/* "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/bigbackgound.png" */
            className="absolute top-0 right-0 object-contain"
            style={{ height: '100%' }}
          />
          <div className="relative top-2 flex flex-col items-end self-end ml-auto max-w-full w-[818px] max-md:ml-2.5">
            <div /* className="shrink-0 w-px bg-white border border-white border-solid h-[92px] max-md:ml-2.5" */
            className={`shrink-0 w-px bg-white border border-white border-solid ${isMobile ? 'h-[52px] mr-8' : 'h-[92px] max-md:ml-2.5'}`} />
            <div /* className="flex gap-3.5 self-stretch mt-10 text-5xl leading-[79.5px] max-md:flex-wrap max-md:text-4xl" */
            className={`flex gap-3.5 self-stretch leading-[79.5px] max-md:flex-wrap max-md:text-4xl ${isMobile ? 'mt-2' : 'mt-10 text-5xl '}`}>
              <div className={`font-oldchinese flex-auto text-pink-400 ${isMobile ? 'text-4xl mt-6' : 'max-md:text-4xl mt-0 '}`}>
                什麼是 <span className="text-pink-400">兔子旅行</span>
              </div>
              <div className="flex-auto text-white font-[275] max-md:max-w-full max-md:text-4xl">
                <span className="text-pink-400">Bunny Journey</span>?
              </div>
            </div>
            <div className={`font-semibold leading-9 text-pink-400 ${isMobile ? 'mr-8 text-[20px] mt-6' : 'mr-7 text-2xl max-md:mt-10 mt-14'}`}>
              Bunny Journey
            </div>
            <div className={`font-oldchinese mt-8 leading-10 text-right text-white max-md:max-w-full ${isMobile ? 'mr-10 text-[12px] break-words' : 'text-[20px]'}`}>
              我們致力於提供個性化、高端定製的深度日本文化體驗。
              <br />
              我們為您提供無微不至的關懷，滿足您的需求，讓你的旅行更加愉快！
            </div>
           {/*  <div className="mt-8 mr-6">
              <Button onClick={() => alert('Button clicked!')}>Contact us</Button>
            </div> */}
          </div>
          <div className="relative bottom-24 flex flex-col self-center mt-20 w-full max-w-[1016px] max-md:mt-10 max-md:max-w-full">
            <div className={`shrink-0 w-px bg-white border border-white border-solid ${isMobile ? 'h-[52px] mt-12 ml-4' : 'ml-3 h-[92px] max-md:ml-2.5'}`} />
            <div className={`flex gap-5 text-right text-white leading-[79.5px] max-md:flex-wrap max-md:text-4xl ${isMobile ? ' mt-2 text-[20px]' : 'mt-7 text-5xl'}`}>
              <div className={`font-oldchinese flex-auto ${isMobile ? ' mt-2 mr-4 text-[30px]' : 'max-md:text-4xl mt-7 text-5xl'}`}>我們可以做什麼?</div>
              <div /* className="flex-auto my-auto font-[275] max-md:max-w-full max-md:text-4xl" */
              className={`flex-auto my-auto font-[275] max-md:max-w-full  ${isMobile ? ' mt-2 mr-4 text-[30px]' : 'max-md:text-4xl'}`}>
                What can we do?
              </div>
            </div>
            <div className={`flex justify-start font-semibold leading-9 text-pink-400 max-md:max-w-full ml-28 ${isMobile ? ' mt-2 text-[20px]' : ' mt-6 text-2xl'}`}>
              Bunny Journey
            </div>
            <div className="max-md:max-w-full">
              <div className="flex max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[62%] max-md:ml-0 max-md:w-full">
                  <div className={`font-oldchinese flex flex-col grow leading-6 text-white whitespace-nowrap max-md:max-w-full text-left  ${isMobile ? 'mt-6 text-[15px]' : 'ml-28 max-md:mt-20 text-[20px]'}`}>
                    <div className="max-md:max-w-full">・定製豪華旅遊行程</div>
                    <div className="mt-2.5 max-md:max-w-full">・獲取有關於日本知名女優最新信息</div>
                    <div className="mt-2.5 max-md:max-w-full">・獲取有關於亞洲兔子先生女優的最新信息</div>
                    <div className="mt-2.5 max-md:max-w-full">・提供日本全程陪同服務</div>
                  </div>
                </div>
                <div className="flex flex-col w-[38%] max-md:ml-0 max-md:w-full">
                  <div className={`font-oldchinese flex flex-col grow leading-6 text-white whitespace-nowrap text-left ${isMobile ? 'mt-4 text-[15px]' : 'text-[20px] max-md:mt-10'}`}>
                    <div>・亞洲女優空降,商演,線下游玩</div>
                    <div className="mt-2.5">・日本Av現場參觀</div>
                    <div className="mt-2.5">・私人影片定製</div>
                    <div className="mt-2.5">・獲得喜愛偶像周邊...</div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={`mt-8 self-start ${isMobile ? 'self-center' : 'ml-28'}`}>
              <Button onClick={() => alert('Button clicked!')} className="font-oldchinese flex-auto my-auto">
                聯繫我們
              </Button>
            </div> */}
          </div>
        </div>
      )}


      <div className={`relative flex flex-col ${isMobile ? '' : 'py-20 px-16'} max-md:px-5`}>
        <img
          loading="lazy"
          src={isMobile ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/Mask%20group.png" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/japanplan.png"}
          alt="Banner background"
          className={`absolute inset-0 object-cover w-full h-full max-md:max-w-full`}
        />
        <div className="flex relative gap-2.5 self-start whitespace-nowrap leading-[150%] max-md:flex-wrap">
          <div className={`font-semibold flex-auto text-white ${isMobile ? 'text-2xl mt-6' : 'text-8xl mt-6'}`}>
            Japlan
          </div>
          <div className={`font-oldchinese flex-auto my-auto text-pink-400 max-md:max-w-full ${isMobile ? 'text-xl mt-8' : 'text-6xl mt-12'}`}>
            兔子先生日本旅行計畫
          </div>
        </div>
        <div className={`flex relative gap-3.5 items-start self-start font-semibold max-md:flex-wrap ${isMobile ? 'ml-2 leading-[100%] mt-2' : 'ml-28 leading-[150%] mt-6 '}`} >
          <div className={`self-start text-white ${isMobile ? 'text-2xl' : 'text-7xl'}`}>
            Plan
          </div>
          <div className={`flex-auto self-end text-pink-400 ${isMobile ? 'text-2xl' : 'text-4xl'}`}>
            Bunny Journey
          </div>
        </div>
        <div className={`font-oldchinese relative self-center text-center text-white max-md:max-w-full ${isMobile ? 'text-[10px] mt-6' : 'text-2xl mt-11'}`}>
          兔子先生深入日本旅行,私人專門定製計畫,獨家尊享
        </div>

        <div className={`relative gap-3.5 self-center rounded-none ${isMobile ? 'mt-6 mb-2 py-2 pl-0' : 'mt-20 pr-1.5 pl-7'}`}>
          <Button onClick={() => navigate('/allplan')} className="font-oldchinese flex-auto my-auto">
            查看更多
          </Button>
        </div>
      </div>





      <div className={`relative flex flex-col ${isMobile ? '' : 'py-20 px-16'} max-md:px-5`}>
        <img
          loading="lazy"
          src={isMobile ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/Mask%20group%20(1).png" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/dreamplan.png"}
          alt="Banner background"
          className={`absolute inset-0 object-cover w-full h-full max-md:max-w-full`}
        />
        <div className="flex relative gap-2.5 self-start whitespace-nowrap leading-[150%] max-md:flex-wrap">
          <div className={`font-semibold flex-auto text-white ${isMobile ? 'text-2xl mt-6' : 'text-8xl mt-6'}`}>
            Dream
          </div>
          <div className={`font-oldchinese flex-auto my-auto text-pink-400 max-md:max-w-full ${isMobile ? 'text-xl mt-8' : 'text-6xl mt-12'}`}>
            兔子先生男優圓夢計畫
          </div>
        </div>
        <div className={`flex relative gap-3.5 items-start self-start font-semibold max-md:flex-wrap ${isMobile ? 'ml-2 leading-[100%] mt-2' : 'ml-28 leading-[150%] mt-6'}`} >
          <div className={`self-start text-white ${isMobile ? 'text-2xl' : 'text-7xl'}`}>
            Plan
          </div>
          <div className={`flex-auto self-end text-pink-400 ${isMobile ? 'text-2xl' : 'text-4xl'}`}>
            Bunny Journey
          </div>
        </div>
        <div className={`font-oldchinese relative self-center text-center text-white max-md:max-w-full ${isMobile ? 'text-[10px] mt-6' : 'text-2xl mt-11'}`}>
          兔子先生男優圓夢計畫,實現你做男優的夢想,不管是刺激的劇情或者日式溫柔,泡泡浴,我們都會滿足你
        </div>

        <div className={`relative gap-3.5 self-center rounded-none ${isMobile ? 'mt-6 mb-2 py-2 pl-0' : 'mt-20 pr-1.5 pl-7'}`}>
          <Button onClick={() => navigate('/dreamplan')} className="font-oldchinese flex-auto my-auto">
            查看更多
          </Button>
        </div>
      </div>


      <div className="flex max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow items-start px-20 py-10 w-full bg-zinc-800 max-md:px-5 max-md:max-w-full">
            <div /* className="font-oldchinese text-5xl text-white leading-[79.5px] max-md:max-w-full max-md:text-4xl" */
              className={`font-oldchinese text-white max-md:max-w-full ${isMobile ? 'text-3xl leading-[40px]' : 'text-5xl leading-[79.5px]'}`}>
              兔子先生
            </div>
            <div /* className="mt-3.5 text-5xl text-pink-400 font-[275] leading-[79.5px] max-md:max-w-full max-md:text-4xl" */
              className={`mt-3.5 text-pink-400 font-[275] max-md:max-w-full ${isMobile ? 'text-2xl leading-[40px]' : 'text-5xl leading-[79.5px]'}`}>
              Mr.BunnySEX
            </div>
            <div /* className="font-oldchinese mt-4 text-2xl leading-8 text-white max-w-[506px] max-md:max-w-full text-left mx-auto px-1" */
              className={`font-oldchinese mt-4 leading-8 text-white max-w-[506px] max-md:max-w-full text-left mx-auto px-1${isMobile ? 'text-xs' : 'text-2xl'}`}>
              兔子先生,我們專注於拍攝日本女生同時我們面向華人,讓華人深切的感受日本女生的溫柔,刺激的劇情,強烈的視覺影視,我們擁有上百部成人作品,各種類型的女生,帶給你全新的體驗.
            </div>
            <div className="flex gap-5 justify-between mt-10 max-md:mt-10">
              <img
                loading="lazy"
                srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/rabbit.svg"
                //className="shrink-0 max-w-full ml-12 object-contain w-[170px]"
                className={`shrink-0 max-w-full ml-12 object-contain ${isMobile ? 'w-[100px]' : 'w-[170px]'}`}
              />
              <img
                loading="lazy"
                srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
                //className="shrink-0 max-w-full ml-6 object-contain w-[170px]"
                className={`shrink-0 max-w-full ml-6 object-contain ${isMobile ? 'w-[100px]' : 'w-[130px]'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open('https://moso.monster/');
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[50%] max-md:w-full justify-center items-center">
          <img
            loading="lazy"
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/homeFrame.jpg"
            className="w-full object-cover"
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Home;