/* import React, { useState } from 'react';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
// import { likeByIdType } from '../../../api';
import queryString from 'query-string';
import { toast } from 'react-hot-toast';
import useAuthStore from '../../../authStore';
import { Image } from 'antd';
import { throttle } from '../../../grocery/utils';

interface Media {
  id: number;
  artistsId: number;
  aid?: number;
  wid?: number;
  type?: string;
  cover: string;
  username: string;
  englishName?: string;
  totalLikes?: string;
  isMylike?: string;
  islike?: number;
}

interface MediaCardProps {
  media: Media;
  showName?: boolean;
  index: number;
  updateList?: (id: number, num: number) => {}
  setShowPreview?: (flag: boolean) => void
  setCurrentIndex?: (flag: number) => void
}

const MediaCard: React.FC<MediaCardProps> = ({ media, index, showName = true, updateList, setShowPreview, setCurrentIndex }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const [imgLoad, setImgLoad] = useState<boolean>(false)

  const { user } = useAuthStore()

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleTagClick = (tag: string) => {
    const str = searchParams.toString()
    const allParams = str && queryString.parse(str)
    setSearchParams({
      ...allParams,
      tag
    })
  }; */

  // const handleLike = async () => {
  //   if (!user?.uid) {
  //     return
  //   }
  //   const res = await likeByIdType({
  //     //aid: media?.artistsId || media?.aid,
  //     aid: media?.aid || media?.id,
  //     uid: user?.uid,
  //   })
  //   if (res.data === 'liked') {
  //     toast.success('liked');
  //     updateList?.(media?.id, 1)
  //     return
  //   }
  //   if (res.data === 'unliked') {
  //     toast.success('unliked');
  //     updateList?.(media.id, -1)
  //     return
  //   }
  //   toast.error(res.data);
  // }

/*   const navigate = useNavigate()
  const location = useLocation()

  return (
    <div className="cursor-pointer inline-block rounded-lg overflow-hidden mb-0 md:mb-4 w-full">
      <div className='relative inline-block w-full'>
        {showName ? 
          <img
            className="rounded-lg contrast-115 object-cover w-full min-w-[42vw]  md:min-w-[240px]"
            src={media?.cover}
            alt=''
            onLoad={() => setImgLoad(true)}
            onError={() => setImgLoad(false)}
            onClick={async () => {
              if (location?.pathname === '/explore') {
                navigate(`/info/${media?.artistsId}`)
                return
              }
              if (location?.pathname === '/mylikes') {
                navigate(`/info/${media?.aid}`)
                return
              }
              await setCurrentIndex?.(index)
              setShowPreview?.(true)
            }}
          /> 
          : 
          <Image
            className="rounded-lg contrast-115 object-cover w-full min-w-[42vw]  md:min-w-[240px]"
            src={media?.cover}
            alt=''
            onLoad={() => setImgLoad(true)}
            onError={() => setImgLoad(false)}
            onClick={async () => {
              await setCurrentIndex?.(index)
              setShowPreview?.(true)
            }}
          />
        }
        {imgLoad ? null : <div className='bg-[#d0c9c9] w-full pb-[58%] skeleton rounded-lg'></div>}
        {showName && (
          <div className="backdrop-blur-[2px]  md:absolute bottom-0 left-0 right-0 bg-gray-800 bg-opacity-50 text-white p-2 rounded-b-lg">
            <div className="flex justify-between">
              <div>
                <div
                  onClick={() => navigate(`/info/${media?.artistsId}`)}
                  className="text-[0.8rem] md:text-[14px] text-left font-montserrat text-white p-0 md:pl-2"
                >
                  <span>{media?.username}</span>
                  <span className='text-[0.6rem] md:text-[12px] ml-2'>{media?.englishName || ''}</span>
                </div>
                <div className="max-w-[16ch] md:max-w-[26ch] text-sm text-left font-montserrat text-white">
                  <p>
                  {media?.type?.split(',')?.map((type, index) => (
                    <span key={index} className={`text-[10px] md:text-[12px] inline pr-2 font-montserra text-gray-300`}>
                      #{type}
                    </span>
                  ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-start pr-2 justify-between absolute right-0 top-0">
          <div className='flex items-start'>
            {media?.islike || media?.isMylike ? 
              <HeartFilled
                onClick={throttle(handleLike, 300)}
                className='font-bold mt-1 text-red-700' 
              /> :
              <HeartOutlined
                onClick={throttle(handleLike, 300)}
                className='font-bold mt-1 text-red-700' 
              />
            }
            <span className="ml-2">{media?.totalLikes || 0}</span>
          </div>
        </div> 
      </div>
    </div>
  );
};

export default MediaCard; */



import React, { useState } from 'react';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { toast } from 'react-hot-toast';
import useAuthStore from '../../../authStore';
import { likeById } from '../../../api'; // 假设你有一个 API 方法来处理点赞
import { throttle } from '../../../grocery/utils';

interface MediaCover {
  id: number;
  cover: string;
  englishName?: string;
  username: string;
  type?: string;
  islike?: boolean;
  isMylike?: boolean;
  totalLikes?: number;
}

interface MediaCoverCardProps {
  media: MediaCover;
  index: number;
  showName?: boolean;
  setShowPreview?: (flag: boolean) => void;
  setCurrentIndex?: (flag: number) => void;
  updateList?: (id: number, num: number) => void;
}

const MediaCoverCard: React.FC<MediaCoverCardProps> = ({ media, index, showName = true, setShowPreview, setCurrentIndex, updateList }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);
  const { user } = useAuthStore();
  const navigate = useNavigate();

  const handleLike = async () => {
    if (!user?.uid) {
      toast.error('Please log in to like the media');
      return;
    }
    const res = await likeById({ wid: media?.id, uid: user?.uid });
    if (res.data === 'liked') {
      toast.success('Liked');
      updateList?.(media?.id, 1);
    } else if (res.data === 'unliked') {
      toast.success('Unliked');
      updateList?.(media?.id, -1);
    } else {
      toast.error(res.data);
    }
  };

  return (
    <div className="relative cursor-pointer inline-block rounded-lg overflow-hidden mb-0 md:mb-4 w-full">
      <Image
        className="rounded-lg contrast-115 object-cover w-full min-w-[42vw] md:min-w-[240px]"
        src={media?.cover}
        alt=''
        onLoad={() => setImgLoad(true)}
        onError={() => setImgLoad(false)}
        onClick={() => navigate(`/info/${media?.id}`)}
      />
      {imgLoad ? null : (
        <div>
          <div className='bg-[#d0c9c9] w-full pb-[58%] skeleton rounded-lg'></div>
        </div>
      )}
      {showName && (
        <div className="absolute bottom-0 left-0 right-0 bg-gray-800 bg-opacity-50 text-white p-2 rounded-b-lg z-10">
          <div className="flex justify-between">
            <div>
              <div
                onClick={() => navigate(`/info/${media?.id}`)}
                className="text-[0.8rem] md:text-[14px] text-left font-montserrat text-white p-0 md:pl-2"
              >
                <span>{media?.username}</span>
                {media?.englishName && (
                  <span className='text-[0.6rem] md:text-[12px] ml-2'>{media?.englishName}</span>
                )}
              </div>
              <div className="max-w-[16ch] md:max-w-[26ch] text-sm text-left font-montserrat text-white">
                <p>
                  {media?.type?.split(',')?.map((type, index) => (
                    <span key={index} className={`text-[10px] md:text-[12px] inline pr-2 font-montserra text-gray-300`}>
                      #{type}
                    </span>
                  ))}
                </p>
              </div>
            </div>
          {/*   <div className='flex items-start'>
              {media?.islike || media?.isMylike ? (
                <HeartFilled onClick={throttle(handleLike, 300)} className='font-bold mt-1 text-red-700' />
              ) : (
                <HeartOutlined onClick={throttle(handleLike, 300)} className='font-bold mt-1 text-red-700' />
              )}
              <span className="ml-2">{media?.totalLikes || 0}</span>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaCoverCard;


/* import React, { useState } from 'react';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import useAuthStore from '../../../authStore';

interface MediaCover {
  id: number;
  cover: string;
  englishName?: string;
  username: string;
  type?: string;
  islike?: boolean;
  isMylike?: boolean;
  totalLikes?: number;
}

interface MediaCoverCardProps {
  media: MediaCover;
  index: number;
  showName?: boolean;
  setShowPreview?: (flag: boolean) => void;
  setCurrentIndex?: (flag: number) => void;
}

const MediaCoverCard: React.FC<MediaCoverCardProps> = ({ media, index, showName = true, setShowPreview, setCurrentIndex }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);
  const { user } = useAuthStore();
  const navigate = useNavigate();

  const handleLike = () => {
    // 处理点赞逻辑
    console.log('Liked:', media.id);
  };

  return (
    <div className="relative cursor-pointer inline-block rounded-lg overflow-hidden mb-0 md:mb-4 w-full">
      <Image
        className="rounded-lg contrast-115 object-cover w-full min-w-[42vw] md:min-w-[240px]"
        src={media?.cover}
        alt=''
        onLoad={() => setImgLoad(true)}
        onError={() => setImgLoad(false)}
        onClick={async () => {
          await setCurrentIndex?.(index);
          setShowPreview?.(true);
        }}
      />
      {imgLoad ? null : (
        <div>
          <div className='bg-[#d0c9c9] w-full pb-[58%] skeleton rounded-lg'></div>
        </div>
      )}
      {showName && (
        <div className="absolute bottom-0 left-0 right-0 bg-gray-800 bg-opacity-50 text-white p-2 rounded-b-lg z-10">
          <div className="flex justify-between">
            <div>
              <div
                onClick={() => navigate(`/info/${media?.id}`)}
                className="text-[0.8rem] md:text-[14px] text-left font-montserrat text-white p-0 md:pl-2"
              >
                <span>{media?.username}</span>
                {media?.englishName && (
                  <span className='text-[0.6rem] md:text-[12px] ml-2'>{media?.englishName}</span>
                )}
              </div>
              <div className="max-w-[16ch] md:max-w-[26ch] text-sm text-left font-montserrat text-white">
                <p>
                  {media?.type?.split(',')?.map((type, index) => (
                    <span key={index} className={`text-[10px] md:text-[12px] inline pr-2 font-montserra text-gray-300`}>
                      #{type}
                    </span>
                  ))}
                </p>
              </div>
            </div>
            <div className='flex items-start'>
              {media?.islike || media?.isMylike ? (
                <HeartFilled onClick={handleLike} className='font-bold mt-1 text-red-700' />
              ) : (
                <HeartOutlined onClick={handleLike} className='font-bold mt-1 text-red-700' />
              )}
              <span className="ml-2">{media?.totalLikes || 0}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaCoverCard;

 */