import React, { useState, useEffect } from 'react';
import UserProfileIcon from './UserProfileIcon';
import useAuthStore from '../authStore';

const NewHeader: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { isLoggedIn } = useAuthStore();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`flex flex-col font-oldchinese ${isMobile ? 'pt-4' : 'pt-7'} backdrop-blur-[10px] bg-black bg-opacity-10 absolute top-0 w-full z-30`}>
      <div className="flex gap-5 justify-between self-center w-full  max-md:flex-wrap max-md:max-w-full">
        <div className="flex gap-5 my-auto justify-center max-md:flex-wrap max-md:max-w-full">
          <img
            loading="lazy"
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/search-header-logo.svg"
            className={`z-40  shrink-0 stroke-[2.8px] stroke-white ${isMobile ? 'w-10 h-10 ml-6' : 'w-14 h-14 ml-4'}`}
          />
          {!isMobile && (
            <div className="flex flex-auto gap-5 justify-between items-center px-5 my-auto">
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
                className="shrink-0 self-stretch aspect-[2.08] w-[66px] max-md:w-[40px]"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open('https://moso.monster/');
                }}
              />
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png"
                className="shrink-0 self-stretch my-auto w-24 aspect-[3.85] max-md:w-16"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open('https://pornhub.com/channels/mr-bunny');
                }}
              />
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png"
                className="shrink-0 self-stretch my-auto aspect-square w-[27px] max-md:w-10"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open('https://twitter.com/Mr_BunnySex');
                }}
              />
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png"
                className="shrink-0 self-stretch my-auto aspect-square w-[27px] max-md:w-10"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open('https://t.me/mrbunny_sex');
                }}
              />
            </div>
          )}
        </div>
        <div className="flex flex-grow gap-5 flex-row-reverse text-lg leading-5 whitespace-nowrap text-zinc-900">
          {!isLoggedIn && (
            <>
              <div className="flex flex-col justify-center mr-8">
                <div
                  onClick={() => {
                    window.location.replace('/login');
                  }}
                  className="justify-center text-xs px-6 py-3 bg-white rounded-[30px] max-md:px-3 max-md:py-2 max-md:text-[15px]"
                >
                  登入
                </div>
              </div>
              <div className="flex flex-col justify-center">
                <div
                  onClick={() => {
                    window.location.replace('/register');
                  }}
                  className="justify-center text-xs px-6 py-3 bg-pink-400 rounded-[30px] max-md:px-3 max-xs:text-[20px] max-md:py-2 max-md:text-[15px]"
                >
                  免费注册
                </div>
              </div>
              
            </>
          )}
           {isLoggedIn && (
            <div className="flex flex-row justify-center items-center mr-8 space-x-4">
            <div
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://linkr.bio/Mr_BunnySex');
              }}
              className={`text-white font-oldchinese leading-5 cursor-pointer ${isMobile ? 'text-[10px] px-2' : 'text-[20px] px-4'}`}
            >
              社交媒體
            </div>
            <div className="flex flex-row justify-center bg-opacity-50 bg-white rounded-[30px] p-4 max-md:p-2">
              <UserProfileIcon />
            </div>
          </div>
          
            /* <div className="flex flex-col justify-center mr-8 bg-opacity-50 bg-white rounded-[30px] p-4 max-md:p-2">
              <UserProfileIcon/>
            </div> */
          )}
        </div>
      </div>
      <div className="mt-5 border border-white border-solid " style={{ borderColor: 'rgba(128, 128, 128, 0.5)' }}/>
    </div>
  );
};

export default NewHeader;



/* import React from 'react';
import UserProfileIcon from './UserProfileIcon';
import useAuthStore from '../authStore';

const NewHeader: React.FC = () => {
  const { isLoggedIn } = useAuthStore();

  return (
    <div className="flex flex-col pt-7 backdrop-blur-[10px] bg-black bg-opacity-10 absolute top-0 w-full z-30">
      <div className="flex gap-5 justify-between self-center w-full max-w-[1383px] max-md:flex-wrap max-md:max-w-full">
        <div className="flex gap-5 my-auto max-md:flex-wrap max-md:max-w-full">
          <img
            loading="lazy"
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/search-header-logo.svg"
            className="shrink-0 stroke-[2.8px] stroke-white w-[57px] ml-4"
          />
          <div className="flex flex-auto gap-5 justify-between items-center px-5 my-auto">
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
              className="shrink-0 self-stretch aspect-[2.08] w-[66px]"
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://moso.monster/');
              }}
            />
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png"
              className="shrink-0 self-stretch my-auto w-24 aspect-[3.85]"
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://pornhub.com/channels/mr-bunny');
              }}
            />
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png"
              className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://twitter.com/Mr_BunnySex');
              }}
            />
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png"
              className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://t.me/mrbunny_sex');
              }}
            />
          </div>
        </div>
        <div className="flex gap-5 justify-between text-lg leading-5 whitespace-nowrap text-zinc-900">
          {!isLoggedIn && (
            <>
              <div className="flex flex-col justify-center">
                <div
                  onClick={() => {
                    window.location.replace('/register');
                  }}
                  className="justify-center text-xs px-9 py-4 bg-pink-400 rounded-[30px] max-md:px-5"
                >
                  免费注册
                </div>
              </div>
              <div className="flex flex-col justify-center mr-8">
                <div
                  onClick={() => {
                    window.location.replace('/login');
                  }}
                  className="justify-center text-xs px-11 py-4 bg-white rounded-[30px] max-md:px-5"
                >
                  登入
                </div>
              </div>
            </>
          )}
          {isLoggedIn && (
            <div className="flex flex-col justify-center mr-8 bg-opacity-50 bg-white rounded-[30px] p-4">
              <UserProfileIcon />
            </div>
          )}
        </div>
      </div>
      <div className="mt-6 w-full bg-white border border-white border-solid min-h-[1px] max-md:max-w-full" />
    </div>
  );
};

export default NewHeader; */

/* import React from 'react';
import UserProfileIcon from './UserProfileIcon';
import useAuthStore from '../authStore';

const NewHeader: React.FC = () => {
  const { user, isLoggedIn } = useAuthStore();
  return (
    <div className="flex flex-col pt-7 backdrop-blur-[10px] bg-black bg-opacity-10 absolute top-0 w-full z-30">
      <div className="flex gap-5 justify-between self-center w-full max-w-[1383px] max-md:flex-wrap max-md:max-w-full">
        <div className="flex gap-5 my-auto max-md:flex-wrap max-md:max-w-full">
          <img
            loading="lazy"
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/search-header-logo.svg"
            className="shrink-0 stroke-[2.8px] stroke-white w-[57px] ml-4"
          />
          <div className="flex flex-auto gap-5 justify-between items-center px-5 my-auto">
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
              className="shrink-0 self-stretch aspect-[2.08] w-[66px]"
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://moso.monster/');
              }}
            />
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png"
              className="shrink-0 self-stretch my-auto w-24 aspect-[3.85]"
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://pornhub.com/channels/mr-bunny');
              }}
            />
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png"
              className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://twitter.com/Mr_BunnySex');
              }}
            />
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png"
              className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://t.me/mrbunny_sex');
              }}
            />
          </div>
        </div>
        <div className="flex gap-5 justify-between items-center text-lg leading-5 whitespace-nowrap text-zinc-900">
          {isLoggedIn ? (
            <div className="flex items-center justify-center">
              <UserProfileIcon />
            </div>
          ) : (
            <>
              <div className="flex flex-col justify-center">
                <div onClick={() => { window.location.replace('/register') }} className="justify-center text-xs px-9 py-4 bg-pink-400 rounded-[30px] max-md:px-5">
                  免费注册
                </div>
              </div>
              <div className="flex flex-col justify-center mr-8">
                <div onClick={() => { window.location.replace('/login') }} className="justify-center text-xs px-11 py-4 bg-white rounded-[30px] max-md:px-5">
                  登入
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mt-6 w-full bg-white border border-white border-solid min-h-[1px] max-md:max-w-full" />
    </div>
  );
};

export default NewHeader; */


/* import React from 'react';
import UserProfileIcon from './UserProfileIcon';
import useAuthStore from '../authStore';

const NewHeader: React.FC = () => {
  const { user, isLoggedIn, login, logout } = useAuthStore();
  return (
    <div className="flex flex-col pt-7 backdrop-blur-[10px] bg-black bg-opacity-10 absolute top-0 w-full z-30">
      <div className="flex gap-5 justify-between self-center w-full max-w-[1383px] max-md:flex-wrap max-md:max-w-full">
        <div className="flex gap-5 my-auto max-md:flex-wrap max-md:max-w-full">
          <img
            loading="lazy"
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/search-header-logo.svg"
            className="shrink-0 stroke-[2.8px] stroke-white w-[57px] ml-4"
          />
          <div className="flex flex-auto gap-5 justify-between items-center px-5 my-auto">
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
              className="shrink-0 self-stretch aspect-[2.08] w-[66px]"
              onClick={(e) => {
                e.stopPropagation()
                window.open('https://moso.monster/')
              }}
            />
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png"
              className="shrink-0 self-stretch my-auto w-24 aspect-[3.85]"
              onClick={(e) => {
                e.stopPropagation()
                window.open('https://pornhub.com/channels/mr-bunny')
              }}
            />
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png"
              className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
              onClick={(e) => {
                e.stopPropagation()
                window.open('https://twitter.com/Mr_BunnySex')
              }}
            />
            <img
              loading="lazy"
              src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png"
              className="shrink-0 self-stretch my-auto aspect-square w-[27px]"
              onClick={(e) => {
                e.stopPropagation()
                window.open('https://t.me/mrbunny_sex')
              }}
            />
          </div>
        </div>
        <div className="flex gap-5 justify-between text-lg leading-5 whitespace-nowrap text-zinc-900">
          <div className="flex flex-col justify-center">
            <div onClick={() => { window.location.replace('/register') }} className="justify-center px-9 py-4 bg-pink-400 rounded-[30px] max-md:px-5">
              免费注册
            </div>
          </div>
          <div className="flex flex-col justify-center mr-8">
            <div onClick={() => { window.location.replace('/login') }} className="justify-center px-11 py-4 bg-white rounded-[30px] max-md:px-5">
              登入
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 w-full bg-white border border-white border-solid min-h-[1px] max-md:max-w-full" />
    </div>
  );
};

export default NewHeader;
 */