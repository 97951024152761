import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Select } from 'antd';
import { HeartOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import UserProfileIcon from './UserProfileIcon';
import Menu from './Menu';
import SortByPopover from './SortByPopover';
import TypeFilter from './TypeFilter';
import { getAllNames } from '../api';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { contact } from '../grocery/utils';

const { Option } = Select;

const Header: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
  const [searchText, setSearchText] = useState<string>('');
  const [showClear, setShowClear] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const headerRef = useRef<HTMLDivElement | null>(null); // 引用头部元素的ref
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const navigate = useNavigate()
  useEffect(() => {
    getAllNames().then((res) => {
      setOptions(res?.data || [])
    }).catch((error) => {
      console.log(error);
    })
  }, [])
  useEffect(() => {
    const str = searchParams.get('name')
    if (str) {
      setShowClear(true)
    } else {
      setShowClear(false)
    }
  }, [searchParams])
  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    const str = searchParams.toString()
    const allParams = str && queryString.parse(str)
    setSearchParams({
      ...allParams,
      name: value
    })
  };

  const handleSearch = (value: string) => {
    setSearchText(value);

  };

  const filterOption = (input: string, option: React.ReactElement) => {
    if (option && option.props.children) {
      return option.props.children.toString().toLowerCase().includes(input.toLowerCase());
    }
    return false;
  };
  const handleClear = async (e: any) => {
    setSearchParams({})
    setSelectedOption(undefined);
  }

  const isMobile = window.innerWidth <= 1024;
  useEffect(() => {
    if (!isMobile) {
      return;
    }
    // 添加滚动事件监听器
    const handleScroll = () => {
      if (headerRef.current) {
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        // 根据滚动位置来判断是否隐藏头部
        setIsHeaderVisible(scrollY < 100);
      }
    };

    // 初始化时执行一次
    handleScroll();

    // 添加滚动事件监听器
    window.addEventListener('scroll', handleScroll);

    // 清除滚动事件监听器
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
   /*  <header className={`px-2 py-4 md:py-8 md:px-16 ${isHeaderVisible ? '' : 'pt-0'}`} ref={headerRef}> */
   <header 
  className={`fixed top-0 left-0 w-full z-50 px-2 py-4 md:py-8 md:px-16 ${isHeaderVisible ? '' : 'pt-0'}`} 
  ref={headerRef}
  style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}  // 透明的黑色背景
>


      <div className={`max-w-[90rem] mx-auto justify-between flex items-center pl-2 md:px-4 ${isHeaderVisible ? '' : 'hidden'}`}>
        <div className="flex items-center">
          <div className="mr-4 w-8">
            <img src='https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/search-header-logo.svg' className='cursor-pointer' alt="logo" onClick={() => {
              window.location.replace('/')
            }} />
          </div>
          <Menu />
          {showClear ? <CloseOutlined className='text-sm text-white mr-2' onClick={handleClear} /> : null}
        </div>
        <div className="flex items-center ">
          <Link to="/about" className="hidden md:block mr-2 md:tracking-[2px] font-montserrat-bold text-md text-white md:mr-4">關於我們</Link>
          <span onClick={() => { contact() }} className="cursor-pointer hidden md:block mr-2 md:tracking-[2px] font-montserrat-bold text-md  text-white md:mr-4">聯繫我們</span>
          <UserProfileIcon />
        </div>
      </div>
      <div className={`flex lg:hidden justify-between m-2`}>
      </div>
    </header>
  );
};

export default Header;

/* import React from "react";

const PlanHeader = () => {
  const navItems = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/e913131ec5450bcaa36e8d4b14b10f8fb761af4551bb617ac7b9a394e0ce1466?apiKey=4001fa8768534eaebe631c6feeea2451&",
      alt: "Logo 1",
      className: "aspect-[2.08] w-[66px]",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/c9c6ec7ad1ebe525b35984cd359397c0ef170b22ddf38d80b9a37071d01407cd?apiKey=4001fa8768534eaebe631c6feeea2451&",
      alt: "Logo 2",
      className: "w-24 aspect-[3.85]",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0bdd5c5711fa6164d497372f91cec6fb07b6e0001c79597fe4d3aeda16af3dbb?apiKey=4001fa8768534eaebe631c6feeea2451&",
      alt: "Logo 3",
      className: "aspect-square w-[27px]",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/ae8a5d5a1bc0d7c74d4f7a52409891a23d520728b0d21c8147df85b2dde2050d?apiKey=4001fa8768534eaebe631c6feeea2451&",
      alt: "Logo 4",
      className: "aspect-square w-[27px]",
    },
  ];

  return (
    <div className="flex justify-between items-center py-4 px-8 w-full bg-black text-white">
      <div className="flex items-center gap-4">
        {navItems.map((item, index) => (
          <img key={index} src={item.src} alt={item.alt} className={item.className} />
        ))}
      </div>
      <div className="flex items-center gap-5">
        <div className="flex items-center gap-3 px-2 py-1 rounded-2xl border border-gray-200 text-neutral-400">
          <input
            type="text"
            placeholder="Search..."
            className="bg-transparent outline-none text-xs text-white placeholder-gray-400"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/488b8812d31ba534d6c8cf20053a4d7d713d07b612df7f1b967347c9d2a797f1?apiKey=4001fa8768534eaebe631c6feeea2451&"
            className="shrink-0 w-4"
            alt="Search Icon"
          />
        </div>
        <div className="flex gap-5 items-center text-base font-bold uppercase text-white">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/785d5df56c4ed4ece75cbcf6934732ae37eb343bea5f8d602d56b82b478dfb96?apiKey=4001fa8768534eaebe631c6feeea2451&"
            className="shrink-0 w-6 aspect-[1.2]"
            alt="Contact Us Icon"
          />
          <div>聯繫我們</div>
          <div>退出</div>
        </div>
      </div>
    </div>
  );
};

export default PlanHeader; */




/* import React from 'react';

const PlanHeader = () => {
  return (
    <div className="flex relative gap-5 max-md:flex-wrap">
      <div className="flex flex-auto gap-5 justify-between px-3 py-1.5 text-xs font-medium leading-4 rounded-2xl border border-gray-200 border-solid text-neutral-400 text-opacity-50">
        <div>Search....</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/488b8812d31ba534d6c8cf20053a4d7d713d07b612df7f1b967347c9d2a797f1?apiKey=4001fa8768534eaebe631c6feeea2451&"
          className="shrink-0 self-start aspect-[0.93] w-[15px]"
          alt="Search Icon"
        />
      </div>
      <div className="flex gap-5 justify-between my-auto text-base font-bold text-center uppercase text-zinc-100">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/785d5df56c4ed4ece75cbcf6934732ae37eb343bea5f8d602d56b82b478dfb96?apiKey=4001fa8768534eaebe631c6feeea2451&"
          className="shrink-0 w-6 aspect-[1.2] fill-white"
          alt="Contact Us Icon"
        />
        <div>聯繫我們</div>
        <div>退出</div>
      </div>
    </div>
  );
};

export default PlanHeader;
 */