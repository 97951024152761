import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../component/Header";
import BackGround from "../../component/BackGround";
import BunnyJourneyAnimation from "../../Animation/BunnyJourneyAnimation";
import { payForVip, getProfile } from "../../api";
import useAuthStore from "../../authStore";
import { message } from "antd";
function PayPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [payLoading, setPayLoading] = useState(false);
  const [payStatus, setPayStatus] = useState<string>('');
  const { user, isLoggedIn } = useAuthStore();

  useEffect(() => {
    const payStatusTemp = searchParams.get('payStatus') || '';
    if (user?.role === 'vip' && payStatusTemp === 'success') {
      setPayStatus('success')
      setTimeout(() => {
        window.location.replace('/explore')
      }, 2000)
      return
    }
    if (payStatusTemp === 'success') {
      setPayLoading(true)
      setTimeout(() => {
        setPayStatus('success')
        setTimeout(() => {
          window.location.replace('/explore')
        }, 2000)
      }, 10000)
    }
  }, [searchParams])

  // useEffect(() => {
  //   const timer = 

  // }, [payLoading])



  if (payStatus === 'error') {
    return (
      <div className='height-fill-available md:min-h-screen'>
        <Header />
        <BackGround />
        <div className="flex flex-col justify-center items-center">
          <div></div>
          <div className="">
            <p className="text-xl md:text-5xl  font-bold font-montserrat mb-4">Payment Error</p>
            <p className="text-sm md:text-xl  font-bold font-montserrat mb-2 text-pink-500 cursor-pointer" onClick={() => {
              navigate('/help')
            }}>Need Help?</p>
          </div>
        </div>
      </div>
    )
  }
  if (payStatus === 'success') {
    return (
      <div className='height-fill-available md:min-h-screen'>
        <Header />
        <BackGround />
        <div className="flex flex-col justify-center items-center">
          <div></div>
          <div className="">
            <p className="text-xl md:text-5xl  font-bold font-montserrat mb-4">Payment Success!</p>
            <p className="text-sm md:text-xl  font-bold font-montserrat mb-2 text-pink-500 cursor-pointer" onClick={() => {
              navigate('/explore')
            }}>Join Now</p>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='height-fill-available md:min-h-screen'>
      <Header />
      <BackGround />
      {!payLoading
        ? <div className="flex flex-col justify-center items-center">
          <p className="text-xl md:text-5xl  font-bold font-montserrat mb-4">Please complete the payment.</p>
          <p className="text-xl md:text-5xl font-bold font-montserrat mb-4 w-[74vw] md:w-[600px]"><span className="text-[#FF548B]">$9.9 </span> / Month <span className="text-xl font-montserrat text-gray-500">月</span></p>
          <p className="text-sm md:text-xl text-left w-[74vw] md:w-[600px] p-0 md:p-2">● 我們擁有全日本最大的女優與素人信息</p>
          <p className="text-sm md:text-xl text-left w-[74vw] md:w-[600px] p-0 md:p-2">● 高端旅行定製計劃</p>
          <p className="text-sm md:text-xl text-left w-[74vw] md:w-[600px] p-0 md:p-2">● 聯繫日本頂級女優</p>
          <p className="text-sm md:text-xl text-left w-[74vw] md:w-[600px] p-0 md:p-2">● 獲得全日本最新女優信息</p>
          <p className="text-sm md:text-xl text-left w-[74vw] md:w-[600px] p-0 md:p-2">● 亞洲女孩空降,商演,線下游玩</p>
          <p className="text-sm md:text-xl text-left w-[74vw] md:w-[600px] p-0 md:p-2">● 日本Av現場參觀</p>
          <p className="text-sm md:text-xl text-left w-[74vw] md:w-[600px] p-0 md:p-2">● 私人影片定製</p>
          <p className="text-sm md:text-xl text-left w-[74vw] md:w-[600px] p-0 md:p-2 mb-4">● 獲得喜愛偶像周邊...</p>
          <div>
            <p className='font-montserrat text-p text-[#aaaaaa]'>Bunny.vip has a full <a href='/membership' className="text-pink-500 hover:underline">membership</a> system</p>
            <div className="w-[100vw] md:w-[400px]">
              <button
                onClick={() => {
                  if (!isLoggedIn) {
                    navigate('/login')
                    message.warning('please login')
                  }
                  setPayLoading(true)
                  payForVip().then((res) => {
                    console.log('res', res);
                    if (res.data?.paymentUrl) {
                      // window.open(res.data?.paymentUrl)
                      window.location.replace(res.data?.paymentUrl)
                      return
                    }

                    setTimeout(() => {
                      setPayLoading(false)
                    }, 3000)
                  }).catch((error) => {
                    console.log('error', error);
                  })

                }}
                className="text-xl md:text-3xl mx-auto my-4 bg-gradient-to-r from-[#F093FB] to-[#F5576C] text-white font-montserrat flex items-center justify-center py-2 px-4 rounded-full w-[60vw] md:w-full"
              >
                Pay
              </button>
            </div>
            <p className='font-montserrat text-p text-[#aaaaaa]'>Want to know about members?<a href='/help' className="text-pink-500 hover:underline"> Need Help?</a></p>
          </div>
        </div>
        : <div className="flex flex-col justify-center items-center">
          <div></div>
          <div className="">
            <p className="text-xl md:text-5xl  font-bold font-montserrat mb-4">Complete payment</p>
            <BunnyJourneyAnimation width={200} height={200} />
            <p className="text-xl md:text-2xl  font-bold font-montserrat mb-2">Payment in progress...</p>
            <p className="text-sm md:text-xl  font-bold font-montserrat mb-6">About to embark on a new dream journey...</p>
            <p className="text-sm md:text-xl  font-bold font-montserrat mb-2 text-pink-500 cursor-pointer" onClick={() => {
              navigate('/help')
            }}>Need Help?</p>
          </div>
        </div>
      }
    </div>
  );
}

export default PayPage;
