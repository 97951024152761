import React, { useRef, useState, ChangeEvent, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getLocalStorage, setLocalStorage } from '../../grocery/utils';
import Header from '../../component/Header';
import BackGround from '../../component/BackGround';
import { verificationCode, sendResetCode, isMailExist } from '../../api/noAuthRequests';
import useAuthStore from '../../authStore';
import CustomInputField from '../../component/CustomInputField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
});

const MyComponent: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { user, isLoggedIn, login, logout } = useAuthStore();
  // const email = searchParams.get('email');
  const [verificationCodes, setVerificationCodes] = useState<string[]>(['', '', '', '', '', '']);
  const [email, setEmail] = useState<string>('');
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));
  const navigate = useNavigate()
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    setVerificationCodes((prevCodes) => {
      const newCodes = [...prevCodes];
      newCodes[index] = value;
      return newCodes;
    });
    if (value.length > 0 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };
  const sendCode = async (restemail: string) => {
    if (!restemail) {
      toast.error('Please fill the email address.')
      return
    }
    const emailFlag = await isMailExist({ email: restemail })

    if (emailFlag?.data === 'no') {
      toast.error('Email not registered')
      return
    }
    setEmail(restemail)
    try {
      const res = await sendResetCode({ email: restemail });
      if (res?.data?.includes('sent')) {
        toast.success(res?.data)
        return
      }
    } catch (error: any) {
      console.log('error', error?.response?.data || error?.message);
      toast.error(error?.response?.data || error?.message)
    }
  }
  const handleClear = async () => {
    setVerificationCodes(['', '', '', '', '', ''])
  }
  const handleVerify = async () => {
    const codes = verificationCodes.join('');
    if (!email) {
      return
    }
    try {
      const res = await verificationCode({ code: Number(codes), email })
      console.log('res', res);
      if (res?.data?.ok) {
        // 注册成功
        if (res?.data?.atoken) {
          toast.success(res?.data?.msg)
          setLocalStorage('aToken', res?.data?.atoken)
          window.location.replace('/updatepw');
          return
        }
        toast.error(res?.data?.msg)
        return
      }
      toast.error(res?.data?.msg)
    } catch (error: any) {
      // toast.error(error)
      console.log('error', error?.response?.data || error?.message);

    }
    // Add your verification logic here
    // For example, you can make an API call to verify the code
    console.log('Verifying code:', verificationCode);
  };

  const handlePaste = (event: ClipboardEvent) => {
    const clipboardContent = event.clipboardData?.getData('text/plain');
    if (clipboardContent) {
      const firstSixDigits = clipboardContent.match(/\d{6}/);
      if (firstSixDigits) {
        const digits = firstSixDigits[0].split('');
        setVerificationCodes(digits);
      }
    }
  };
  useEffect(() => {
    window.addEventListener('paste', handlePaste as EventListener);

    return () => {
      window.removeEventListener('paste', handlePaste as EventListener);
    };
  }, []);
  return <div >
    <Header />
    <BackGround />
    <div className='flex flex-col justify-center items-center w-full mt-8'>
      {email ? <><h1 className='text-xl md:text-4xl font-montserrat font-bold mb-4'>Enter 6 digit code sent to you at</h1>
        <p className='text-xl md:text-4xl font-montserrat font-bold text-[#FF548B] mb-4' >{email}</p>
        <div className='flex mb-4'>
          {verificationCodes.map((code, index) => (
            <input
              className="text-xl md:text-4xl border-b w-10 h-10 md:w-24 md:h-24 m-2 text-center border-gray-400 focus:border-blue-500 bg-transparent py-2.5 outline-none mb-4"
              key={index}
              type="text"
              value={code}
              maxLength={1}
              onChange={(e) => handleInputChange(e, index)}
              ref={(input) => {
                inputRefs.current[index] = input;
              }}
            />
          ))}
        </div>
        <div className='w-[400px]'>
          <button
            onClick={handleClear}
            className=" text-[#F5576C]"
          >
            Clear
          </button>
          <p className='mt-16'>Bunny.vip has a full
            membership
            system</p>
          <button
            onClick={handleVerify}
            className="my-2 mx-auto bg-gradient-to-r from-[#F093FB] to-[#F5576C] text-white font-montserrat flex items-center justify-center py-2 px-4 rounded-full w-[60%] md:w-full"
          >
            Verify
          </button>
          <p>Didn’t recieve a verification code?</p>
          <button className="font-bold text-[#FF548B]" onClick={() => { sendCode(email) }}>Resend Code</button>
        </div>
      </> : <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values: any, { setSubmitting }) => {
          setSubmitting(false);
          sendCode(values?.email)
        }}
      >
        {() => (
          <Form className='w-[80%] md:w-[400px] '>
            {/* <CustomInputField name="username" type="text" placeholder="Username" /> */}
            <CustomInputField name="email" type="email" placeholder="Email Address" />
            <button
              // onClick={sendCode}
              type='submit'
              className="my-4 bg-gradient-to-r from-[#F093FB] to-[#F5576C] text-white font-montserrat flex items-center justify-center py-2 px-4 rounded-full w-full"
            >
              Send Reset Code
            </button>
          </Form>
        )}
      </Formik>}
    </div>
  </div>;
};

export default MyComponent;
