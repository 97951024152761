// src/App.tsx
import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from 'antd';
import DataTable from './_components/DataTable';
import AnimalForm from './_components/AnimalForm';
import { addAnimal, getArtistss, getArtistsTag, removeArtists } from '../../api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import NavigationButtons from './_components/NavigationButtons';
function Admin() {
  const [isVisible, setIsVisible] = useState(false);
  const [animals, setAnimals] = useState([]);
  const [tags, setTags] = useState([]);
  const [initialData, setInitialData] = useState<any>({});
  const [mode, setMode] = useState<'edit' | 'create'>('create');
  const navigate = useNavigate()
  const loadData = () => {
    getArtistss().then((res) => {
      setAnimals(res?.data)
    }).catch().finally()
    getArtistsTag().then((res) => {
      setTags(res?.data)
    }).catch().finally()
  }
  const refreshData = () => {
    getArtistss().then((res) => {
      setAnimals(res?.data)
    })
  }

  useEffect(() => {
    loadData()
  }, [])
  useEffect(() => {
    if (!isVisible) {
      setInitialData({})
    }
  }, [isVisible])

  const handleSave = async (data: any) => {
    // 这里处理保存逻辑，比如发送到后端等。
    try {
      const res = await addAnimal({
        ...data,
        tags: data?.tags?.join(',')
      })
      res?.data?.msg && toast.success(res.data.msg)
      refreshData()
    } catch (error) {
      console.log('error', error);
    }

  };

  return (
    <div className="Admin bg-white min-h-[100vh] p-4">
      <div className='flex justify-center'>
        <NavigationButtons />
        <Button className='m-2' onClick={() => { setMode('create'); setIsVisible(true); }}>Create Cat</Button>
      </div>
      <div className='m-2 mb-[100px]'>
        <DataTable
          data={animals}
          onEdit={async (record: any) => {
            await setInitialData(record)
            await setMode('edit');
            setIsVisible(true);
          }}
          onDelete={(id) => {
            removeArtists({ id: id.toString() }).then((res: any) => {
              message.info(res?.data?.msg)
              loadData()
            })
          }}
        />
      </div>
      <Modal
        open={isVisible}
        title={mode === 'create' ? 'Create Form' : 'Edit Form'}
        onCancel={() => setIsVisible(false)}
        footer={null}
      >
        <AnimalForm
          mode={mode}
          tags={tags}
          initialData={initialData}
          onSave={handleSave}
        />
      </Modal>
    </div>
  );
}

export default Admin;
