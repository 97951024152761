import React, { useState } from 'react';
import { HeartOutlined, VideoCameraFilled, PlayCircleFilled, HeartFilled } from '@ant-design/icons';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { likeById } from '../../../api';
import queryString from 'query-string';
import { toast } from 'react-hot-toast';
import useAuthStore from '../../../authStore';
import { Image, Modal } from 'antd';
import { throttle } from '../../../grocery/utils';
interface Media {
  id: number;
  artistsId: number;
  aid?: number;
  wid?: number;
  type?: 'video' | 'image';
  imageUrl: string;
  videoUrl?: string;
  username: string;
  englishName?: string;
  tags?: string;
  totalLikes?: string;
  isMylike?: string;
  islike?: number;
}

interface MediaCardProps {
  media: Media;
  showName?: boolean;
  index: number;
  updateList?: (id: number, num: number) => {}
  setShowVideoPreview?: (flag: boolean) => void
  setVideo?: (flag: any) => void
  setShowPreview?: (flag: boolean) => void
  setCurrentIndex?: (flag: number) => void
}

const MediaCard: React.FC<MediaCardProps> = ({ media, index, showName = true, updateList, setShowPreview, setCurrentIndex, setVideo, setShowVideoPreview }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const [imgLoad, setImgLoad] = useState<boolean>(false)
  const urltag = searchParams.get('tag') || undefined

  const { user } = useAuthStore()

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const handleTagClick = (tag: string) => {

    const str = searchParams.toString()
    const allParams = str && queryString.parse(str)
    setSearchParams({
      ...allParams,
      tag
    })
  };
  const handleLike = async () => {
    if (!user?.uid) {
      return
    }
    const res = await likeById({
      aid: media?.artistsId || media?.aid,
      wid: media?.wid || media?.id,
      uid: user?.uid,
    })
    if (res.data === 'liked') {
      toast.success('liked');
      updateList?.(media?.id, 1)
      return
    }
    if (res.data === 'unliked') {
      toast.success('unliked');
      updateList?.(media.id, -1)
      return
    }
    toast.error(res.data);
  }
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div className="cursor-pointer inline-block rounded-lg overflow-hidden mb-0 md:mb-4 w-full">
      <div className='relative inline-block w-full'>

        {showName ? <img
          className="rounded-lg contrast-115 object-cover w-full min-w-[42vw]  md:min-w-[240px]"
          src={media?.imageUrl}
          alt=''
          onLoad={() => {
            setImgLoad(true)
          }}
          onError={() => {
            setImgLoad(false)
          }}
          onClick={async () => {
            if (location?.pathname === '/explore') {
              navigate(`/info/${media?.artistsId}`)
              return
            }
            if (location?.pathname === '/mylikes') {
              navigate(`/info/${media?.aid}`)
              return
            }
            await setCurrentIndex?.(index)
            setShowPreview?.(true)
          }}
        /> : <Image
          className="rounded-lg contrast-115 object-cover w-full min-w-[42vw]  md:min-w-[240px]"
          src={media?.imageUrl}
          alt=''
          onLoad={() => {
            setImgLoad(true)
          }}
          onError={() => {
            setImgLoad(false)
          }}
          onClick={async () => {
            await setCurrentIndex?.(index)
            setShowPreview?.(true)
          }}
        />}
        {imgLoad ? null : <div>
          <div className='bg-[#d0c9c9] w-full pb-[58%] skeleton rounded-lg'  ></div>
        </div>}
        {showName ? <div className="backdrop-blur-[2px]  md:absolute bottom-0 left-0 right-0 bg-gray-800 bg-opacity-50 text-white p-2 rounded-b-lg">
          <div className="flex justify-between">
            <div>
              {/* <div className="text-left font-montserrat tracking-[2px] font-bold  text-gray-400">NAME</div> */}
              <div
                onClick={() => {
                  navigate(`/info/${media?.artistsId}`)
                }}
                className="text-[0.8rem] md:text-[14px] text-left font-montserrat  text-white p-0 md:pl-2"
              >
                <span>{media?.username}</span>
                <span className='text-[0.6rem] md:text-[12px] ml-2'>{media?.englishName || ''}</span>
              </div>
              {/* <div className="text-left font-montserrat tracking-[2px] font-bold  text-gray-400">TAGS</div> */}
              <div className="max-w-[16ch] md:max-w-[26ch] text-sm text-left font-montserrat text-white">
                <p>
                  {media?.tags?.split(',')?.map((tag, index) => (
                    <span
                      key={index}
                      className={`text-[10px] md:text-[12px] inline pr-2 font-montserra ${urltag === tag ? 'text-[#00F0FF]' : 'text-gray-300'}`}
                      onClick={() => {
                        handleTagClick(tag);
                      }}
                    >
                      #{tag}
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>
          : <>
            {media?.videoUrl ? <div className='absolute inset-0 flex items-center justify-center'>
              <PlayCircleFilled className='text-3xl md:text-5xl' onClick={() => {
                setShowVideoPreview?.(true)
                media?.videoUrl && setVideo?.(media)
              }} />
            </div> : null}
          </>
        }
        <div className="flex items-start pr-2 justify-between absolute right-0 top-0">
          <div className='flex items-start'>
            {media?.islike || media?.isMylike ? <HeartFilled
              onClick={throttle(handleLike, 300)}
              className='font-bold mt-1 text-red-700' /> :
              <HeartOutlined
                onClick={throttle(handleLike, 300)}
                className='font-bold mt-1 text-red-700' />}
            <span className="ml-2" >{media?.totalLikes || 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaCard;
