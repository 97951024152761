import Header from '../../component/PlanHeader';
import Footer from '../../component/Footer';
import Button from '../../component/Button';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";


const AllPlan: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 设定一个阈值来检测是否为手机端
    };

    handleResize(); // 初始化检测
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigate = useNavigate()
  return (
    
    <div className="max-w-[1440px] mx-auto">
      <Header />
      <div className="flex flex-col items-center justify-center w-full max-w-screen-lg mx-auto gap-4 p-4">

        <section className="flex gap-5 self-start leading-[150%] max-md:flex-wrap">
          <h1 /* className="flex-auto text-6xl font-oldchinese text-pink-400 max-md:max-w-full max-md:text-4xl" */
          className={`flex-auto font-oldchinese text-pink-400 max-md:max-w-full ${isMobile ? 'text-[24px]' : ' text-6xl'}`}>
          兔子先生日本旅行計劃
          </h1>
          <p /* className="flex-auto self-end mt-6 text-4xl font-semibold text-white" */
          className={`flex-auto self-end font-semibold text-white ${isMobile ? 'text-2xl' : 'mt-6 text-4xl'}`}>
            Travel Plan
          </p>
        </section>

        <div className="w-full">
          <img
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/2024.4.16.png"
            alt="Image 1"
            className={`object-cover w-full h-auto max-md:w-full max-md:h-auto ${isMobile ? 'mt-4' : 'mt-12'}`}
            onClick={() => navigate('/plan1')}
          />
        </div>
        <div className="w-full">
          <img
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/2024.4.16_%E5%89%AF%E6%9C%AC.png"
            alt="Image 2"
            className={`object-cover w-full h-auto max-md:w-full max-md:h-auto ${isMobile ? 'mt-4' : 'mt-12'}`}
            onClick={() => navigate('/plan2')}
          />
        </div>
        <div className="w-full">
          <img
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/2024.4.16_%E5%89%AF%E6%9C%AC2.png"
            alt="Image 3"
            className={`object-cover w-full h-auto max-md:w-full max-md:h-auto ${isMobile ? 'mt-4' : 'mt-12'}`}
            onClick={() => navigate('/plan3')}
          />
        </div>
      </div>


      <div className={`relative flex flex-col ${isMobile ? '' : 'py-20 px-16'} max-md:px-5`}>
        <img
          loading="lazy"
          src={isMobile ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/Mask%20group%20(1).png" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/dreamplan.png"}
          alt="Banner background"
          className={`absolute inset-0 object-cover w-full h-full max-md:max-w-full`}
        />
        <div className="flex relative gap-2.5 self-start whitespace-nowrap leading-[150%] max-md:flex-wrap">
          <div className={`font-semibold flex-auto text-white ${isMobile ? 'text-2xl mt-6' : 'text-8xl mt-6'}`}>
            Dream
          </div>
          <div className={`font-oldchinese flex-auto my-auto text-pink-400 max-md:max-w-full ${isMobile ? 'text-xl mt-8' : 'text-6xl mt-12'}`}>
            兔子先生男優圓夢計畫
          </div>
        </div>
        <div className={`flex relative gap-3.5 items-start self-start font-semibold max-md:flex-wrap ${isMobile ? 'ml-2 leading-[100%] mt-2' : 'ml-28 leading-[150%] mt-6'}`} >
          <div className={`self-start text-white ${isMobile ? 'text-2xl' : 'text-7xl'}`}>
            Plan
          </div>
          <div className={`flex-auto self-end text-pink-400 ${isMobile ? 'text-2xl' : 'text-4xl'}`}>
            Bunny Journey
          </div>
        </div>
        <div className={`font-oldchinese relative self-center text-center text-white max-md:max-w-full ${isMobile ? 'text-[10px] mt-6' : 'text-2xl mt-11'}`}>
          兔子先生男優圓夢計畫,實現你做男優的夢想,不管是刺激的劇情或者日式溫柔,泡泡浴,我們都會滿足你
        </div>

        <div className={`relative gap-3.5 self-center rounded-none ${isMobile ? 'mt-6 mb-2 py-2 pl-0' : 'mt-20 pr-1.5 pl-7'}`}>
          <Button onClick={() => navigate('/dreamplan')} className="font-oldchinese flex-auto my-auto">
            聯繫我們
          </Button>
        </div>
      </div>


      <div className="flex max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow items-start px-20 py-10 w-full bg-zinc-800 max-md:px-5 max-md:max-w-full">
            <div /* className="font-oldchinese text-5xl text-white leading-[79.5px] max-md:max-w-full max-md:text-4xl" */
              className={`font-oldchinese text-white max-md:max-w-full ${isMobile ? 'text-3xl leading-[40px]' : 'text-5xl leading-[79.5px]'}`}>
              兔子先生
            </div>
            <div /* className="mt-3.5 text-5xl text-pink-400 font-[275] leading-[79.5px] max-md:max-w-full max-md:text-4xl" */
              className={`mt-3.5 text-pink-400 font-[275] max-md:max-w-full ${isMobile ? 'text-2xl leading-[40px]' : 'text-5xl leading-[79.5px]'}`}>
              Mr.BunnySEX
            </div>
            <div /* className="font-oldchinese mt-4 text-2xl leading-8 text-white max-w-[506px] max-md:max-w-full text-left mx-auto px-1" */
              className={`font-oldchinese mt-4 leading-8 text-white max-w-[506px] max-md:max-w-full text-left mx-auto px-1${isMobile ? 'text-xs' : 'text-2xl'}`}>
              兔子先生,我們專注於拍攝日本女生同時我們面向華人,讓華人深切的感受日本女生的溫柔,刺激的劇情,強烈的視覺影視,我們擁有上百部成人作品,各種類型的女生,帶給你全新的體驗.
            </div>
            <div className="flex gap-5 justify-between mt-10 max-md:mt-10">
              <img
                loading="lazy"
                srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/rabbit.svg"
                //className="shrink-0 max-w-full ml-12 object-contain w-[170px]"
                className={`shrink-0 max-w-full ml-12 object-contain ${isMobile ? 'w-[100px]' : 'w-[170px]'}`}
              />
              <img
                loading="lazy"
                srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
                //className="shrink-0 max-w-full ml-6 object-contain w-[170px]"
                className={`shrink-0 max-w-full ml-6 object-contain ${isMobile ? 'w-[100px]' : 'w-[170px]'}`}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[50%] max-md:w-full justify-center items-center">
          <img
            loading="lazy"
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/homeFrame.jpg"
            className="w-full object-cover"
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default AllPlan;

/* import React from 'react';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import Button from '../../component/Button';

type TravelPlanProps = {
  title: string;
  subtitle: string;
  features: string[];
  activities: string[];
  backgroundImage: string;
  activitiesStyle?: string;
};

const TravelPlan: React.FC<TravelPlanProps> = ({ title, subtitle, features, activities, backgroundImage, activitiesStyle }) => (
  <section
    className="flex flex-col justify-center mt-20 bg-white rounded-[30.285px] max-w-[1440px] mx-auto max-md:mt-10 max-md:max-w-full"
    style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
  >
    <div className="flex overflow-hidden relative flex-col px-9 py-5 w-full backdrop-blur-[7.271845817565918px] fill-black fill-opacity-50 min-h-[534px] max-md:px-5 max-md:max-w-full">
      <img loading="lazy" src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/rabbit.svg" className="w-40 max-w-full aspect-[6.25]" alt="Travel plan logo" />
      <div className="relative mt-12 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[55%] max-md:ml-0 max-md:w-full">
            <div className="flex relative flex-col grow max-md:mt-10 max-md:max-w-full">
              <h2 className="text-7xl text-white tracking-[9.34px] max-md:max-w-full max-md:text-4xl">{title}</h2>
              <p className="mt-5 text-xl text-rose-400 leading-[58.5px] mix-blend-plus-lighter tracking-[3.94px] max-md:max-w-full">{subtitle}</p>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[45%] max-md:ml-0 max-md:w-full">
            <div className="grid grid-cols-2 gap-5 my-auto text-sm text-white tracking-[2.28px] max-md:mt-10 max-md:max-w-full">
              {features.map((feature, index) => (
                <div key={index} className="flex justify-start items-center mt-7 first:mt-0">
                  <div className="flex overflow-hidden relative flex-col justify-start items-start px-6 py-3.5 h-full backdrop-blur-[2.549743175506592px] fill-pink-200 fill-opacity-30 w-full max-md:px-5 bg-white bg-opacity-50 rounded-lg">
                    {feature}
                  </div>
                </div>
              ))}
            </div>
          </div> 
        </div>
      </div>
      <div className="relative self-center mt-6 w-full max-w-[1040px] max-md:max-w-full">
        <div className="text-2xl text-rose-300 text-left tracking-[2.7px]">特别活动体验:</div>
        <div className={`grow text-3xl text-yellow-400 ${activitiesStyle}`}>
          {activities.map((activity, index) => (
            <p key={index} className="mt-7 leading-[103%]">
              <span className="text-white"></span>{activity}
            </p>
          ))}
        </div>
      </div>
      <div className="flex relative gap-3.5 self-end mt-6 whitespace-nowrap leading-[150%]">
        <img loading="lazy" src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/FramePlan.png" className="shrink-0 w-24 stroke-[1.738px] stroke-white" alt="Bunny Journey logo" />
      </div>
    </div>
  </section>
);

const AllPlan: React.FC = () => {
  return (
    <div className="flex flex-col bg-zinc-900 max-w-[1440px] mx-auto">
      <Header />
      <main className="flex flex-col px-20 mt-20 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <section className="flex gap-5 self-start leading-[150%] max-md:flex-wrap">
          <h1 className="flex-auto text-6xl text-pink-400 max-md:max-w-full max-md:text-4xl">
            兔子先生日本旅行计划
          </h1>
          <p className="flex-auto self-end mt-6 text-4xl font-semibold text-white">
            Travel Plan
          </p>
        </section>

        <TravelPlan
          title="日本魅惑旅行"
          subtitle="Bespoke Travels Exclusively Yours"
          features={[
            "1.上海往返东京机票",
            "2.机场接送服务",
            "3.包含3晚住宿",
            "4.深度体验日本"
          ]}
          activities={[
            "日式传统口爆店",
            "歌舞伎町日本风俗表演秀",
            "日式传统洗体店",
            "兔子先生-成人AV现场拍摄参观"
          ]}
          backgroundImage="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/meihuo.png"
          activitiesStyle="grid grid-cols-2 gap-4"
        />

        <TravelPlan
          title="日本高级旅行"
          subtitle="Bespoke Travels Exclusively Yours"
          features={[
            "1.上海往返东京机票",
            "2.机场接送服务",
            "3.包含3晚住宿",
            "4.深度体验日本"
          ]}
          activities={[
            "美女全天陪玩(8小时)",
            "日本老牌吉原泡泡浴体验",
            "成人脱衣舞表演",
            "兔子先生-成人AV现场拍摄参观",
            "俯瞰东京直升机",
            "日本美女上门空降体验"
          ]}
          backgroundImage="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/gaoji.png"
          activitiesStyle="grid grid-cols-2 gap-4"
        />

        <TravelPlan
          title="日本至尊旅行"
          subtitle="Bespoke Travels Exclusively Yours"
          features={[
            "1.上海往返东京机票",
            "2.机场接送服务",
            "3.包含3晚住宿",
            "4.深度体验日本"
          ]}
          activities={[
            "美女全天陪玩(24小时)",
            "日本老牌吉原泡泡浴体验",
            "日式和牛料理",
            "日本河豚刺生",
            "日本美女伴游富士山",
            "兔子先生-成人AV现场拍摄参观",
            "传统日式手打乌冬面套餐",
            "日式人体盛宴",
            "俯瞰东京直升机",
            "日本美女上门空降体验",
            "日本蟹道乐"
          ]}
          backgroundImage="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/zhizun.png"
          activitiesStyle="grid grid-cols-2 gap-4"
        />

        <div className="flex overflow-hidden relative flex-col px-16 py-20 min-h-[546px] max-md:px-5 mt-20">
          <img
            loading="lazy"
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/dreamplan.png"
            className="object-cover absolute inset-0 size-full bg-cover bg-center bg-no-repeat"
          />
          <div className="flex relative gap-2.5 self-start whitespace-nowrap leading-[150%] max-md:flex-wrap">
            <div className="flex-auto text-8xl font-semibold text-white max-md:text-4xl">
              Dream
            </div>
            <div className="font-oldchinese flex-auto my-auto text-6xl text-pink-400 max-md:max-w-full max-md:text-4xl">
              兔子先生男優圓夢計畫
            </div>
          </div>
          <div className="flex relative gap-3.5 items-start self-start mt-6 ml-28 font-semibold leading-[150%] max-md:flex-wrap">
            <div className="self-start text-7xl text-white max-md:text-4xl">
              Plan
            </div>
            <div className="flex-auto self-end mt-6 text-5xl text-pink-400 max-md:text-4xl">
              Bunny Journey
            </div>
          </div>
          <div className="font-oldchinese relative self-center mt-11 text-2xl text-center text-white max-md:mt-10 max-md:max-w-full">
            兔子先生男優圓夢計畫,實現你做男優的夢想,不管是刺激的劇情或者日式溫柔,泡泡浴,我們都會滿足你
          </div>
          
          <div className="relative gap-3.5 self-center py-3 pr-1.5 pl-7 mt-20 rounded-none max-md:pl-5 max-md:mt-10">
            <Button onClick={() => alert('Button clicked!')} className="flex-auto my-auto">
              Read More
            </Button>
          </div>
        </div>

        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[46%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow items-start px-20 py-20 w-full bg-zinc-800 max-md:px-5 max-md:max-w-full">
            <div className="font-oldchinese text-5xl text-white leading-[79.5px] max-md:max-w-full max-md:text-4xl">
              兔子先生
            </div>
            <div className="mt-3.5 text-5xl text-pink-400 font-[275] leading-[79.5px] max-md:max-w-full max-md:text-4xl">
              Mr.BunnySEX
            </div>
            <div className="font-oldchinese mt-4 text-2xl leading-8 text-white max-w-[506px] max-md:max-w-full text-left mx-auto px-1">
              兔子先生,我們專注於拍攝日本女生同時我們面向華人,讓華人深切的感受日本女生的溫柔,刺激的劇情,強烈的視覺影視,我們擁有上百部成人作品,各種類型的女生,帶給你全新的體驗.
            </div>
            <div className="flex gap-5 justify-between mt-20 max-md:mt-10">
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/rabbit.svg"
                className="shrink-0 max-w-full aspect-[3.45] w-[170px]"
              />
              <img
                loading="lazy"
                src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
                className="shrink-0 max-w-full aspect-[3.45] w-[170px]"
              />
            </div>
          </div>
        </div>
        
        <div className="flex flex-col ml-5 w-[54%] max-md:ml-0 max-md:w-full">
          <div className="mt-16 max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <img
                  loading="lazy"
                  src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/sample.png"
                  className="grow shrink-0 max-w-full aspect-[0.7] w-[270px] max-md:mt-4 bg-cover bg-center"
                />
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <img
                  loading="lazy"
                  src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/sample.png"
                  className="grow shrink-0 max-w-full aspect-[0.71] w-[271px] max-md:mt-4 bg-cover bg-center"
                />
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <img
                  loading="lazy"
                  src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/sample.png"
                  className="grow shrink-0 max-w-full aspect-[0.7] w-[270px] max-md:mt-4 bg-cover bg-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </main>
    </div>
  );
};

export default AllPlan;
 */
