import { Player, Controls } from '@lottiefiles/react-lottie-player';
import test from './animation_lkywscm3.json'

type BunnyJourneyAnimationProps = {
  width?: number;
  height?: number;
}

const BunnyJourneyAnimation: React.FC<BunnyJourneyAnimationProps> = ({ width = 100, height = 100 }) => {

  return (
    <div>
      <Player
        autoplay
        loop
        src={test}
        style={{ height: `${height}px`, width: `${width}px` }}
      >
        {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
      </Player>
    </div>
  );
};

export default BunnyJourneyAnimation;
