import { Modal } from 'antd';
import { useEffect, useState } from 'react';

const VideoModal = ({ setPreviewVisible, previewVisible, videoUrl }: any) => {
  const [videoAspectRatio, setVideoAspectRatio] = useState<number | null>(null);

  useEffect(() => {
    if (videoUrl) {
      const videoElement = document.createElement('video');
      videoElement.src = videoUrl;

      videoElement.addEventListener('loadedmetadata', () => {
        const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
        setVideoAspectRatio(aspectRatio);
      });
    }
  }, [videoUrl]);

  const closeModal = () => {
    setVideoAspectRatio(null);
    setPreviewVisible(false);
  };

  if (!videoUrl) {
    return null;
  }

  return (
    <Modal
      className='media-preview-modal'
      open={previewVisible}
      onCancel={closeModal}
      footer={null}
      centered={true} // 居中显示
    >
      <div className="aspect-w-16 aspect-h-9"> {/* Tailwind CSS aspect ratio utility */}
        <video controls className="mx-auto">
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
    </Modal>
  );
};

export default VideoModal;
