import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import Button from './Button';

interface BannerProps {
  images: string[];
  className?: string;
  addtionalImgSrc?: string;
}

const Banner: React.FC<BannerProps> = ({ images, className, addtionalImgSrc }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(1); // 从1开始以避免假图像
  const [transitioning, setTransitioning] = useState(true);
  const [isMobile, setIsMobile] = useState(false); // 用于检测是否为手机端
  const bannerRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleDotClick = (index: number) => {
    setCurrentImageIndex(index + 1);
    setTransitioning(true);
  };

  const handleTransitionEnd = () => {
    if (currentImageIndex === 0) {
      setTransitioning(false);
      setCurrentImageIndex(images.length);
    } else if (currentImageIndex === images.length + 1) {
      setTransitioning(false);
      setCurrentImageIndex(1);
    }
  };

  const startCarousel = () => {
    intervalRef.current = setInterval(() => {
      setTransitioning(true);
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }, 3000);
  };

  const stopCarousel = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const handleButtonClick = () => {
    window.location.href = 'https://t.me/mrbunny_sex';
  };

  useEffect(() => {
    startCarousel();
    return () => stopCarousel();
  }, [images.length]);

  useEffect(() => {
    if (!transitioning) {
      bannerRef.current!.style.transition = 'none';
      setTimeout(() => {
        bannerRef.current!.style.transition = 'transform 0.5s ease-in-out';
      }, 50);
    }
  }, [transitioning]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 设定一个阈值来检测是否为手机端
    };

    handleResize(); // 初始化检测
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopCarousel();
      } else {
        // 重新设置 currentImageIndex，以确保轮播正常工作
        setCurrentImageIndex((prevIndex) => (prevIndex === images.length ? 1 : prevIndex));
        startCarousel();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className={`banner-container relative overflow-hidden ${className}`}>
      <div
        ref={bannerRef}
        className="banner flex"
        onTransitionEnd={handleTransitionEnd}
        style={{
          transition: transitioning ? 'transform 0.5s ease-in-out' : 'none',
          transform: `translateX(-${currentImageIndex * 100}%)`
        }}
      >
        <img src={images[images.length - 1]} alt="last-clone" className="w-full h-full object-cover flex-shrink-0" />
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Slide ${index}`} className="w-full h-full object-cover flex-shrink-0" />
        ))}
        <img src={images[0]} alt="first-clone" className="w-full h-full object-cover flex-shrink-0" />
      </div>

      {!isMobile && (
        <div className="dots absolute bottom-36 left-0 right-0 z-10 flex justify-center">
          {images.map((_, index) => (
            <span
              key={index}
              className={`h-2 w-2 rounded-full mx-1 cursor-pointer ${currentImageIndex === index + 1 ? 'bg-pink-500 transform scale-125' : 'bg-gray-300'}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      )}

      {addtionalImgSrc && (
        <img
          src={addtionalImgSrc}
          alt=""
          className={`absolute z-0 ${isMobile ? 'left-1/2 transform -translate-x-1/2' : ''}`}
          style={{ bottom: '-0.5rem', width: '100%' }}
        />
      )}

      {/* 新增子容器 */}
      <div className={`flex flex-col absolute bottom-44 transform p-4 rounded-lg ${isMobile ? 'left-[55%] transform w-[60%] -translate-x-1/2' : 'left-1/3 -translate-x-1/2'}`}>
        <img
          loading="lazy"
          src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/bunnybannerlogo.png"
        />
        {!isMobile ? (''
          /*<Button className="flex-auto mx-auto ml-0" onClick={handleButtonClick}>
          聯繫我們
        </Button>*/
        ) : (
          <div className="text-center text-xs text-white whitespace-nowrap mt-4">
            高端私人訂製旅行,享受至尊服務
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
