import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './App.css';
import Home from './pages';
import Register from './pages/register';
import Login from './pages/login';
import Admin from './pages/admin';
import Data from './pages/admin/data';
import Membership from './pages/membership';
import Artwork from './pages/admin/artwork';
import Ad from './pages/admin/ad';
import Tags from './pages/admin/tags';
import FirstLoad from './pages/firstLoad';
import User from './pages/admin/user';
import Explore from './pages/explore';
import About from './pages/about';
import Help from './pages/help';
import Terms from './pages/terms';
import Info from './pages/info';
import Mylikes from './pages/mylikes';
import Pay from './pages/pay';
import Privacy from './pages/privacy';
import Usc from './pages/usc';
import Verify from './pages/verify';
import Resetpw from './pages/resetpw';
import Updatepw from './pages/updatepw';
import { Toaster } from 'react-hot-toast';
import Auth from './component/Auth';
import ContactModal from './component/ContactModal';
import useAuthStore from './authStore';
import useModalStore from "./modalStore";
import { useEffect, useState } from "react";
import { getProfile } from "./api";
import FirstLoadAnimation from "./Animation/FirstLoadAnimation";
import { RestoreRequestFilterSensitiveLog } from "@aws-sdk/client-s3";
//
import FilterExplore from './pages/filterExplore';
import Footer from "./component/Footer";
import GirlType from './pages/girlType';
import AllPlan from "./pages/allPlan";
import Plan1 from "./pages/plan1";
import Plan2 from "./pages/plan2";
import Plan3 from "./pages/plan3"
import DreamPlan from "./pages/dreamPlan";
import ScrollToTop from "./component/ScrollToTo";
import Banner from './component/Banner'; 
import background from "./Figure/adminbackground.png";
import bunnygirl from "./Figure/bunnygirl.png"
import famgirl from "./Figure/famgirl.png"
import femAmat from "./Figure/femAmat.png"
import dreamp from "./Figure/dreamplan.png"
import japanp from "./Figure/japanplan.png"
import sample from "./Figure/sample.png"
import backgound2 from "./Figure/bigbackgound.png"
import banner1 from "./Figure/1.jpg"
import banner2 from "./Figure/2.jpg"
import banner3 from "./Figure/3.jpg"
import banner4 from "./Figure/4.jpg"
import banner5 from "./Figure/5.jpg"
import banner6 from "./Figure/6.jpg"
import banner7 from "./Figure/7.jpg"
import banner8 from "./Figure/8.jpg"
import banner9 from "./Figure/9.jpg"
import banner10 from "./Figure/10.jpg"
import banner11 from "./Figure/11.jpg"
import banner12 from "./Figure/12.jpg"
import banner13 from "./Figure/13.jpg"
import banner14 from "./Figure/14.jpg"
import banner15 from "./Figure/15.jpg"

//
const publicAuth = ['guest', 'admin', 'vip', 'comomon', 'expired', 'need-verify'];
const adminAuth = ['admin'];
const vipAuth = ['admin', 'vip'];
const routes = [
  {
    path: "/",
    element: <Home />,
    allowedRoles: publicAuth,
  },
  // {
  //   path: "/",
  //   element: <FirstLoad />,
  //   allowedRoles: publicAuth,
  // },
  {
    path: "/register",
    element: <Register />,
    allowedRoles: publicAuth,
  },
  {
    path: "/verify",
    element: <Verify />,
    allowedRoles: publicAuth,
  },
  {
    path: "/resetpw",
    element: <Resetpw />,
    allowedRoles: publicAuth,
  },
  {
    path: "/updatepw",
    element: <Updatepw />,
    allowedRoles: vipAuth,
  },
  {
    path: "/login",
    element: <Login />,
    allowedRoles: publicAuth,
  },
  {
    path: "/admin",
    element: <Admin />,
    allowedRoles: adminAuth,
  },
  {
    path: "/tags",
    element: <Tags />,
    allowedRoles: adminAuth,
  },
  {
    path: "/ad",
    element: <Ad />,
    allowedRoles: adminAuth,
  },
  {
    path: "/user",
    element: <User />,
    allowedRoles: adminAuth,
  },
  {
    path: "/order",
    element: <Data />,
    allowedRoles: adminAuth,
  },
  {
    path: "/artwork/:animalId",
    element: <Artwork />,
    allowedRoles: adminAuth,
  },
  {
    path: "/info/:animalId",
    element: <Info />,
    allowedRoles: publicAuth,
  },
  {
    path: "/mylikes",
    element: <Mylikes />,
    allowedRoles: vipAuth,
  },
  {
    path: "/explore",
    element: <Explore />,
    allowedRoles: vipAuth,
  },
  {
    path: "/pay",
    element: <Pay />,
    allowedRoles: publicAuth,
  },
  {
    path: "/privacy",
    element: <Privacy />,
    allowedRoles: publicAuth,
  },
  {
    path: "/usc",
    element: <Usc />,
    allowedRoles: publicAuth,
  },
  {
    path: "/about",
    element: <About />,
    allowedRoles: publicAuth,
  },
  {
    path: "/help",
    element: <Help />,
    allowedRoles: publicAuth,
  },
  {
    path: "/terms",
    element: <Terms />,
    allowedRoles: publicAuth,
  },
  {
    path: "/membership",
    element: <Membership />,
    allowedRoles: publicAuth,
  },
  //
  {
    path: "/filterExplore",
    element: <FilterExplore />,
    allowedRoles: vipAuth,
  },

  {
    path: "/girlType",
    element: <GirlType />,
    allowedRoles: publicAuth,
  },
  {
    path: "/allPlan",
    element: <AllPlan />,
    allowedRoles: publicAuth,
  },
  {
    path: "/plan1",
    element: <Plan1 />,
    allowedRoles: publicAuth,
  },
  {
    path: "/plan2",
    element: <Plan2 />,
    allowedRoles: publicAuth,
  },
  {
    path: "/plan3",
    element: <Plan3 />,
    allowedRoles: publicAuth,
  },
  {
    path: "/dreamplan",
    element: <DreamPlan />,
    allowedRoles: publicAuth,
  },
  //
]


// let allLoading = true;
// window.onload = () => {
//   console.log('onload');
//   setTimeout(() => { allLoading = false }, 1000)
// }

//读取banner需要展出的图片2024/05/10
const images = [banner1,banner2,banner3,banner4,banner5,banner6,banner7,banner8,banner9,banner10,banner11, banner12,banner13,banner14,banner15];
//


function App() {
  const { user, isLoggedIn, login, logout } = useAuthStore();
  const { isOpen } = useModalStore();
  const [appLoading, setApploading] = useState(true);
  const [allLoading, setAllLoading] = useState(true);

  useEffect(() => {

    const aToken = localStorage.getItem('aToken')
    if (!aToken) {
      setApploading(false)
    }
    // 获取用户信息
    if (!isLoggedIn) {
      getProfile().then((res) => {
        if (res?.data) {
          const { role, uid } = res.data || {}
          login({ role, uid })
        }
      }).catch(() => {

      }).finally(() => {
        setApploading(false)
      })
    } else {
      setApploading(false)
    }
    setTimeout(() => {
      setAllLoading(false)
    }, 2000)
  }, [])
  if (appLoading || allLoading) {
    return <div style={{ transition: 'all 1s' }} className="w-[100vw] h-[100vh] flex flex-col justify-center items-center">
      <FirstLoadAnimation width={300} height={300} />
    </div>
  }
  return (
    
    <div className={`App font-montserrat md:min-h-screen relative`}>
      
      <BrowserRouter>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.allowedRoles ? (
                  <Auth
                    allowedRoles={route.allowedRoles}
                  >
                    {route.element}
                  </Auth>
                ) : (
                  route.element
                )
              }
            />
          ))}
          
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <ScrollToTop />
      </BrowserRouter>

      <div className="web-footer footer-des backdrop-blur-[4px]">
        {/*
        <div className="nav-list  md:text-[12px] text-[8px]">
          <div onClick={() => { window.location.replace('/about') }} className="nav-item  md:mr-[40px] mr-4">About us</div>
          <div onClick={() => { window.location.replace('/terms') }} className="nav-item  md:mr-[40px] mr-4">Terms & Conditions</div>
          <div onClick={() => { window.location.replace('/usc') }} className="nav-item  md:mr-[40px] mr-4">18 USC 2257</div>
          <div onClick={() => { window.location.replace('/privacy') }} className="nav-item  md:mr-[40px] mr-4">Privacy</div>
          <div onClick={() => { window.location.replace('/help') }} className="nav-item  md:mr-[40px] mr-4" style={{ marginRight: 0 }}>Contact Us</div>
        </div>*/}
        
  
       {/*
        <p className="footer-pay md:text-[12px] text-[6px]">
          <img style={{ width: 30, height: 14 }} src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/MasterCard.png" alt="mc" />
          <img style={{ width: 30, height: 10 }} src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/visa-logo.png" alt="visa" />
          <span>For business, please contact:</span>
          <span className="text-[#FF548B] text-left ml-1">support@mrbunny.vip</span>
        </p>
        */}


        {/*<p className="md:text-[12px] text-[6px] ">Hungereye Technology Ltd-Location: 77, Ave., Strovolos Center, Off. 204, 2018， Nicosia, Cyprus</p>*/}
        </div>
      {/* <Footer /> */}
      <Toaster />
      {isOpen ? <ContactModal /> : null}
    </div >
  );
}

export default App;
