import { Player } from '@lottiefiles/react-lottie-player';
import test from './animation_ln1mpntq.json'

type FirstLoadAnimationProps = {
  width?: number;
  height?: number;
}

const FirstLoadAnimation: React.FC<FirstLoadAnimationProps> = ({ width = 100, height = 100 }) => {

  return (
    <div>
      <Player
        autoplay
        loop
        src={test}
        style={{ height: `${height}px`, width: `${width}px` }}
      >
      </Player>
    </div>
  );
};

export default FirstLoadAnimation;
