import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { MenuOutlined } from "@ant-design/icons";
import useAuthStore from '../authStore';
import { contact } from '../grocery/utils';
const Menu: React.FC = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [placement, setPlacement] = useState<any>('left');
  const { logout } = useAuthStore();
  const navigate = useNavigate();
  const showDrawer = () => {
    setIsDrawerVisible(true);
    // setPlacement()
    if (window.innerWidth < 750) {
      setPlacement('bottom')
      return
    }
    setPlacement('left')
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('aToken');
    logout()
    navigate('/')
  };

  return (
    <div className="flex items-center">
      <MenuOutlined onClick={showDrawer} className='mr-4' />
      <Drawer
        title={null}
        headerStyle={{
          background: 'black',
          color: 'white'
        }}
        className='my-drawer w-[50%] md:w-[400px]'
        placement={placement}
        onClose={closeDrawer}
        open={isDrawerVisible}
        bodyStyle={{ backgroundColor: 'black', color: 'white' }}
      >
        <div>
          <div className="flex items-center">
            <img src='https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/banner.png' alt="Logo" className="w-[80%]" />
          </div>
          <ul className="list-none p-4">
            <li className="m-4 font-montserrat text-2xl">
              <Link to="/explore">Home</Link>
            </li>
            <li className="m-4 font-montserrat text-2xl">
              <Link to="/about">About Us</Link>
            </li>
            <li className="cursor-pointer m-4 font-montserrat text-2xl" onClick={() => { contact() }}>
              Chat With Us
            </li>
            <li className="m-4 font-montserrat text-2xl">
              <Link to="#" onClick={handleLogout}>Logout</Link>
            </li>
            {/* Add more menu items */}
          </ul>
        </div>
      </Drawer>
    </div>
  );
};

export default Menu;
