import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import MediaCard from '../pages/explore/_component/MediaCard';
import FilterMediaCard from '../pages/filterExplore/_component/FilterMediaCard'; // 如果你使用这个组件
import { updateLikeList } from '../api/like';
import MediaPreview from './MediaPreview';
import VideoModal from './VideoModal';
import BunnyJourneyAnimation from '../Animation/BunnyJourneyAnimation';
import { Masonry } from 'react-masonry-component2';
import { throttle, contact } from '../grocery/utils';
import { Image } from 'antd';

interface MediaListProps {
  setMediaList: any,
  mediaList: any,
  fetchMoreData: any,
  loading?: boolean,
  showName?: boolean,
  info?: any,
}

const FilterMediaList: React.FC<MediaListProps> = ({ setMediaList, mediaList, fetchMoreData, loading = false, showName = true, info }) => {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [video, setVideo] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    console.log('FilterMediaList mounted');
    console.log('Initial mediaList:', mediaList);

    return () => {
      console.log('FilterMediaList unmounted');
    };
  }, []);

  useEffect(() => {
    console.log('mediaList updated:', mediaList);
  }, [mediaList]);


  if (mediaList?.length === 0 && (!loading)) {
    return <div className='mt-16'>
      No Data
    </div>
  }

  return (
    <div className='media-list'>
      <InfiniteScroll
        dataLength={mediaList.length}
        next={throttle(fetchMoreData, 300)}
        hasMore={true}
        loader={loading ? <BunnyJourneyAnimation /> : null}
        className='mx-4 my-0 md:mx-auto lg:mx-auto flex flex-col justify-center items-center md:max-w-[1200px] lg:max-w-[1440px]'
      >
        <Image.PreviewGroup
          preview={{
            onVisibleChange: (value) => {
              console.log('Preview visibility changed:', value);
              setShowPreview(value)
            },
            imageRender: (originalNode: React.ReactNode, info2: any) => {
              return <div className='absolute inset-0 flex flex-col justify-center items-center'>
                <p className='text-white font-montserrat my-2  text-xl md:text-3xl text-center w-full'>
                  {info?.username}
                  <span className='text-sm md:text-xl ml-2 mr-4 md:mr-16'> {info?.englishName}</span>
                </p>
                {originalNode}
                <div className='relative flex items-center my-2 '>
                  <div onClick={() => { contact() }} className="text-[#FE5D75] mt-2 bg-white rounded-full p-2 md:px-4 md:py-2 text-xl md:text-2xl font-montserrat-extra-bold font-extrabold cursor-pointer">CONTACT US</div>
                </div>
              </div>
            },
            toolbarRender: (originalNode: React.ReactNode, info: any) => {
              return <>
                {originalNode}
              </>
            }
          }}
        >
          {showName && (mediaList?.length > 100000)
            ? <div className="columns-2 gap-3 md:gap-6 md:columns-3 md:mx-4  lg:columns-4 xl:columns-5 md:max-w-[1200px] lg:max-w-[1440px]">
              {mediaList.map((media: any, index: number) => (
                <FilterMediaCard
                  index={index}
                  setCurrentIndex={setCurrentIndex}
                  key={"media" + media?.id}
                  media={media}
                  showName={showName}
                  setVideo={setVideo}
                  setShowVideoPreview={setShowVideoPreview}
                  updateList={(id, num): any => {
                    const newList = updateLikeList(mediaList, id, num)
                    setMediaList(newList)
                  }}
                  setShowPreview={(flag: boolean) => {
                    setShowPreview?.(flag)
                  }}
                />
              ))}
            </div>
            : <Masonry
              direction='row'
              columnsCountBreakPoints={{
                1400: 5,
                1000: 4,
                700: 2,
              }}
            >
              {mediaList.map((media: any, index: number) => (
                <FilterMediaCard
                  index={index}
                  setCurrentIndex={setCurrentIndex}
                  key={'media' + media?.id}
                  media={media}
                  showName={showName}
                  setVideo={setVideo}
                  setShowVideoPreview={setShowVideoPreview}
                  updateList={(id, num): any => {
                    const newList = updateLikeList(mediaList, id, num)
                    setMediaList(newList)
                  }}
                  setShowPreview={(flag: boolean) => {
                    setShowPreview?.(flag)
                  }}
                />
              ))}
            </Masonry>
          }
        </Image.PreviewGroup>
      </InfiniteScroll>
      {showVideoPreview && video?.videoUrl ? <VideoModal
        setPreviewVisible={setShowVideoPreview}
        previewVisible={showVideoPreview}
        videoUrl={video.videoUrl}
      /> : null}
    </div>
  );
};

export default FilterMediaList;
