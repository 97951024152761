import React, { useState, useEffect } from 'react';
import MediaList from '../../component/MediaList';
import SearchHeader from '../../component/SearchHeader';
import { getMyFavorite } from '../../api';
import useAuthStore from '../../authStore';
import { useSearchParams } from 'react-router-dom';
import { typeMap } from '../explore';

const Mylikes: React.FC = () => {
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [tagFilter, setTagFilter] = useState<string | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthStore()
  const fetchMoreData = () => {

  };

  useEffect(() => {
    if (!user?.uid) {
      return
    }
    if (!user?.uid) {
      return
    }
    // const sort = searchParams.get('sort') || 'rand'
    const type = searchParams.get('type') || 'actress'
    const tag = searchParams.get('tag') || undefined
    const name = searchParams.get('name') || undefined
    setTagFilter?.(tag)
    getMyFavorite({ uid: user?.uid, type: typeMap?.[type], tag, name }).then((res: any) => {
      const list = res?.data.map((d: any) => ({
        ...d,
        islike: true,
      }))
      setMediaList(list)
    })
  }, [searchParams])

  return (
    <div className='expolre'>
      <div className='fixed left-0 top-0 w-full z-10 bg-[rgba(0,0,0,0.7)]'>
        <SearchHeader />
      </div>
      <p className="text-xl lg:text-5xl text-[#FF548B] pt-28 lg:pt-32 pb-8 font-montserrat-extra-bold">My favorite</p>
      {tagFilter ? <div className=''>
        <p className='mx-auto text-left text-xl md:text-4xl text-white text-bold font-montserrat pb-4 max-w-[90rem] px-4'>
          tagged
          <span>「
            <span className='text-[#00F0FF]'>#{tagFilter}</span>
            」
          </span>
          ({mediaList?.length || 0} results)
          <span className='text-[#c4eb25] cursor-pointer ml-4' onClick={() => {
            setTagFilter?.('')
            setSearchParams()
          }}>X</span>
        </p>
      </div> : null}
      <MediaList mediaList={mediaList} setMediaList={setMediaList} fetchMoreData={fetchMoreData} />
    </div >
  );
};

export default Mylikes;
