export const tripData = [
  {
    number: '01',
    dayNum: 'Day-1',
    title: '「上海出发」抵达东京',
    items: [
      {
        left: 'Day1',
        title: '抵达东京机场,阿尔法/海狮专车接机',
        desc: '',
        images: []
      },
      {
        left: '★',
        title: '享受日本川崎傳統口爆风俗店(粉紅沙龍)',
        desc: `來自日本老牌風俗店品牌,在日本風俗事業最傳統的川崎地区!超多人喜爱★從「日本女學生到日本熟婦人妻」應有盡有,日本女生在籍人数NO1,可以体验所有的梦想～在独立的小小隔斷中,四周縈繞著此起彼伏的動人的蕭聲,屬於您和日本女郎的獨處时间，她舌尖在您全身游走，靈活的小舌頭輕輕滑過您的乳頭用嘴帮您洗滌小弟弟，最后將您的精華全部释放在日本女郎口中`,
        images: [
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day1_1.png', width: '35%' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day1_2.png', width: '20%' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day1_3.png', width: '35%' }
        ]
      },
      {
        left: '★',
        title: '酒店休息(第一天行程結束)',
        desc: '',
        images: []
      }
    ]
  },
  {
    number: '02',
    dayNum: 'Day-2',
    title: '歌舞伎町觀看日本风俗表演秀&日本风俗洗体按摩店',
    items: [
      {
        left: '★',
        title: '歌舞伎町日本风俗表演秀(3小时)',
        desc: `体验东京夜生活的巅峰，尽在新宿歌舞伎町的脱衣舞秀！一场大秀分为6个小场次,每场30约分钟,由6位佳人倾情演出，专业的表演团队、华丽的舞台设计和多样化的艺术形式，在音乐伴随下舞动身躯,随着女舞者的衣服一件件退去,呈现出最火热的女性曼妙身体曲线为您呈现一场视觉和听觉的双重盛宴。在这里，感受激情四溢的夜晚，与表演者近距离互动，释放您的热情，享受不一样的夜生活体验。歌舞伎町的脱衣舞秀，让每个夜晚都精彩纷呈，不容错过`,
        images: [
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day2.1.1.png', width: '' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day2.1.2.png', width: '' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day2.1.3.png', width: '' }
        ]
      },
      {
        left: '★',
        title: '日本风俗洗体按摩店',
        desc: `体验极致放松与享受，那一定是日本的传统风俗「洗体按摩店」！来自日本超级专业的女技师提供温柔细致的服务,结合传统按摩与全身清洁，让您在舒适的环境中彻底放松身心。每一个细节都精心设计，带给您前所未有的清新与愉悦。是来日本必体验的日本洗体文化按摩店，感受前所未有的极致呵护与放松体验！`,
        images: [
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day2.2.1.png', width: '' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day2.2.2.png', width: '' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day2.2.3.png', width: '' }
        ]
      },
      {
        left: '★',
        title: '酒店休息(第二天行程結束)',
        desc: '',
        images: []
      }
    ]
  },
  {
    number: '03',
    dayNum: 'Day-3',
    title: '兔子先生-成人AV现场拍摄参观',
    items: [
      {
        left: '',
        title: '',
        desc: '在片场可以看到我们免子先生的AV拍摄现场幕后,了解平时在电脑前展示的片子是如何拍摄的,一部AV影片是如何完成出品,现场观看来自日本专业的男女主角你眼前完成全套做爱,拍摄现场可能不只是1v1,可能会是1v2,甚至更多,这个会根据当天的拍摄计划进行制定,拍摄结束与女生拍照留念。但是请注意,拍摄期间不可打扰正常拍摄活动,当然如果你够胆可以上前体验一把汁男的感受.',
        images: [
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day3.1.png', width: '' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day3.2.png', width: '' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day3.3.png', width: '' }
        ]
      }
    ]
  },
  {
    number: '04',
    dayNum: 'Day-4',
    title: '东京-上海',
    items: [
      {
        left: '',
        title: '',
        desc: ' 行程结束,在指定时间集合,前往东京机场,返回上海.',
        images: []
      }
    ]
  }
];

export const tripData2 = [
  {
    number: '01',
    dayNum: 'Day-1',
    title: '「上海出发」抵达东京',
    items: [
      {
        left: 'Day1',
        title: '8小時日本美女全程陪玩',
        desc: '',
        images: []
      },
      {
        left: '★',
        title: '美女接機,車上口爆服務',
        desc: `美女在東京機場接機,客人上車後可以在車裡對日本美女為所欲為,口交或者在空間可以使展開的情況下,進行車震等任何行為`,
        images: [
          { url:"https://cdn.builder.io/api/v1/image/assets/TEMP/a6b6402936d1e43a4493bea9d756ab65cf4bf8de008d9d95e38476f28bdc3899?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6b6402936d1e43a4493bea9d756ab65cf4bf8de008d9d95e38476f28bdc3899?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6b6402936d1e43a4493bea9d756ab65cf4bf8de008d9d95e38476f28bdc3899?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6b6402936d1e43a4493bea9d756ab65cf4bf8de008d9d95e38476f28bdc3899?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6b6402936d1e43a4493bea9d756ab65cf4bf8de008d9d95e38476f28bdc3899?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6b6402936d1e43a4493bea9d756ab65cf4bf8de008d9d95e38476f28bdc3899?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6b6402936d1e43a4493bea9d756ab65cf4bf8de008d9d95e38476f28bdc3899?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6b6402936d1e43a4493bea9d756ab65cf4bf8de008d9d95e38476f28bdc3899?apiKey=4001fa8768534eaebe631c6feeea2451&",
            width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/e42eaf8f5ac5db4752b8a0e509d79fa9e77bee313cffcbdaa3896925d1bbdad6?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e42eaf8f5ac5db4752b8a0e509d79fa9e77bee313cffcbdaa3896925d1bbdad6?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e42eaf8f5ac5db4752b8a0e509d79fa9e77bee313cffcbdaa3896925d1bbdad6?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e42eaf8f5ac5db4752b8a0e509d79fa9e77bee313cffcbdaa3896925d1bbdad6?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e42eaf8f5ac5db4752b8a0e509d79fa9e77bee313cffcbdaa3896925d1bbdad6?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e42eaf8f5ac5db4752b8a0e509d79fa9e77bee313cffcbdaa3896925d1bbdad6?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e42eaf8f5ac5db4752b8a0e509d79fa9e77bee313cffcbdaa3896925d1bbdad6?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e42eaf8f5ac5db4752b8a0e509d79fa9e77bee313cffcbdaa3896925d1bbdad6?apiKey=4001fa8768534eaebe631c6feeea2451&",
            width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/5bb4c564c74154b191e8112984cdfe7d04006fce4a8f7911cc7dee145e6014e3?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bb4c564c74154b191e8112984cdfe7d04006fce4a8f7911cc7dee145e6014e3?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bb4c564c74154b191e8112984cdfe7d04006fce4a8f7911cc7dee145e6014e3?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bb4c564c74154b191e8112984cdfe7d04006fce4a8f7911cc7dee145e6014e3?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bb4c564c74154b191e8112984cdfe7d04006fce4a8f7911cc7dee145e6014e3?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bb4c564c74154b191e8112984cdfe7d04006fce4a8f7911cc7dee145e6014e3?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bb4c564c74154b191e8112984cdfe7d04006fce4a8f7911cc7dee145e6014e3?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bb4c564c74154b191e8112984cdfe7d04006fce4a8f7911cc7dee145e6014e3?apiKey=4001fa8768534eaebe631c6feeea2451&",
            width: '' },
          { url:"https://cdn.builder.io/api/v1/image/assets/TEMP/be55e8d7c67c1d0448519551698e289a3379d8a0324cd1a49fefec691a63bdc4?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/be55e8d7c67c1d0448519551698e289a3379d8a0324cd1a49fefec691a63bdc4?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/be55e8d7c67c1d0448519551698e289a3379d8a0324cd1a49fefec691a63bdc4?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/be55e8d7c67c1d0448519551698e289a3379d8a0324cd1a49fefec691a63bdc4?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/be55e8d7c67c1d0448519551698e289a3379d8a0324cd1a49fefec691a63bdc4?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/be55e8d7c67c1d0448519551698e289a3379d8a0324cd1a49fefec691a63bdc4?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/be55e8d7c67c1d0448519551698e289a3379d8a0324cd1a49fefec691a63bdc4?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/be55e8d7c67c1d0448519551698e289a3379d8a0324cd1a49fefec691a63bdc4?apiKey=4001fa8768534eaebe631c6feeea2451&",
            width: '' }
        ]
      },
      {
        left: '★',
        title: '到達溫泉酒店',
        desc: '到達溫泉酒店',
        images: [
          { url:"https://cdn.builder.io/api/v1/image/assets/TEMP/1de938a91918336cb55d3eef744f3dfe32b4d3fc4d1cdde7ba55d9c482e94008?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1de938a91918336cb55d3eef744f3dfe32b4d3fc4d1cdde7ba55d9c482e94008?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1de938a91918336cb55d3eef744f3dfe32b4d3fc4d1cdde7ba55d9c482e94008?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1de938a91918336cb55d3eef744f3dfe32b4d3fc4d1cdde7ba55d9c482e94008?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1de938a91918336cb55d3eef744f3dfe32b4d3fc4d1cdde7ba55d9c482e94008?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1de938a91918336cb55d3eef744f3dfe32b4d3fc4d1cdde7ba55d9c482e94008?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1de938a91918336cb55d3eef744f3dfe32b4d3fc4d1cdde7ba55d9c482e94008?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1de938a91918336cb55d3eef744f3dfe32b4d3fc4d1cdde7ba55d9c482e94008?apiKey=4001fa8768534eaebe631c6feeea2451&",
            width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/aa304fed648b5ca2fec57a508fcc2b7c4d56de8246f031631ebef9c72c8f1673?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa304fed648b5ca2fec57a508fcc2b7c4d56de8246f031631ebef9c72c8f1673?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa304fed648b5ca2fec57a508fcc2b7c4d56de8246f031631ebef9c72c8f1673?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa304fed648b5ca2fec57a508fcc2b7c4d56de8246f031631ebef9c72c8f1673?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa304fed648b5ca2fec57a508fcc2b7c4d56de8246f031631ebef9c72c8f1673?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa304fed648b5ca2fec57a508fcc2b7c4d56de8246f031631ebef9c72c8f1673?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa304fed648b5ca2fec57a508fcc2b7c4d56de8246f031631ebef9c72c8f1673?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/aa304fed648b5ca2fec57a508fcc2b7c4d56de8246f031631ebef9c72c8f1673?apiKey=4001fa8768534eaebe631c6feeea2451&",
            width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/851f394e2110017de518508d047bce164dae685ac7b5acd9a7cb1ee185bea021?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/851f394e2110017de518508d047bce164dae685ac7b5acd9a7cb1ee185bea021?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/851f394e2110017de518508d047bce164dae685ac7b5acd9a7cb1ee185bea021?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/851f394e2110017de518508d047bce164dae685ac7b5acd9a7cb1ee185bea021?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/851f394e2110017de518508d047bce164dae685ac7b5acd9a7cb1ee185bea021?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/851f394e2110017de518508d047bce164dae685ac7b5acd9a7cb1ee185bea021?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/851f394e2110017de518508d047bce164dae685ac7b5acd9a7cb1ee185bea021?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/851f394e2110017de518508d047bce164dae685ac7b5acd9a7cb1ee185bea021?apiKey=4001fa8768534eaebe631c6feeea2451&",
            width: '' },
          { url:"https://cdn.builder.io/api/v1/image/assets/TEMP/79773f954bb340226432b8af221f107e70471f6556435c779b9ccd0d2a6fe42f?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/79773f954bb340226432b8af221f107e70471f6556435c779b9ccd0d2a6fe42f?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/79773f954bb340226432b8af221f107e70471f6556435c779b9ccd0d2a6fe42f?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/79773f954bb340226432b8af221f107e70471f6556435c779b9ccd0d2a6fe42f?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/79773f954bb340226432b8af221f107e70471f6556435c779b9ccd0d2a6fe42f?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/79773f954bb340226432b8af221f107e70471f6556435c779b9ccd0d2a6fe42f?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/79773f954bb340226432b8af221f107e70471f6556435c779b9ccd0d2a6fe42f?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/79773f954bb340226432b8af221f107e70471f6556435c779b9ccd0d2a6fe42f?apiKey=4001fa8768534eaebe631c6feeea2451&",
            width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/bb66307d71004a1a9ac99a7749311c404b6007911aacf7928032209245a313b2?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb66307d71004a1a9ac99a7749311c404b6007911aacf7928032209245a313b2?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb66307d71004a1a9ac99a7749311c404b6007911aacf7928032209245a313b2?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb66307d71004a1a9ac99a7749311c404b6007911aacf7928032209245a313b2?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb66307d71004a1a9ac99a7749311c404b6007911aacf7928032209245a313b2?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb66307d71004a1a9ac99a7749311c404b6007911aacf7928032209245a313b2?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb66307d71004a1a9ac99a7749311c404b6007911aacf7928032209245a313b2?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb66307d71004a1a9ac99a7749311c404b6007911aacf7928032209245a313b2?apiKey=4001fa8768534eaebe631c6feeea2451&",
            width: '' }
        ]
      },
      {
        left: '★',
        title: '酒店休息(與美女自由活動)',
        desc: '',
        images: []
      }
    ]
  },
  {
    number: '02',
    dayNum: 'Day-2',
    title: ['歌舞伎町觀看日本风俗表演秀','东京上空私人飞机观光 ','日本老牌吉原泡泡浴體驗'],
    titleColor:'yellow',
    items: [
      {
        left: '★',
        title: '歌舞伎町日本风俗表演秀(3小时)',
        desc: `体验东京夜生活的巅峰，尽在新宿歌舞伎町的脱衣舞秀！一场大秀分为6个小场次,每场30约分钟,由6位佳人倾情演出，专业的表演团队、华丽的舞台设计和多样化的艺术形式，在音乐伴随下舞动身躯,随着女舞者的衣服一件件退去,呈现出最火热的女性曼妙身体曲线为您呈现一场视觉和听觉的双重盛宴。在这里，感受激情四溢的夜晚，与表演者近距离互动，释放您的热情，享受不一样的夜生活体验。歌舞伎町的脱衣舞秀，让每个夜晚都精彩纷呈，不容错过`,
        images: [
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day2.1.1.png', width: '' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day2.1.2.png', width: '' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day2.1.3.png', width: '' }
        ]
      },
      {
        left: '★',
        title: '俯瞰东京私人直升机观光体验',
        desc: `东京留下特别回忆，乘坐观光直升飞机会是个不错的选择。您可从高空眺望东京塔、东京晴空塔、彩虹桥等著名地标。夜幕降临后，东京的街道就会在炫目灯光的照耀下开始闪亮起来，从空中欣赏的东京夜景美艳动人，令人惊叹。欢迎您预订直升飞机游览行程，来一场永生难忘的精彩体验。`,
        images: [
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/978eafee584961775693f12ed97c4b03fba0d2ddd647b70797e5e751af98db7f?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/978eafee584961775693f12ed97c4b03fba0d2ddd647b70797e5e751af98db7f?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/978eafee584961775693f12ed97c4b03fba0d2ddd647b70797e5e751af98db7f?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/978eafee584961775693f12ed97c4b03fba0d2ddd647b70797e5e751af98db7f?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/978eafee584961775693f12ed97c4b03fba0d2ddd647b70797e5e751af98db7f?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/978eafee584961775693f12ed97c4b03fba0d2ddd647b70797e5e751af98db7f?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/978eafee584961775693f12ed97c4b03fba0d2ddd647b70797e5e751af98db7f?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/978eafee584961775693f12ed97c4b03fba0d2ddd647b70797e5e751af98db7f?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/8ac97616e82d9601c90c7538cb0bbe9b591e5f02ff995c1ad29b9e4601756fdd?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ac97616e82d9601c90c7538cb0bbe9b591e5f02ff995c1ad29b9e4601756fdd?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ac97616e82d9601c90c7538cb0bbe9b591e5f02ff995c1ad29b9e4601756fdd?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ac97616e82d9601c90c7538cb0bbe9b591e5f02ff995c1ad29b9e4601756fdd?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ac97616e82d9601c90c7538cb0bbe9b591e5f02ff995c1ad29b9e4601756fdd?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ac97616e82d9601c90c7538cb0bbe9b591e5f02ff995c1ad29b9e4601756fdd?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ac97616e82d9601c90c7538cb0bbe9b591e5f02ff995c1ad29b9e4601756fdd?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ac97616e82d9601c90c7538cb0bbe9b591e5f02ff995c1ad29b9e4601756fdd?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/605f2fc8b1656c9e9e3d0f4d8c5de7d988fa1000991e9d9d9d73790a626b5634?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/605f2fc8b1656c9e9e3d0f4d8c5de7d988fa1000991e9d9d9d73790a626b5634?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/605f2fc8b1656c9e9e3d0f4d8c5de7d988fa1000991e9d9d9d73790a626b5634?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/605f2fc8b1656c9e9e3d0f4d8c5de7d988fa1000991e9d9d9d73790a626b5634?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/605f2fc8b1656c9e9e3d0f4d8c5de7d988fa1000991e9d9d9d73790a626b5634?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/605f2fc8b1656c9e9e3d0f4d8c5de7d988fa1000991e9d9d9d73790a626b5634?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/605f2fc8b1656c9e9e3d0f4d8c5de7d988fa1000991e9d9d9d73790a626b5634?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/605f2fc8b1656c9e9e3d0f4d8c5de7d988fa1000991e9d9d9d73790a626b5634?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' }
        ]
      },
      {
        left: '★',
        title: '日本老牌吉原泡泡浴體驗',
        desc: '来自日本最大最高级的泡泡浴品牌「吉原高级泡泡浴」店,主打温柔身材一流的日本女性女孩在身上塗上大量的沐浴露，並用胸部和大腿內側清洗男人的身體。其特徵是，將蓬鬆的泡沫體與男性的身體緊密接觸，使富有彈性的乳房爬過男性的身體，或在柔軟的大腿內側滑動手臂和腿，從而清洗全身。洗澡時女孩們所坐的凹椅是為了讓女孩們能夠把手伸到男人的胯下，讓她們用手指仔細地清洗從陰莖到肛門的一切。當女孩用指尖輕輕地、仔細地清洗你的敏感部位時，感覺太好了，你可能會忘記你實際上是在清洗你的身體。一起入浴的时候,您可以与女孩亲吻,水中口交等,感受日本女孩独特的温柔',
        images: [
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/ccec3a2e1fa3ff49788a4b7b49098fbd20061d33182b5857109fef61bc891b43?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ccec3a2e1fa3ff49788a4b7b49098fbd20061d33182b5857109fef61bc891b43?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ccec3a2e1fa3ff49788a4b7b49098fbd20061d33182b5857109fef61bc891b43?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ccec3a2e1fa3ff49788a4b7b49098fbd20061d33182b5857109fef61bc891b43?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ccec3a2e1fa3ff49788a4b7b49098fbd20061d33182b5857109fef61bc891b43?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ccec3a2e1fa3ff49788a4b7b49098fbd20061d33182b5857109fef61bc891b43?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ccec3a2e1fa3ff49788a4b7b49098fbd20061d33182b5857109fef61bc891b43?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ccec3a2e1fa3ff49788a4b7b49098fbd20061d33182b5857109fef61bc891b43?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/a989c854450bfbfdda3662e9f2e59e9c8aacb4bc5491c1ac9a7e26cd1bd32d0d?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a989c854450bfbfdda3662e9f2e59e9c8aacb4bc5491c1ac9a7e26cd1bd32d0d?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a989c854450bfbfdda3662e9f2e59e9c8aacb4bc5491c1ac9a7e26cd1bd32d0d?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a989c854450bfbfdda3662e9f2e59e9c8aacb4bc5491c1ac9a7e26cd1bd32d0d?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a989c854450bfbfdda3662e9f2e59e9c8aacb4bc5491c1ac9a7e26cd1bd32d0d?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a989c854450bfbfdda3662e9f2e59e9c8aacb4bc5491c1ac9a7e26cd1bd32d0d?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a989c854450bfbfdda3662e9f2e59e9c8aacb4bc5491c1ac9a7e26cd1bd32d0d?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a989c854450bfbfdda3662e9f2e59e9c8aacb4bc5491c1ac9a7e26cd1bd32d0d?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' },
          { url:"https://cdn.builder.io/api/v1/image/assets/TEMP/c125df8d8ef46d5c6c0f1896e0241816b3a8d8f0481a6f1adf2ac9e31002aa7b?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c125df8d8ef46d5c6c0f1896e0241816b3a8d8f0481a6f1adf2ac9e31002aa7b?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c125df8d8ef46d5c6c0f1896e0241816b3a8d8f0481a6f1adf2ac9e31002aa7b?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c125df8d8ef46d5c6c0f1896e0241816b3a8d8f0481a6f1adf2ac9e31002aa7b?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c125df8d8ef46d5c6c0f1896e0241816b3a8d8f0481a6f1adf2ac9e31002aa7b?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c125df8d8ef46d5c6c0f1896e0241816b3a8d8f0481a6f1adf2ac9e31002aa7b?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c125df8d8ef46d5c6c0f1896e0241816b3a8d8f0481a6f1adf2ac9e31002aa7b?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c125df8d8ef46d5c6c0f1896e0241816b3a8d8f0481a6f1adf2ac9e31002aa7b?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' }
        ]
      },
      {
        left: '★',
        title: '酒店休息(第二天行程結束)',
        desc: '',
        images: []
      }
    ]
  },
  {
    number: '03',
    dayNum: 'Day-3',
    title: '兔子先生-成人AV现场拍摄参观&日本美女上門空降體驗',
    titleColor:'yellow',
    items: [
      {
        left: '',
        title: '',
        desc: '在片场可以看到我们免子先生的AV拍摄现场幕后,了解平时在电脑前展示的片子是如何拍摄的,一部AV影片是如何完成出品,现场观看来自日本专业的男女主角你眼前完成全套做爱,拍摄现场可能不只是1v1,可能会是1v2,甚至更多,这个会根据当天的拍摄计划进行制定,拍摄结束与女生拍照留念。但是请注意,拍摄期间不可打扰正常拍摄活动,当然如果你够胆可以上前体验一把汁男的感受.',
        images: [
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day3.1.png', width: '' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day3.2.png', width: '' },
          { url: 'https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/day3.3.png', width: '' }
        ]
      },
      {
        left: '★',
        title: '日本美女上門空降體驗',
        titleColor:'yellow',
        desc: '兔子先生专属女优&日本知名女优&日本素人',
        images: [
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/6be3a58c26dbd024f13323b517b8f2ab39bbbcd3bc311d1260aedb94cbc9aaeb?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6be3a58c26dbd024f13323b517b8f2ab39bbbcd3bc311d1260aedb94cbc9aaeb?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6be3a58c26dbd024f13323b517b8f2ab39bbbcd3bc311d1260aedb94cbc9aaeb?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6be3a58c26dbd024f13323b517b8f2ab39bbbcd3bc311d1260aedb94cbc9aaeb?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6be3a58c26dbd024f13323b517b8f2ab39bbbcd3bc311d1260aedb94cbc9aaeb?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6be3a58c26dbd024f13323b517b8f2ab39bbbcd3bc311d1260aedb94cbc9aaeb?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6be3a58c26dbd024f13323b517b8f2ab39bbbcd3bc311d1260aedb94cbc9aaeb?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6be3a58c26dbd024f13323b517b8f2ab39bbbcd3bc311d1260aedb94cbc9aaeb?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' },
          { url:"https://cdn.builder.io/api/v1/image/assets/TEMP/5c3240d7f44a2c0a002c802f6fbaa08b535e26cb9472e4be23785060eff56061?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c3240d7f44a2c0a002c802f6fbaa08b535e26cb9472e4be23785060eff56061?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c3240d7f44a2c0a002c802f6fbaa08b535e26cb9472e4be23785060eff56061?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c3240d7f44a2c0a002c802f6fbaa08b535e26cb9472e4be23785060eff56061?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c3240d7f44a2c0a002c802f6fbaa08b535e26cb9472e4be23785060eff56061?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c3240d7f44a2c0a002c802f6fbaa08b535e26cb9472e4be23785060eff56061?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c3240d7f44a2c0a002c802f6fbaa08b535e26cb9472e4be23785060eff56061?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c3240d7f44a2c0a002c802f6fbaa08b535e26cb9472e4be23785060eff56061?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/6546c82405af3d375fba308c1af7afa75c5099acf8ccfd40eb1b41c0bf3bdb49?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6546c82405af3d375fba308c1af7afa75c5099acf8ccfd40eb1b41c0bf3bdb49?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6546c82405af3d375fba308c1af7afa75c5099acf8ccfd40eb1b41c0bf3bdb49?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6546c82405af3d375fba308c1af7afa75c5099acf8ccfd40eb1b41c0bf3bdb49?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6546c82405af3d375fba308c1af7afa75c5099acf8ccfd40eb1b41c0bf3bdb49?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6546c82405af3d375fba308c1af7afa75c5099acf8ccfd40eb1b41c0bf3bdb49?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6546c82405af3d375fba308c1af7afa75c5099acf8ccfd40eb1b41c0bf3bdb49?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6546c82405af3d375fba308c1af7afa75c5099acf8ccfd40eb1b41c0bf3bdb49?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/10c0aa209945423feac31c5b2386762ab83891ce240c06657c4289657680ee60?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c0aa209945423feac31c5b2386762ab83891ce240c06657c4289657680ee60?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c0aa209945423feac31c5b2386762ab83891ce240c06657c4289657680ee60?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c0aa209945423feac31c5b2386762ab83891ce240c06657c4289657680ee60?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c0aa209945423feac31c5b2386762ab83891ce240c06657c4289657680ee60?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c0aa209945423feac31c5b2386762ab83891ce240c06657c4289657680ee60?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c0aa209945423feac31c5b2386762ab83891ce240c06657c4289657680ee60?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/10c0aa209945423feac31c5b2386762ab83891ce240c06657c4289657680ee60?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/8d1e2aac8ab7da7fc2b397269d10f06202ce7ee89a235679e1271be1d88947d2?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8d1e2aac8ab7da7fc2b397269d10f06202ce7ee89a235679e1271be1d88947d2?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8d1e2aac8ab7da7fc2b397269d10f06202ce7ee89a235679e1271be1d88947d2?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8d1e2aac8ab7da7fc2b397269d10f06202ce7ee89a235679e1271be1d88947d2?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8d1e2aac8ab7da7fc2b397269d10f06202ce7ee89a235679e1271be1d88947d2?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8d1e2aac8ab7da7fc2b397269d10f06202ce7ee89a235679e1271be1d88947d2?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8d1e2aac8ab7da7fc2b397269d10f06202ce7ee89a235679e1271be1d88947d2?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8d1e2aac8ab7da7fc2b397269d10f06202ce7ee89a235679e1271be1d88947d2?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' },
          { url: "https://cdn.builder.io/api/v1/image/assets/TEMP/099822046f2ccdffe362f22febaa8870cb6e57ae2b954d9e7cd2c75fc12623cf?apiKey=4001fa8768534eaebe631c6feeea2451&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/099822046f2ccdffe362f22febaa8870cb6e57ae2b954d9e7cd2c75fc12623cf?apiKey=4001fa8768534eaebe631c6feeea2451&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/099822046f2ccdffe362f22febaa8870cb6e57ae2b954d9e7cd2c75fc12623cf?apiKey=4001fa8768534eaebe631c6feeea2451&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/099822046f2ccdffe362f22febaa8870cb6e57ae2b954d9e7cd2c75fc12623cf?apiKey=4001fa8768534eaebe631c6feeea2451&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/099822046f2ccdffe362f22febaa8870cb6e57ae2b954d9e7cd2c75fc12623cf?apiKey=4001fa8768534eaebe631c6feeea2451&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/099822046f2ccdffe362f22febaa8870cb6e57ae2b954d9e7cd2c75fc12623cf?apiKey=4001fa8768534eaebe631c6feeea2451&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/099822046f2ccdffe362f22febaa8870cb6e57ae2b954d9e7cd2c75fc12623cf?apiKey=4001fa8768534eaebe631c6feeea2451&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/099822046f2ccdffe362f22febaa8870cb6e57ae2b954d9e7cd2c75fc12623cf?apiKey=4001fa8768534eaebe631c6feeea2451&", width: '' }
        ]
      },
    ]
  },
  {
    number: '04',
    dayNum: 'Day-4',
    title: '东京-上海',
    items: [
      {
        left: '',
        title: '',
        desc: ' 行程结束,在指定时间集合,前往东京机场,返回上海.',
        images: []
      }
    ]
  }
]


export const tripData3 = [
  {
    number: '01',
    dayNum: 'Day-1',
    title: '「上海出发」抵达东京',
    items: [
      {
        left: 'Day1',
        title: '「24小時」日本美女全程陪玩',
        desc: '',
        images: []
      },
      {
        left: '★',
        title: '美女接機,車上口爆服務',
        desc: `美女在東京機場接機,客人上車後可以在車裡對日本美女為所欲為,口交或者在空間可以使展開的情況下,進行車震等任何行為`,
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/IMG_5124.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/IMG_5125.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/IMG_5126.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/IMG_5127.jpg', width: '' },
        ]
      },
      {
        left: '★',
        title: '到達溫泉酒店',
        desc: '到達溫泉酒店',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/_w1700%201.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/0%201182400.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/1ea67be54a8ca68813b85440adc5d79b-1.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/images-2.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/list.jpg', width: '' },
          
        ]
      },
      {
        left: '★',
        title: '酒店休息&自由活動',
        desc: '日本美女24小時陪伴',
        images: [
        ]
      },
      {
        left: '★',
        title: '晚餐(日式和牛料理)',
        desc: '',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/0006105857F2.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/t0301_015.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/w1200.jpg', width: '' },
        ]
      }
    ]
  },
  {
    number: '02',
    dayNum: 'Day-2',
    title: ['*美女陪玩富士山','*俯瞰東京直升機體驗(东京湾)','*日本老牌吉原泡泡浴體驗','早餐:酒店自助早餐,午餐:传统日式手打乌冬面套餐,晚餐:日本河豚刺生'],
    titleColor:'yellow',
    items: [
      {
        left: '★',
        title: '酒店自助早餐',
        desc: '',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/0_%E8%AD%8C%EF%BD%AC%E7%B8%BA%EF%BD%AE%E8%9D%AE%E3%83%BBWBY00096-1920x1281-1.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/8y6IvRrwrKbupCUXVzLRegF3DqDgNHkYuDynSzPF.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/kv-01@mobile.jpg', width: '' },
        ]
      },
      {
        left: '★',
        title: '美女陪玩富士山',
        desc: '来日本必打卡的景点',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/fujiyama.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/TTNmedia_zhcn_MountFuji.jpg', width: '' },
        ]
      },
      {
        left: '★',
        title: '传统日式手打乌冬面套餐',
        desc: '',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/20210109_102958_1431d6a2_w1920.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/photox.pchome.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/udon-with-mixed-tempura%201.jpg', width: '' },
        ]
      },
      {
        left: '★',
        title: '俯瞰东京私人直升机观光体验(东京湾)',
        desc: '东京留下特别回忆，乘坐观光直升飞机会是个不错的选择。您可从高空眺望东京塔、东京晴空塔、彩虹桥等著名地标。夜幕降临后，东京的街道就会在炫目灯光的照耀下开始闪亮起来，从空中欣赏的东京夜景美艳动人，令人惊叹。欢迎您预订直升飞机游览行程，来一场永生难忘的精彩体验。',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/ye-jing_a1bfui%201.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/%E6%88%AA%E5%B1%8F2024-06-06%2013.50.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/%E6%88%AA%E5%B1%8F2024-06-06%2013.51.jpg', width: '' },
        ]
      },
      {
        left: '★',
        title: '日本河豚刺生',
        desc: '',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/1591693159192.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/0.jpg', width: '' },
        ]
      },
      {
        left: '★',
        title: '日本老牌吉原泡泡浴體驗',
        desc: '来自日本最大最高级的泡泡浴品牌「吉原高级泡泡浴」店,主打温柔身材一流的日本女性女孩在身上塗上大量的沐浴露，並用胸部和大腿內側清洗男人的身體。其特徵是，將蓬鬆的泡沫體與男性的身體緊密接觸，使富有彈性的乳房爬過男性的身體，或在柔軟的大腿內側滑動手臂和腿，從而清洗全身。洗澡時女孩們所坐的凹椅是為了讓女孩們能夠把手伸到男人的胯下，讓她們用手指仔細地清洗從陰莖到肛門的一切。當女孩用指尖輕輕地、仔細地清洗你的敏感部位時，感覺太好了，你可能會忘記你實際上是在清洗你的身體。一起入浴的时候,您可以与女孩亲吻,水中口交等,感受日本女孩独特的温柔',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/E-_EzEGVcActf43.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/%E6%88%AA%E5%B1%8F2024-05-20%2014.39%201.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/%E6%88%AA%E5%B1%8F2024-05-20%2014.40.jpg', width: '' },
        ]
      },
      {
        left: '★',
        title: '酒店休息(第二天行程結束)',
        desc: '',
        images: [

        ]
      },
    ]
  },
  {
    number: '03',
    dayNum: 'Day-3',
    title:[
      '*日式人体盛宴',
      '*日本AV成人男优圆梦计划',
      '早餐:酒店自助早餐,午餐:日式人体盛宴,晚餐:日本蟹道乐'
    ],
    titleColor:'yellow',
    items: [
      {
        left: '★',
        title: '日式人体盛宴',
        desc: '由我们精心挑选的日本美女,入浴清洗后,邀请日本著名手寿司制作者,亲临现场制作,寿司与刺生将会在在女孩的裸体上展现,也请尽情的享用,这种高境界的的盛宴吧!',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/4fcbe812de39d0164e2855eeec3e91b3.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/57f26396-1658-4a2c-a927-0ff40abd0311.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/d16cdf04146094e70227d4c354c0129c.jpg', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/E1584597632841_6902_1.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/nyotaimori1.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/nyotaimori5.png', width: '' },
        ]
      },
      {
        left: '★',
        title: '日本AV成人男优圆梦计划',
        desc: '根据您的需求,打造完全属于你的成人影片\n120分钟的日本AV拍摄体验,请在敬请的体验当男优的快感\n' +
          '*提供在日本的10多个拍摄场景,提供拍摄团队,与服装道具造型等全套服务\n' +
          '*拍摄后的影片可作为自己珍藏',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/IMG_5294%201.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/IMG_4887%201.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/IMG_4454%201.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/IMG_4454%201%20(1).png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/IMG_3755%201.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/IMG_5034%201.png', width: '' },
        ]
      },
      {
        left: '★',
        title:'日本蟹道',
        desc: '',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/%E3%81%8B%E3%81%AB%E9%81%93%E6%A5%BD%EF%BC%88%E8%9F%B9%E9%81%93%E4%B9%90%EF%BC%89%E4%BA%BA%E6%B0%94%E8%9E%83%E8%9F%B9%E6%96%99%E7%90%86%E9%A4%90%E5%8E%85-%E6%A2%85%E7%94%B0%E5%9C%B0%E5%8C%BA.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/1517232968-4efdd2f969559e8b1c92e99f32ded48e.png', width: '' }
        ]
      },
      {
        left: '★',
        title:'酒店休息(第三天行程結束)',
        desc: '',
        images: [
        ]
      },
    ]
  },
  {
    number: '04',
    dayNum: 'Day-4',
    title: '东京-上海',
    items: [
      {
        left: '',
        title: '',
        desc: '早餐:酒店自助早餐,午餐:鳗鱼饭',
        images: []
      },
      {
        left: '★',
        title:'鳗鱼饭',
        desc: '',
        images: [
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/1503047238-1503303360.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/images.png', width: '' },
          { url: 'https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/0031564585F4_1024x576d80.png', width: '' },
        ]
      },
      {
        left: '★',
        title:'东京-上海',
        desc: '行程结束,在指定时间集合,前往东京机场,返回上海.',
        images: [
        ]
      },
    ]
  }
]

