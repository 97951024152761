import React, { useState, useEffect } from 'react';
import { Button, Popover } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import useAuthStore from '../authStore';
import { useNavigate } from 'react-router-dom';

const UserProfileIcon: React.FC = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { logout, user } = useAuthStore();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('aToken');
    logout();
    setPopoverVisible(false);
    navigate('/');
  };

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  if (!user?.uid) {
    return null;
  }

  const content = (
    <div className="p-4">
      <Button
        type="primary"
        danger
        block
        onClick={handleLogout}
        style={{
          fontSize: isMobile ? '12px' : '16px', // Adjust button text size
          padding: isMobile ? '6px 12px' : '', // Adjust button padding
        }}
      >
        Log Out
      </Button>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      open={popoverVisible}
      onOpenChange={handlePopoverVisibleChange}
      overlayStyle={{
        width: isMobile ? '150px' : '200px', // Adjust popover width
        padding: isMobile ? '10px' : '20px', // Adjust popover padding
      }}
    >
      <div className="cursor-pointer flex">
        <LogoutOutlined className="md:block" style={{ fontSize: isMobile ? '18px' : '22px' }} />
      </div>
    </Popover>
  );
};

export default UserProfileIcon;




/* import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import useAuthStore from '../authStore';
import { useNavigate } from 'react-router-dom';
const UserProfileIcon: React.FC = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { logout, user } = useAuthStore();
  const navigate = useNavigate()
  const handleLogout = () => {
    // 在这里执行登出逻辑，包括移除本地存储中的 aToken
    localStorage.removeItem('aToken');
    logout()
    setPopoverVisible(false);
    navigate('/')
  };

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };
  if (!user?.uid) {
    return null
  }

  const content = (
    <div className="p-4">
      <Button type="primary" danger block onClick={handleLogout}>
        Log Out
      </Button>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      open={popoverVisible}
      onOpenChange={handlePopoverVisibleChange}
    >
      <div className="cursor-pointer flex">
        <LogoutOutlined className="hidden md:block" />
      </div>
    </Popover>
  );
};

export default UserProfileIcon;
 */