import { Modal, Button } from 'antd';
import useModalStore from '../modalStore';

const ContactModal = () => {
  const { isOpen, showModal, hideModal } = useModalStore()
  const handleCancel = () => {
    hideModal();
  };

  const handleConfirm = () => {
    hideModal()
    window.open('https://t.me/mr_bunny_vip', '_blank')
  };

  return (
    <Modal
      title="溫馨提示"
      open={isOpen}
      footer={null}
      centered
      onCancel={handleCancel}
      className="contact-modal"
    >
      <p className="text-[0.7rem] md:text-sm max-h-[400px] overflow-y-auto p-4">
        <p>兔子旅行提醒您,預約高端行程請註意一下內容:</p>
        <p className='mb-2'>感謝您選擇兔子先生高端定製旅行!</p>
        <p className='my-1'>· 請直接告知我們您喜愛並且鐘意的🔞成人偶像</p>
        <p className='my-1'>· 請告知您的日程時間 </p>
        <p className='my-1'>· 請提供您的預算&獲取喜愛偶像的報價</p>
        <p className='my-1'>· 信息匹配成功後,需要提前支付50%~的定金</p>
        <p className='my-1'>· 行程日程當日,需支付訂單尾款</p>
        <p className='my-1'>· 提供海外空降服務(詳情請聯系服務人員)</p>
        <p className='my-1'>· 提供日本AV現場參觀(詳情請聯系服務人員)</p>
        <p className='my-2'>註意事項</p>
        <p className='my-1'>· 預約不可取消,若成人偶像因自然及其他方面的問題沒能履行承諾,定金將全部退還,若用戶單方想要取消訂單,定金將不予退還.</p>
        <p className='my-1'>· 請尊重成人偶像,請務必溫柔對待.禁止暴力與強迫,如有發現我們將直接終止服務.</p>
        <p className='my-1'>· 預約時請如實告知赴約人數.</p>
        <p className='my-1'>· 請務必與我們溝通:是否戴套行為.</p>
        <p className='my-1'>· 為保證日本成人偶像,請勿將網絡影片與偶像影片,照片,等各類信息在赴約時與其分享,以免造成不愉快的麻煩.</p>
        <p className='my-1'>· 為保護您在海外旅行的隱私與安全,請勿與成人偶像私下交換聯系方式等,或透露任何個人隱私信息,請務必遵守.以免造成 不必要的危險行為</p>
        <p className='my-1'>· 更多兔子先生旅行條款請查看 <span className='text-[#FF548B] cursor-pointer' onClick={() => {
          window.open('/term')
        }}>《terms》</span></p>
        <p className='my-1'>· 預祝您有個美好的旅行,感謝選擇兔子先生旅行~</p>
      </p>
      <div className="modal-footer flex justify-end mt-4 mx-4 ">
        {/* <Button onClick={handleCancel} className="mr-2">Cancel</Button> */}
        <button
          onClick={handleConfirm}
          className="bg-gradient-to-r from-[#F093FB] to-[#F5576C] text-white font-montserrat flex items-center justify-center py-2 px-4 rounded-full w-[40%]"
        >
          Agree
        </button>
      </div>
    </Modal>
  );
};

export default ContactModal;
