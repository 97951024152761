import { useNavigate } from "react-router-dom";
import Header from "../../component/Header";
import { contact } from "../../grocery/utils";
const iconImages = [
  { icon: "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/twitter.png", url: 'https://twitter.com/Mr_BunnySex' },
  { icon: "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/telegram.png", url: 'https://t.me/mr_bunny_vip' },
  { icon: "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/PornHubPrLogo.png", url: 'https://cn.pornhub.com/channels/mr-bunny' },
];

const handleIconClick = (url: string) => {
  window.open(url, '_blank');
};
function About() {

  const navigate = useNavigate()
  return (
    <div className="bg-black">
      <Header />
      <div className="flex  flex-col justify-center items-center p-4 md:p-0">
        <img className="w-[60%] md:w-auto" src='https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/aboutBanner.svg' alt="img" />
        <div className="flex flex-col md:flex-row mb-16">
          <p className="text-2xl font-montserrat-extra-bold md:text-5xl text-[#FF548B] md:w-[400px] text-left">About Us</p>
          <p className="md:w-[400px] text-left">We are a media production company from Japan and Taiwan and LA, we love Japanese girls, exploring and discovering the best of sex!</p>
        </div>
        <div className="flex flex-col md:flex-row mb-16">
          <p className="text-2xl font-montserrat-extra-bold md:text-5xl text-[#FF548B] md:w-[400px] text-left">Our Services</p>
          <p className="md:w-[400px] text-left">Provide Japan, Taiwan, Japan offline tour ~ you know oh!We offer customizable travel plans with Japanese girls!Customized production of adult films for personal or commercial use~More than you can imagine, let's make it happen.</p>
        </div>
        <div className="flex flex-col md:flex-row mb-16">
          <p className="text-2xl font-montserrat-extra-bold md:text-5xl text-[#FF548B] md:w-[400px] text-left">Production</p>
          <p className="md:w-[400px] text-left flex">
            <img src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/rabbit.svg" alt="rabbit" className="mr-8 w-[25vw] md:w-auto" />
            <img src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/pussy.svg" alt="pussy" className="mr-8  w-[25vw] md:w-auto" />
            <img src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/bqszlogo.png" alt="bqszlogo" className="w-[25vw] md:w-auto" />
          </p>
        </div>
        <div className="flex flex-col md:flex-row mb-16">
          <p className="text-2xl font-montserrat-extra-bold md:text-5xl text-[#FF548B] md:w-[400px] text-left">Our Partners</p>
          <p className="md:w-[400px] text-left flex flex-col">
            <p className="flex my-4 md:mb-8 md:mt-0">
              <img src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/madou.svg" alt="madou" className="mr-8 w-[35vw] md:w-auto" />
              <img src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/yxcm.svg" alt="yxcm" className="w-[35vw] md:w-auto" />
            </p>
            <p className="flex mb-8">
              <img src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/jd.svg" alt="jd" className="mr-8 w-[25vw] md:w-auto" />
              <img src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/sekon.svg" alt="sekon" className="mr-8 w-[25vw] md:w-auto" />
              <img src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/ms.svg" alt="ms" className="w-[25vw] md:w-auto" />
            </p>
            <p className="flex  mb-8">
              <img src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/tm.svg" alt="tm" className="mr-8 w-[35vw] md:w-auto" />
              <img src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/91zpc.svg" alt="zpc91" className="w-[35vw] md:w-auto" />
            </p>
          </p>
        </div>
        {/* <div className='flex justify-center mb-4'>
          媒体图标
          {iconImages.map(({ icon, url }, index) => (
            <img
              key={index}
              src={icon}
              alt={`Icon ${index + 1}`}
              className='cursor-pointer h-8 mr-8'
              onClick={() => handleIconClick(url)}
            />
          ))}
        </div> */}
        {/* <div className="flex mb-4">
          <p className="mr-8" onClick={() => { contact() }}>Contact </p>
          <p className="mr-8" onClick={() => { navigate('/membership') }}> Membership </p>
          <p className="mr-8" onClick={() => { navigate('/terms') }}> Terms </p>
        </div>
        <p className="mb-8">
          For business, please contact: <span className="text-[#FF548B] md:w-[400px] text-left">support@mrbunny.vip</span>
        </p> */}
      </div>
    </div>
  );
}

export default About;
