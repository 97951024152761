// import { useAuthStore } from '@store/auth';
import { message } from 'antd';
import axios from 'axios';

const service = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_HOST,
  timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('aToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log(error.response);
    // if (error.response?.status === 401) {
    //   localStorage.removeItem('aToken')
    //   message.error(error.response?.data);
    //   // window.location.replace('/login');
    //   return {}
    // }
    return Promise.reject(error);
  }
);

export default service;

