import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { useSearchParams } from 'react-router-dom';

const SortByPopover = () => {
  const [visible, setVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // 使用 URL 参数中的 sort 字段来初始化选择
    const sortParam = searchParams.get('sort') || 'rand';
    if (sortParam) {
      setSelectedOption(sortParam);
    }
  }, [searchParams]);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const handleSelectOption = (option: any) => {
    if(selectedOption === option){
      setSelectedOption('rand');
      searchParams.set('sort', 'rand');
    }else{
      setSelectedOption(option);
      searchParams.set('sort', option);
    }

    // 更新 URL 参数
    setSearchParams(searchParams);

    setVisible(false);
  };

  const content = (
    <div className="bg-gray-700 p-2 text-white">
      {
        [{ key: 'rand', text: '随机' },{ key: 'new', text: '最新資訊' }/* , { key: 'like', text: '最多人喜愛' } */].map(({ key, text }) => (
          <div
            key={key}
            className={`cursor-pointer rounded-lg p-2 ${selectedOption === key ? 'bg-black' : 'bg-transparent'}`}
            onClick={() => handleSelectOption(key)}
          >
            {text}
          </div>
        ))
      }
    </div>
  );

  return (
    <Popover
      content={content}
      open={visible}
      trigger="click"
      onOpenChange={handleVisibleChange}
      rootClassName='sort-popover'
    >
      <div className="flex font-montserrat-bold text-[0.8rem] md:text-xl cursor-pointer">
        排序方式{' '}
        <img
          className="ml-2 w-4 md:w-8"
          src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/chevron-double-down.svg"
          alt="chevron-double-down"
        />
      </div>
    </Popover>
  );
};

export default SortByPopover;
