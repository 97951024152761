import React, { useState, useEffect } from 'react';

const buttonStyle = {
  base: (size: string) => ({
    position: 'relative' as 'relative',
    display: 'inline-block',
    cursor: 'pointer',
    outline: 'none',
    border: '0',
    verticalAlign: 'middle' as 'middle',
    textDecoration: 'none',
    background: '#d3d3d3',
    padding: size === 'small' ? '0.5rem 0.75rem' : '0.75rem 1rem',
    fontSize: size === 'small' ? '0.5rem' : 'inherit', // Adjust font size
    fontFamily: "'Mukta', sans-serif",
    borderRadius: size === 'small' ? '1rem' : '1.625rem',
    overflow: 'hidden' as 'hidden',
  }),
  circle: (size: string) => ({
    transition: 'all 0.45s cubic-bezier(0.65, 0, 0.076, 1)',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
    width: size === 'small' ? '2rem' : '3rem',
    height: size === 'small' ? '2rem' : '3rem',
    background: '#ff69b4',
    borderRadius: '50%',
  }),
  icon: (size: string) => ({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: size === 'small' ? '0.5rem' : '0.625rem',
    transform: 'translateY(-50%)',
    width: size === 'small' ? '0.75rem' : '1.125rem', // Adjust icon size
    height: '0.125rem',
    background: 'none',
  }),
  arrowBefore: {
    content: '""',
    position: 'absolute' as 'absolute',
    top: '-0.25rem',
    right: '0.0625rem',
    width: '0.625rem',
    height: '0.625rem',
    borderTop: '0.125rem solid #fff',
    borderRight: '0.125rem solid #fff',
    transform: 'rotate(45deg)',
  },
  buttonText: (size: string) => ({
    transition: 'color 0.45s cubic-bezier(0.65, 0, 0.076, 1)',
    position: 'relative' as 'relative',
    padding: size === 'small' ? '0.5rem 0.75rem' : '0.75rem 1rem',
    marginLeft: size === 'small' ? '1rem' : '3.5rem',
    color: '#000',
    fontWeight: 700,
    lineHeight: 1.6,
    textAlign: 'center' as 'center',
    textTransform: 'uppercase' as 'uppercase',
    whiteSpace: 'nowrap' as 'nowrap',
  }),
  circleHover: (size: string) => ({
    width: '100%',
    left: '0',
    borderRadius: size === 'small' ? '1rem' : '1.625rem',
  }),
  buttonTextHover: {
    color: '#fff',
  },
};

const Button: React.FC<{ onClick?: () => void; children: React.ReactNode; className?: string; size?: string }> = ({
  onClick,
  children,
  className,
  size = 'default',
}) => {
  const [hover, setHover] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <button
      className={`${className} learn-more`}
      onClick={onClick}
      style={buttonStyle.base(isMobile ? 'small' : size)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span
        className="circle"
        aria-hidden="true"
        style={hover ? { ...buttonStyle.circle(isMobile ? 'small' : size), ...buttonStyle.circleHover(isMobile ? 'small' : size) } : buttonStyle.circle(isMobile ? 'small' : size)}
      >
        <span className="icon arrow" style={buttonStyle.icon(isMobile ? 'small' : size)}>
          <span style={buttonStyle.arrowBefore}></span>
        </span>
      </span>
      <span className="button-text" style={hover ? { ...buttonStyle.buttonText(isMobile ? 'small' : size), ...buttonStyle.buttonTextHover } : buttonStyle.buttonText(isMobile ? 'small' : size)}>
        {children}
      </span>
    </button>
  );
};

export default Button;


/* import React, { useState } from 'react';

const buttonStyle = {
  base: {
    position: 'relative' as 'relative',
    display: 'inline-block',
    cursor: 'pointer',
    outline: 'none',
    border: '0',
    verticalAlign: 'middle' as 'middle',
    textDecoration: 'none',
    background: '#d3d3d3', // 初始背景颜色为浅灰色
    padding: '0.75rem 1rem',
    fontSize: 'inherit',
    fontFamily: "'Mukta', sans-serif",
    borderRadius: '1.625rem', // 确保按钮两侧为半圆形
    overflow: 'hidden' as 'hidden',
  },
  circle: {
    transition: 'all 0.45s cubic-bezier(0.65, 0, 0.076, 1)',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
    width: '3rem',
    height: '3rem',
    background: '#ff69b4', // 粉色
    borderRadius: '50%',
  },
  icon: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '0.625rem',
    transform: 'translateY(-50%)',
    width: '1.125rem',
    height: '0.125rem',
    background: 'none',
  },
  arrowBefore: {
    content: '""',
    position: 'absolute' as 'absolute',
    top: '-0.25rem',
    right: '0.0625rem',
    width: '0.625rem',
    height: '0.625rem',
    borderTop: '0.125rem solid #fff',
    borderRight: '0.125rem solid #fff',
    transform: 'rotate(45deg)',
  },
  buttonText: {
    transition: 'color 0.45s cubic-bezier(0.65, 0, 0.076, 1)',
    position: 'relative' as 'relative',
    padding: '0.75rem 1rem',
    marginLeft: '3.5rem', // 确保文本不会与箭头和圆形重叠
    color: '#000',
    fontWeight: 700,
    lineHeight: 1.6,
    textAlign: 'center' as 'center',
    textTransform: 'uppercase' as 'uppercase',
    whiteSpace: 'nowrap' as 'nowrap',
  },
  circleHover: {
    width: '100%', // 在悬停时覆盖整个按钮背景
    left: '0', // 确保从左侧开始覆盖
    borderRadius: '1.625rem', // 左右两侧均为半圆形
  },
  buttonTextHover: {
    color: '#fff',
  },
};

const Button: React.FC<{ onClick?: () => void; children: React.ReactNode; className?: string }> = ({ onClick, children, className }) => {
  const [hover, setHover] = useState(false);

  return (
    <button
      className={`${className} learn-more`}
      onClick={onClick}
      style={buttonStyle.base}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span
        className="circle"
        aria-hidden="true"
        style={hover ? { ...buttonStyle.circle, ...buttonStyle.circleHover } : buttonStyle.circle}
      >
        <span className="icon arrow" style={buttonStyle.icon}>
          <span style={buttonStyle.arrowBefore}></span>
        </span>
      </span>
      <span className="button-text" style={hover ? { ...buttonStyle.buttonText, ...buttonStyle.buttonTextHover } : buttonStyle.buttonText}>
        {children}
      </span>
    </button>
  );
};

export default Button;
 */



