import Header from '../../component/PlanHeader';
import Footer from '../../component/Footer';
import Button from '../../component/Button';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";


const DreamPlan: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 设定一个阈值来检测是否为手机端
    };

    handleResize(); // 初始化检测
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigate = useNavigate()
  return (

    <div className="max-w-[1440px] mx-auto">
      <Header />
      <div className="flex flex-col items-center justify-center w-full mx-auto gap-4 ">
        <section className="flex px-6 gap-5 self-start leading-[150%] max-md:flex-wrap">
          <h1 className="font-oldchinese flex-auto text-6xl text-pink-400 max-w-full max-md:text-4xl max-sm:text-3xl">
          兔子先生男優圓夢計畫
          </h1>
          <p /* className="flex-auto self-end mt-6 text-4xl font-semibold text-white" */
          className={`flex-auto self-end mt-6 font-semibold text-white ${isMobile ? 'text-2xl' : 'text-4xl'}`}>
            Dream Plan
          </p>
        </section>

        <div className="font-oldchinese px-6 text-left w-full mt-12">
          你是否也曾经想过与昔日小电影中的女神同框?想要真的面对面与启蒙老师们交流,兔子先生工作室可以满足你的一切梦想, 可以拍摄专专属于你的情色情影片,珍藏只属于你与老师们的回忆.

        </div>
        <div className="font-oldchinese px-6 text-left w-full">
          ♥️基础拍摄:拍摄时长约1小时-2小时,您无法指定女模特与场地,我们会帮您办理好一切,您只需要到达日本东京的指定地点即可(价格3000美金-7000美金)
        </div>
        <div className="font-oldchinese px-6 text-left w-full">
          ♥️场地升级服务:您可以选择真正日本成人影片中的特色场景,例如:办公室,便利店,医院,审讯室,甚至是户外(野外露出)拍摄等....任何您想的地方,我们都可以尽全力提供.
        </div>
        <div className="font-oldchinese px-6 text-left w-full">
          ♥️女模特升级服务:指定任意女模特(详细请与兔子先生客服联系)
        </div>
        <div className="font-oldchinese px-6 text-left w-full">
           ♥️故事场景升级服务:例如,便利店中购买商品时偷窥穿超短裙的上班的日本营业员小姐姐,被小姐姐发现后!被反过来调戏!!!日本小姐姐拜托你站在收银台位置,她则蹲下了,扒了你的裤子,抚摸与轻舔...最终日本小姐姐竟然直接在收银台发生了腥风血雨xxxx的事件,(当然这只是个例子) 如果你有自己专属的故事情节,并且想要实现出来!请务必与我们联系!!!期待你的超级作品.
        </div>
        <div className="font-oldchinese px-6 text-left w-full">
        ♥️人数升级:你是否曾经幻想过酒池肉林的生活..或者是打开双眼前全都是曼妙的曲线,与肉体的交缠,你与兄弟们在这场酣畅淋漓特殊的聚会中,感受人类最原始最棒最纯洁的性爱体验,一切将回归到最原始的状态,你们水乳交融,相合甚欢...对酒当歌,感叹这才是人生的乐趣.... 如果有这样的疯狂的想法,同时有一帮有义的兄弟们!请一起享受着这份欢愉,一起奏写肉体交融的乐章
          </div>
        <div className="font-oldchinese px-6 text-left w-full">
        顺便说一句,如果你对此感兴趣,认同我们的价值观,甚至比我们更有想法,请务必联系我们,付出行动,实现自己的梦想,我们会全面保护好您的隐私,遵从您的意愿,全面配合~  如果你想当一位片场的参观者Or汁男,也OK!  与无数个日日夜夜一直相伴的老师们,坦诚相见,兔子先生全体期待与您见面♥️
          </div>

        <img
          loading="lazy"
          src={isMobile ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/Frame%2048095542.png" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/Frame%2048095542.png"}
          alt=""
          className={`object-cover w-full h-full w-[1400px] mt-12 ax-md:max-w-full`}
        />
      </div>

      


<div className={`relative flex flex-col ${isMobile ? 'mt-6' : 'mt-12 py-20 px-16'} max-md:px-5`}>
        <img
          loading="lazy"
          src={isMobile ? "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/Mask%20group.png" : "https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/front_images/japanplan.png"}
          alt="Banner background"
          className={`absolute inset-0 object-cover w-full h-full max-md:max-w-full`}
        />
        <div className="flex relative gap-2.5 self-start whitespace-nowrap leading-[150%] max-md:flex-wrap">
          <div className={`font-semibold flex-auto text-white ${isMobile ? 'text-2xl mt-6' : 'text-8xl mt-6'}`}>
            Japlan
          </div>
          <div className={`font-oldchinese flex-auto my-auto text-pink-400 max-md:max-w-full ${isMobile ? 'text-xl mt-8' : 'text-6xl mt-12'}`}>
            兔子先生日本旅行計畫
          </div>
        </div>
        <div className={`flex relative gap-3.5 items-start self-start font-semibold max-md:flex-wrap ${isMobile ? 'ml-2 leading-[100%] mt-2' : 'ml-28 leading-[150%] mt-6 '}`} >
          <div className={`self-start text-white ${isMobile ? 'text-2xl' : 'text-7xl'}`}>
            Plan
          </div>
          <div className={`flex-auto self-end text-pink-400 ${isMobile ? 'text-2xl' : 'text-4xl'}`}>
            Bunny Journey
          </div>
        </div>
        <div className={`font-oldchinese relative self-center text-center text-white max-md:max-w-full ${isMobile ? 'text-[10px] mt-6' : 'text-2xl mt-11'}`}>
          兔子先生深入日本旅行,私人專門定製計畫,獨家尊享
        </div>

        <div className={`relative gap-3.5 self-center rounded-none ${isMobile ? 'mt-6 mb-2 py-2 pl-0' : 'mt-20 pr-1.5 pl-7'}`}>
          <Button onClick={() => navigate('/allplan')} className="font-oldchinese flex-auto my-auto">
            聯繫我們
          </Button>
        </div>
      </div>


      <div className="flex max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow items-start px-20 py-10 w-full bg-zinc-800 max-md:px-5 max-md:max-w-full">
            <div /* className="font-oldchinese text-5xl text-white leading-[79.5px] max-md:max-w-full max-md:text-4xl" */
              className={`font-oldchinese text-white max-md:max-w-full ${isMobile ? 'text-3xl leading-[40px]' : 'text-5xl leading-[79.5px]'}`}>
              兔子先生
            </div>
            <div /* className="mt-3.5 text-5xl text-pink-400 font-[275] leading-[79.5px] max-md:max-w-full max-md:text-4xl" */
              className={`mt-3.5 text-pink-400 font-[275] max-md:max-w-full ${isMobile ? 'text-2xl leading-[40px]' : 'text-5xl leading-[79.5px]'}`}>
              Mr.BunnySEX
            </div>
            <div /* className="font-oldchinese mt-4 text-2xl leading-8 text-white max-w-[506px] max-md:max-w-full text-left mx-auto px-1" */
              className={`font-oldchinese mt-4 leading-8 text-white max-w-[506px] max-md:max-w-full text-left mx-auto px-1${isMobile ? 'text-xs' : 'text-2xl'}`}>
              兔子先生,我們專注於拍攝日本女生同時我們面向華人,讓華人深切的感受日本女生的溫柔,刺激的劇情,強烈的視覺影視,我們擁有上百部成人作品,各種類型的女生,帶給你全新的體驗.
            </div>
            <div className="flex gap-5 justify-between mt-10 max-md:mt-10">
              <img
                loading="lazy"
                srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/rabbit.svg"
                //className="shrink-0 max-w-full ml-12 object-contain w-[170px]"
                className={`shrink-0 max-w-full ml-12 object-contain ${isMobile ? 'w-[100px]' : 'w-[170px]'}`}
              />
              <img
                loading="lazy"
                srcSet="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/bunny/asset/images/logo.png"
                //className="shrink-0 max-w-full ml-6 object-contain w-[170px]"
                className={`shrink-0 max-w-full ml-6 object-contain ${isMobile ? 'w-[100px]' : 'w-[170px]'}`}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[50%] max-md:w-full justify-center items-center">
          <img
            loading="lazy"
            src="https://mrrabbit.fra1.cdn.digitaloceanspaces.com/images/homeFrame.jpg"
            className="w-full object-cover"
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default DreamPlan;

